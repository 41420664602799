import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./oder.scss";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import Offcanvas from "react-bootstrap/Offcanvas";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";

const Order = () => {
  const [show, setShow] = useState(false);

  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterName, setFilterName] = useState("");

  const [nameFilter, setNameFilter] = useState(false);
  const [orderIdFilter, setOrderIdFilter] = useState(false);

  const [showbank, setShowBank] = useState(false);
  const handleCloseBank = () => setShowBank(false);
  const handleShowBank = () => setShowBank(true);
  const [orderId, setOrderId] = useState("");
  const [receiptImg, setReceiptImg] = useState("");

  const handleNameFilter = (e) => {
    if (e.target.checked) {
      setFilterName("name");

      setNameFilter(true);
      setOrderIdFilter(false);
    } else if (!e.target.checked) {
      setNameFilter(false);
      setFilterName("name");
    }
  };

  const handleOrderIdFilter = (e) => {
    if (e.target.checked) {
      setFilterName("id");
      setOrderIdFilter(true);
      setNameFilter(false);
    } else if (!e.target.checked) {
      setOrderIdFilter(false);
      setFilterName("name");
    }
  };

  const getOrderData = async () => {
    setOrderData([]);
    setLoader(true);
    const config = {
      method: "get",
      url:
        api_url +
        "/orders?limit=" +
        limit +
        "&offset=" +
        offset +
        "&sort[]=createdAt@desc" +
        `&${filterName ? "search=" + filterName + "=" + searchQuery : ""}&${filterName == "name" ? "searchByName=true" : "searchByName=false"
        }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setOrderData(resData?.orders);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  const changeOrderStatus = async (id, status) => {
    const config = {
      method: "patch",
      url: api_url + "/orders/" + id + "/status",
      data: { status: status },
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        console.log("order status: ", res);
        toast.success(res?.data?.message);
        getOrderData();
      })
      .catch((error) => {
        if (error?.response?.status == 501) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (error?.response?.status == 401) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          localStorage.removeItem("accessToken");
          history.push("/");
        } else {
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      });
  };

  const approveRceipt = async (status) => {
    const config = {
      method: "patch",
      url: api_url + "/orders/" + orderId + "/is-order-approved",
      data: { isOrderApproved: status },
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        console.log("order status: ", res);
        toast.success(res?.data?.message);
        getOrderData();
        handleCloseBank();
      })
      .catch((error) => {
        if (error?.response?.status == 501) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (error?.response?.status == 401) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          localStorage.removeItem("accessToken");
          history.push("/");
        } else {
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getOrderData();
    }
  }, [offset, searchQuery]);

  return (
    <>
      <div className="content">
        <section className="main-dashboard">
          <div className="row">
            <div className="col-sm-12 p-0">
              <div className="Overview_odersec">
                <div className="parent-heading">
                  <h4 className="ooddeerr">Orders</h4>
                  <div
                    className="itemsssss"
                    style={{ maxWidth: "373px", width: "100%" }}
                  >
                    <div className="search-field w-100">
                      <img
                        src="\nav-assets\search-normal.svg"
                        alt="img"
                        className="search-icon"
                      />
                      <input
                        type="text"
                        placeholder="Search"
                        className="parent-input"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <div class="dropdown dropdown-text-parent">
                        <h6
                          className="dropdown-text"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          By {filterName}
                          <img
                            src="\assestsss\arrow1.svg"
                            alt="img"
                            className="img-fluid ml-1"
                          />
                        </h6>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={nameFilter}
                              onChange={(e) => handleNameFilter(e)}
                            />
                            <label>By Name</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={orderIdFilter}
                              onChange={(e) => handleOrderIdFilter(e)}
                            />
                            <label>By Order Id</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="dropdown">
                  <button
                    class=""
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src="\assestsss\filter_111.svg"
                      alt="img"
                      className="img-fluid mr-2"
                    />
                    Filter
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="input-field">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label>All</label>
                    </div>
                    <div className="input-field">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label>Unpublished</label>
                    </div>
                  </div>
                </div> */}
              </div>
              <div class="table-responsive  ">
                <table class="table bashbord_table">
                  <thead className="bashbord_table_head">
                    <tr className="tablerow">
                      <th scope="col">order id</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Payment Method</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Device</th>
                      <th scope="col">amount</th>
                      <th scope="col">status</th>
                      <th scope="col">Status</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData.length > 0
                      ? orderData?.map((item) => {
                        return (
                          <>
                            <tr className="fstrowtable">
                              <td>
                                <p className="fstrownum">{item?.id}</p>{" "}
                              </td>
                              <td className="avtimgandname">
                                <p className="fstrownumsec ">
                                  {" "}

                                  {item?.user?.imageUrl ? (
                                    <img
                                      src={item?.user.imageUrl}
                                      alt="img"
                                      className="avatartable pr-2 img-fluid"
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  ) : (
                                    <img
                                      src="\assestsss\Frame 40.png" // Replace with the actual path to your default image
                                      alt="Default"
                                      className="avatartable pr-2 img-fluid"
                                      style={{ width: "50px", height: "50px" }}
                                    />
                                  )}

                                  {item?.user?.name.slice(0, 15)}
                                  {item?.user?.name.length > 15 ? "..." : ""}
                                </p>
                              </td>
                              <td>
                                <div className="banktransfer">
                                  {/* <label htmlFor="uploadreciept"></label> id upload img then use label outside the image */}
                                  {/* <img src="\assestsss\upload-img-disable.svg" alt="img" className="img-fluid" /> if bank transfer image is not uploaded then show this image tag */}
                                  <h6>
                                    {item?.paymentType?.value}{" "}
                                    {item?.paymentType?.value ==
                                      "Bank Transfer" ||
                                      item?.paymentType?.value == "JazzCash" ||
                                      item?.paymentType?.value ==
                                      "Easypaisa" ? (
                                      <img
                                        onClick={() => {
                                          handleShowBank();
                                          setOrderId(item?.id);
                                          setReceiptImg(
                                            item?.transactionReceiptImageUrl
                                          );
                                        }}
                                        src="\assestsss\upload-img.svg"
                                        alt="img"
                                        className="img-fluid ml-2"
                                      />
                                    ) : null}{" "}
                                  </h6>
                                  <input
                                    type="file"
                                    className="d-none"
                                    id="uploadreciept"
                                  />
                                </div>
                              </td>
                              <td>
                                <p className="fstrownumthrd">
                                  {moment(item?.createdAt)
                                    .format(
                                      "DD-MMM-YYYY HH:mm:ss"
                                    )}
                                </p>
                              </td>
                              <td>
                                <p className="fstrownumfour">
                                  {item?.device}
                                </p>
                              </td>
                              <td>
                                <p className="fstrownumfive">
                                  Rs {Math.round(item?.discount ?
                                    item?.total +
                                    item?.deliveryFee - item?.discount?.discountAmount : item?.total +
                                    item?.deliveryFee)}
                                </p>
                              </td>
                              <td>
                                {" "}
                                {item?.status == "pending" ? (
                                  <span className="greenprocesssyellow">
                                    {item?.status}
                                  </span>
                                ) : item?.status == "delivered" ? (
                                  <span className="greenprocesssgreen">
                                    {item?.status}
                                  </span>
                                ) : item?.status == "processing" ? (
                                  <span className="greenprocesss">
                                    {item?.status}
                                  </span>
                                ) : item?.status == "cancelled" ? (
                                  <span className="greenprocesssred">
                                    {item?.status}
                                  </span>
                                ) : item?.status == "refund" ? (
                                  <span className="greenprocesssred">
                                    {item?.status}
                                  </span>
                                ) : null}
                              </td>
                              <td>
                                {" "}
                                <div className="dropdown">
                                  <div className="">
                                    <div className="d-flex justify-content-center align-content-center px-2">
                                      <select
                                        // value={e.target.name}
                                        className="selector-size selector-options-text btndrop"
                                        onChange={(e) => {
                                          changeOrderStatus(
                                            item?.id,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        <option selected hidden>
                                          Status
                                        </option>
                                        <option
                                          name="pending"
                                          value="pending"
                                          className="selector-options-text dropdown-item"
                                        >
                                          Pending
                                        </option>
                                        <option
                                          name="processing"
                                          value="processing"
                                          className="selector-options-text dropdown-item"
                                        >
                                          Processing
                                        </option>
                                        <option
                                          name="delivered"
                                          value="delivered"
                                          className="selector-options-text dropdown-item"
                                        >
                                          Delivered
                                        </option>
                                        <option
                                          name="refund"
                                          value="refund"
                                          className="selector-options-text dropdown-item"
                                        >
                                          Refund
                                        </option>
                                        <option
                                          name="cancelled"
                                          value="cancelled"
                                          className="selector-options-text dropdown-item"
                                        >
                                          Cancelled
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {" "}
                                <Link
                                  to={{
                                    pathname: "/admin/orderdetail",
                                    state: {
                                      id: item?.id,
                                    },
                                  }}
                                  className="tble_details"
                                >
                                  Details
                                </Link>
                              </td>
                            </tr>
                          </>
                        );
                      })
                      : null}
                  </tbody>
                </table>
              </div>
              <div className="mainfoot">
                <div className="left">
                  {count > 0 && count <= limit ? (
                    <>
                      <p>
                        SHOWING 1-{count} OF {count}
                      </p>
                    </>
                  ) : count > limit ? (
                    <>
                      <p>
                        SHOWING{" "}
                        {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                        {limit * offset > count ? count : limit * offset} OF{" "}
                        {count}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>SHOWING 0 OF 0</p>
                    </>
                  )}
                </div>
                <div className="right">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={page}
                          page={offset}
                          onChange={(e, p) => setOffset(p)}
                          color="primary"
                        />
                      </Stack>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Offcanvas
        className="banktransfer-sidebar"
        show={showbank}
        onHide={handleCloseBank}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Bank Transfer</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {receiptImg ? (<div className="reciept-img">
            <img src={receiptImg} alt="img" className="img-fluid" />
          </div>) : (<div className="reciept-img">
            <p style={{ color: 'gray', textAlign: 'center', fontSize: '16px' }}>No Image Uploaded</p>
          </div>)}

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Order;
