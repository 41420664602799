import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
const ProductList = ({ assignshow, setassignShow, id }) => {
  console.log("id: ", id, assignshow);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url2;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [orderLines, setOrderLines] = useState([]);
  const [total, setTotal] = useState("");

  const handleClose = () => setassignShow(false);

  const getProductLists = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/users/orders/" + id,

      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setOrderLines(resData?.orderLines);
        setTotal(resData?.total);
        console.log("rresdata prod lisit: ", resData);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });

    // }
  };
  useEffect(() => {
    if (id && assignshow) {
      getProductLists();
    }
  }, [id, assignshow]);

  return (
    <>
      <div className="content">
        <section className="productlist">
          <Offcanvas
            show={assignshow}
            onHide={handleClose}
            className="product_list"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Product List</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">PRODUCT NAME</th>
                      <th scope="col">PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(orderLines).length > 0
                      ? Object.entries(orderLines).map(([key, val], item) => {
                          return (
                            <>
                              <tr className="tablerow">
                                <td className="id">{val?.id}</td>
                                <td>
                                  <p className="ffreshmaker">
                                    <img
                                      src={val?.product?.imageUrl}
                                      alt="img"
                                      className="img-fluid mr-2"
                                    />
                                    {val?.product?.name}
                                  </p>
                                </td>

                                <td>
                                  <div className="suctsc">
                                    {/* <span className="reduce-price">150</span> */}
                                    {val?.price}
                                  </div>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : null}

                    {/* <tr className='tablerowss'>
                                            <td className="idtotalamt">Total Amount</td>
                                            <td   >
                                            </td>
                                            <td className='pprice' >Rs. 1,801</td>
                                        </tr> */}
                  </tbody>
                </table>
              </div>
              <div className="lastflex">
                <p className="idtotalamt">Total Amount</p>
                <p className="idtotalamt2">Rs. {total}</p>
              </div>
              <div className="productbtn">
                <button className="productcanacel">Cancel</button>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};

export default ProductList;
