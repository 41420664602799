import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import Delete from "views/Delete_Modal/Delete";
import Product from "views/Product/Product";
import AddCategory from "./AddCategory";
import "./category.scss";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";

const Category = () => {
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);

  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");

  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const url = api_url + "/store/categories/";

  const FetchRefreshToken = async () => {
    localStorage.removeItem("accessToken");
    let refreshVal = localStorage.getItem("refreshToken");
    var config = {
      method: "post",
      url: api_url + `/auth/admins/refresh-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { refreshToken: refreshVal },
    };
    await axios(config)
      .then(function (response) {
        localStorage.removeItem("refreshToken");
        console.log(response.data.data);
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);
        history.push("/admin/dashboard");
      })
      .catch(function (error) {
        console.log(error);
        // history.push('/login');
        if (error.response.data.status === 400) {
          // toast.error('Validation Failed', {
          //   position: 'top-right',
          //   autoClose: 3000,
          // });
          console.log(error);
        } else if (error.response.data.status === 401) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          history.push("/");
        } else if (error.response.data.status === 403) {
          toast.error("403", {
            position: "top-right",
            autoClose: 3000,
          });
        } else if (error.response.data.status === 404) {
          toast.error("Whitelisted user not found", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      });
  };

  const getCategories = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setCategoriesData([]);
    const config = {
      method: "get",
      url: api_url + "/store/categories?limit=" + limit + "&offset=" + offset,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setCategoriesData(resData?.categories);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);

        setPage(resData?.pages);
        // for (let i = 1; i <= responseData?.pages; i++) {
        //   pageLength.push(i);
        // }
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const publishUnpublishCategory = async (id) => {
    setLoader(true);
    const config = {
      method: "patch",
      url: api_url + "/store/categories/" + id + "/is-publish",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getCategories();
    }
  }, [offset]);

  return (
    <>
      <div className="content">
        <section className="category">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 col-12 p-0">
                <div className="parent-heading">
                  <div className="left">
                    <h4>Category</h4>
                  </div>
                  <div className="right">
                    <a
                      className="addcategory-btn"
                      onClick={() => {
                        setShow(true);
                        setOpType("add");
                      }}
                    >
                      <img src="\assestsss\add.svg" className="img-fluid" /> Add
                      Category
                    </a>
                  </div>
                </div>
                <div className="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Category Name</th>
                        <th scope="col">PUBLISH</th>
                        <th scope="col">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoriesData.length > 0 ? (
                        categoriesData.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td className="id">{item?.id}</td>
                                <td>
                                  <img
                                    src={item?.imageUrl}
                                    alt="img"
                                    className="img-fluid mr-2 cat-img cat__image"
                                  />{" "}
                                  {item?.name}
                                  {item?.childCategories?.length > 0
                                    ? item?.childCategories?.map(
                                        (item1, index1) => {
                                          return (
                                            <>
                                              <td className="id">
                                                {item1?.id}
                                              </td>
                                              <td>
                                                <img
                                                  src={item1?.imageUrl}
                                                  alt="img"
                                                  className="img-fluid mr-2 cat-img cat__image"
                                                />
                                                {item1?.name}
                                                {item1?.childCategories
                                                  ?.length > 0
                                                  ? item1?.childCategories?.map(
                                                      (item2, index2) => {
                                                        return (
                                                          <>
                                                            <td className="id">
                                                              {item2?.id}
                                                            </td>
                                                            <td>
                                                              <img
                                                                src={
                                                                  item2?.imageUrl
                                                                }
                                                                alt="img"
                                                                className="img-fluid mr-2 cat-img cat__image"
                                                              />{" "}
                                                              {item2?.name}
                                                            </td>
                                                            <td>
                                                              <div className="switch-input">
                                                                <input
                                                                  class="tgl tgl-ios"
                                                                  id={item2?.id}
                                                                  type="checkbox"
                                                                  defaultChecked={
                                                                    item2?.isPublish
                                                                  }
                                                                  onChange={() => {
                                                                    publishUnpublishCategory(
                                                                      item2?.id
                                                                    );
                                                                  }}
                                                                />
                                                                <label
                                                                  class="tgl-btn"
                                                                  for={item2?.id}
                                                                ></label>
                                                              </div>
                                                            </td>
                                                            <td>
                                                              <a
                                                                onClick={() => {
                                                                  setShow(true);
                                                                  setOpType(
                                                                    "edit"
                                                                  );
                                                                  setEditId(
                                                                    item2?.id
                                                                  );
                                                                }}
                                                              >
                                                                <img
                                                                  src="\users-assets\edit-icon.svg"
                                                                  alt="img"
                                                                  className="img-fluid mr-2"
                                                                />
                                                              </a>
                                                              <a
                                                                onClick={() => {
                                                                  setShow1(
                                                                    true
                                                                  );
                                                                  setDeleteId(
                                                                    item2?.id
                                                                  );
                                                                }}
                                                              >
                                                                <img
                                                                  src="\users-assets\trash-icon.svg"
                                                                  alt="img"
                                                                  className="img-fluid"
                                                                />
                                                              </a>
                                                            </td>
                                                          </>
                                                        );
                                                      }
                                                    )
                                                  : null}
                                              </td>
                                              <td>
                                                <div className="switch-input">
                                                  <input
                                                    class="tgl tgl-ios"
                                                    id={item1?.id}
                                                    type="checkbox"
                                                    defaultChecked={
                                                      item1?.isPublish
                                                    }
                                                    onChange={() => {
                                                      publishUnpublishCategory(
                                                        item1?.id
                                                      );
                                                    }}
                                                  />
                                                  <label
                                                    class="tgl-btn"
                                                    for={item1?.id}
                                                  ></label>
                                                </div>
                                              </td>
                                              <td>
                                                <a
                                                  onClick={() => {
                                                    setShow(true);
                                                    setOpType("edit");
                                                    setEditId(item1?.id);
                                                  }}
                                                >
                                                  <img
                                                    src="\users-assets\edit-icon.svg"
                                                    alt="img"
                                                    className="img-fluid mr-2"
                                                  />
                                                </a>
                                                <a
                                                  onClick={() => {
                                                    setShow1(true);
                                                    setDeleteId(item1?.id);
                                                  }}
                                                >
                                                  <img
                                                    src="\users-assets\trash-icon.svg"
                                                    alt="img"
                                                    className="img-fluid"
                                                  />
                                                </a>
                                              </td>
                                            </>
                                          );
                                        }
                                      )
                                    : null}
                                </td>

                                <td>
                                  <div className="switch-input">
                                    <input
                                      class="tgl tgl-ios"
                                      id={item?.id}
                                      type="checkbox"
                                      defaultChecked={item?.isPublish}
                                      onChange={() => {
                                        publishUnpublishCategory(item?.id);
                                      }}
                                    />
                                    <label
                                      class="tgl-btn"
                                      for={item?.id}
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    onClick={() => {
                                      setShow(true);
                                      setOpType("edit");
                                      setEditId(item?.id);
                                    }}
                                  >
                                    <img
                                      src="\users-assets\edit-icon.svg"
                                      alt="img"
                                      className="img-fluid mr-2"
                                    />
                                  </a>
                                  <a
                                    onClick={() => {
                                      setShow1(true);
                                      setDeleteId(item?.id);
                                    }}
                                  >
                                    <img
                                      src="\users-assets\trash-icon.svg"
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : loader ? (
                        <tr>
                          <td colSpan="5" className="text-center">
                            <div className="text-center">
                              {<Spinner animation="border" variant="info" />}
                              {/* <h4>No Categories</h4> */}
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <p>No Records</p>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    {count > 0 && count <= limit ? (
                      <>
                        <p>
                          SHOWING 1-{count} OF {count}
                        </p>
                      </>
                    ) : count > limit ? (
                      <>
                        <p>
                          SHOWING{" "}
                          {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                          {limit * offset > count ? count : limit * offset} OF{" "}
                          {count}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>SHOWING 0 OF 0</p>
                      </>
                    )}
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item">
                          {offset > 1 ? (
                            <>
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Previous"
                                onClick={() => setOffset((prev) => prev - 1)}
                              >
                                <span aria-hidden="true">
                                  <i class="fas fa-angle-left"></i>
                                </span>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>

                        {pageCount.length > 1 ? (
                          <>
                            {pageCount.map((item) => {
                              return (
                                <>
                                  <li className="page-item">
                                    <a
                                      className={
                                        item + 1 == offset
                                          ? "page-link active"
                                          : "page-link"
                                      }
                                      href="#"
                                      onClick={() => setOffset(item + 1)}
                                    >
                                      {item + 1}
                                    </a>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : null}

                        {/* <li class="page-item">
                          <a class="page-link active" href="#">
                            1
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            3
                          </a>
                        </li> */}
                        <li class="page-item">
                          {offset < page ? (
                            <>
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Next"
                                onClick={() => setOffset((prev) => prev + 1)}
                              >
                                <span aria-hidden="true">
                                  <i class="fas fa-angle-right"></i>
                                </span>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Delete
          setShow1={setShow1}
          show1={show1}
          id={deleteId}
          updateList={getCategories}
          url={url}
        />
        <AddCategory
          show={show}
          setShow={setShow}
          updateList={getCategories}
          setOpType={setOpType}
          opType={opType}
          editId={editId}
        />
      </div>
    </>
  );
};

export default Category;
