import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import Delete from "views/Delete_Modal/Delete";
import Environment from "utils/environment";
import Assingrider from "./Assingrider";
import Editrider from "./Editrider";
import "./rider.scss";
import axios from "axios";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";

const Rider = () => {
  const val = localStorage.getItem("accessToken");
  const url = Environment.base_url3 + "/riders/";
  const history = useHistory();
  const [show1, setShow1] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [assignshow, setassignShowr] = useState(false);
  const [editrider, setEditrider] = useState(false);
  const [update, setUpdate] = useState(null);

  // riders listing
  const [posts, setPosts] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterName, setFilterName] = useState("");

  const [nameFilter, setNameFilter] = useState(false);
  const [emailFilter, setEmailFilter] = useState(false);
  const [phoneNumberFilter, setPhoneNumberFilter] = useState(false);

  const handleNameFilter = (e) => {
    if (e.target.checked) {
      setFilterName("name");
      setNameFilter(true);
      setEmailFilter(false);
      setPhoneNumberFilter(false);
    } else if (!e.target.checked) {
      setNameFilter(false);
      setFilterName("name");
    }
  };

  const handleEmailFilter = (e) => {
    if (e.target.checked) {
      setFilterName("email");
      setEmailFilter(true);
      setNameFilter(false);
      setPhoneNumberFilter(false);
    } else if (!e.target.checked) {
      setEmailFilter(false);
      setFilterName("name");
    }
  };

  const handlePhoneNumberFilter = (e) => {
    if (e.target.checked) {
      setFilterName("phoneNumber");
      setPhoneNumberFilter(true);
      setNameFilter(false);
      setEmailFilter(false);
    } else if (!e.target.checked) {
      setPhoneNumberFilter(false);
      setFilterName("name");
    }
  };

  const getRiders = async () => {
    setPosts([]);
    await axios
      .get(
        `${Environment.base_url3}/riders?offset=1&&limit=10&${
          filterName ? "search=" + filterName + "=" + searchQuery : ""
        }`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        setPosts(res?.data?.data?.riders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getRiders();
    }
  }, [filterName, searchQuery]);

  // edit rider
  const [edit, setEdit] = useState([]);
  function Editinfo(id) {
    axios
      .get(`${Environment.base_url3}/riders/${id}`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setEdit(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const blockUnblockRider = async (id) => {
    var config = {
      method: "patch",
      url: `${Environment.base_url3}/riders/${id}/toggle-rider`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    console.log(edit, "dddd");
    await axios(config)
      .then(function (response) {})
      .catch(function (error) {});
  };
  return (
    <>
      <div className="content">
        <section className="rider">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 col-12 p-0">
                <div className="parent-heading">
                  <h4>Riders</h4>
                  <div
                    className="itemsssss"
                    style={{ maxWidth: "573px", width: "100%" }}
                  >
                    <div className="search-field w-100">
                      <img
                        src="\nav-assets\search-normal.svg"
                        alt="img"
                        className="search-icon"
                      />
                      <input
                        type="text"
                        placeholder="Search"
                        className="parent-input"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <div class="dropdown dropdown-text-parent">
                        <h6
                          className="dropdown-text"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          By {filterName}
                          <img
                            src="\assestsss\arrow1.svg"
                            alt="img"
                            className="img-fluid ml-1"
                          />
                        </h6>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={nameFilter}
                              onChange={(e) => handleNameFilter(e)}
                            />
                            <label>By name</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={emailFilter}
                              onChange={(e) => handleEmailFilter(e)}
                            />
                            <label>By Email</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={phoneNumberFilter}
                              onChange={(e) => handlePhoneNumberFilter(e)}
                            />
                            <label>By Phone</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      className="addcategory-btn"
                      onClick={() => setassignShowr(true)}
                    >
                      <img src="\assestsss\add.svg" className="img-fluid" /> Add
                      Rider
                    </a>
                  </div>
                  {/* <div className="right">
                    <div class="dropdown">
                      <button
                        class=""
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="\assestsss\filter_111.svg"
                          alt="img"
                          className="img-fluid mr-2"
                        />
                        Filter
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
                          <label>All</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
                          <label>Unpublished</label>
                        </div>
                      </div>
                    </div>
                    <a
                      className="addcategory-btn"
                      onClick={() => setassignShowr(true)}
                    >
                      <img src="\assestsss\add.svg" className="img-fluid" /> Add
                      Rider
                    </a>
                  </div> */}
                </div>
                <div className="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">NAME</th>
                        <th scope="col">Email</th>
                        <th scope="col">Contact</th>
                        <th scope="col">joining date</th>
                        <th scope="col">Block</th>
                        <th scope="col">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {posts?.map((data, index) => {
                        return (
                          <>
                            <tr>
                              <td className="id">{data?.id}</td>
                              <td>
                                <img
                                  src="\users-assets\user.svg"
                                  alt="img"
                                  className="img-fluid mr-2"
                                />
                                {""}
                                {data?.name}
                              </td>
                              <td>{data?.email}</td>
                              <td>{data?.phoneNumber}</td>
                              <td>
                                {moment(data?.joiningDate).format(
                                  "DD-MMM-YYYY"
                                )}
                              </td>
                              <td>
                                <div className="switch-input">
                                  <input
                                    class="tgl tgl-ios"
                                    id={index}
                                    type="checkbox"
                                    defaultChecked={data?.isActive}
                                    onChange={() => blockUnblockRider(data?.id)}
                                  />
                                  <label class="tgl-btn" for={index}></label>
                                </div>
                              </td>
                              <td>
                                <a href="#">
                                  <img
                                    src="\users-assets\edit-icon.svg"
                                    alt="img"
                                    className="img-fluid mr-2"
                                    onClick={() => {
                                      setEditrider(true);
                                      Editinfo(data?.id);
                                      setUpdate(data?.id);
                                    }}
                                  />
                                </a>
                                <a
                                  onClick={() => {
                                    setShow1(true);
                                    setDeleteId(data?.id);
                                  }}
                                >
                                  <img
                                    src="\users-assets\trash-icon.svg"
                                    alt="img"
                                    className="img-fluid"
                                  />
                                </a>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    <p>SHOWING 1-10 OF 145</p>
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item">
                          <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">
                              <i class="fas fa-angle-left"></i>
                            </span>
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link active" href="#">
                            1
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li class="page-item">
                          <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">
                              <i class="fas fa-angle-right"></i>
                            </span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Delete
          show1={show1}
          setShow1={setShow1}
          id={deleteId}
          url={url}
          updateList={getRiders}
        />
        <Assingrider
          assignshow={assignshow}
          setassignShowr={setassignShowr}
          getRiders={getRiders}
        />
        <Editrider
          edit={edit}
          editrider={editrider}
          setEditrider={setEditrider}
          update={update}
          getRiders={getRiders}
        />
      </div>
    </>
  );
};
export default Rider;
