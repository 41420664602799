import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import "./bank.scss";
import BankSidebar from "./BankSidebar";
import axios from "axios";
import Environment from "utils/environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import Delete from "views/Delete_Modal/Delete";

const Bank = () => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url3;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [assignshow, setassignShow] = useState(false);
  const [assignshow2, setassignShow2] = useState(false);

  const [deleteId, setDeleteId] = useState("");
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const url = api_url + "/bank/";

  const [searchQuery, setSearchQuery] = useState("");
  const [publishedFilter, setPublishedFilter] = useState(false);
  const [unpublishedFilter, setUnpublishedFilter] = useState(false);

  const handlePublishedFilter = (e) => {
    if (e.target.checked) {
      setPublishedFilter(true);
      setUnpublishedFilter(false);
    } else if (!e.target.checked) {
      setPublishedFilter(false);
    }
  };

  const handleUnpublishedFilter = (e) => {
    if (e.target.checked) {
      setUnpublishedFilter(true);
      setPublishedFilter(false);
    } else if (!e.target.checked) {
      setUnpublishedFilter(false);
    }
  };

  const getBankData = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setBankData([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/bank?limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=name=" +
        searchQuery +
        "&" +
        `${
          !publishedFilter && !unpublishedFilter
            ? ""
            : publishedFilter || unpublishedFilter
            ? "filter=isPublish=eq:" + publishedFilter
            : ""
        }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setBankData(resData?.banks);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const publishUnpublishBank = async (id) => {
    // setLoader(true);
    const config = {
      method: "patch",
      url: api_url + "/discounts/csv/" + id + "/is-publish",
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        // setLoader(false);
        getBankData();
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getBankData();
    }
  }, [offset, publishedFilter, unpublishedFilter, searchQuery]);

  return (
    <>
      <div className="content">
        <section className="banktransfer">
          <div className="content p-0">
            <section className="promo">
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-xl-12 p-0">
                    <div className="customerdetailheading ">
                      <div className="parent-heading w-100">
                        <h3>Bank Information</h3>
                        <div
                          className="itemsssss backbutton"
                          style={{ maxWidth: "573px", width: "100%" }}
                        >
                          <div className="search-field w-100">
                            <img
                              src="\nav-assets\search-normal.svg"
                              alt="img"
                              className="search-icon"
                            />
                            <input
                              type="text"
                              placeholder="Search"
                              className="parent-input"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div class="dropdown dropdown-text-parent">
                              <h6
                                className="dropdown-text"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                By Bank
                                {/* <img
                                  src="\assestsss\arrow1.svg"
                                  alt="img"
                                  className="img-fluid ml-1"
                                /> */}
                              </h6>
                              {/* <div
                                class="dropdown-menu drop__input-field"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div className="input-field">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                  <label>By Category</label>
                                </div>
                                <div className="input-field">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                  />
                                  <label>By Promo Code</label>
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <button
                            className="createbtn"
                            onClick={() => {
                              setassignShow(true);
                              setOpType("add");
                            }}
                          >
                            <img src="" className="img-fluid" />
                            <img
                              src="\assestsss\add.svg"
                              className="img-fluid"
                            />{" "}
                            Add Bank
                          </button>
                        </div>
                      </div>

                      {/* <div className="backbutton">
                        <div className="btnsdrop">
                          <button
                            className="dropdownbtn"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="\assestsss\filter_111.svg"
                              className="img-fluid"
                            />{" "}
                            Filter
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">
                              Action
                            </a>
                            <a class="dropdown-item" href="#">
                              Another action
                            </a>
                            <a class="dropdown-item" href="#">
                              Something else here
                            </a>
                          </div>
                        </div>

                        <button
                          className="createbtn"
                          onClick={() => setassignShow(true)}
                        >
                          <img src="" className="img-fluid" />
                          <img
                            src="\assestsss\add.svg"
                            className="img-fluid"
                          />{" "}
                          Create Code
                        </button>
                      </div> */}
                    </div>
                    <div className="table-customer">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Bank</th>
                            <th scope="col">Name</th>
                            <th scope="col">Branch</th>
                            <th scope="col">Account</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bankData?.length > 0
                            ? bankData?.map((item, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{item?.name}</td>
                                      <td>{item?.accountName}</td>
                                      <td>{item?.branchName}</td>
                                      <td>{item?.accountNumber}</td>

                                      <td>
                                        <div className="images">
                                          <img
                                            src="\assestsss\edit-2.png"
                                            className="img-fluid"
                                            onClick={() => {
                                              setassignShow(true);
                                              setOpType("edit");
                                              setEditId(item?.id);
                                            }}
                                          />
                                          <img
                                            src="\assestsss\trash.svg"
                                            className="img-fluid"
                                            onClick={() => {
                                              setassignShow2(true);
                                              setDeleteId(item?.id);
                                            }}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            : null}
                        </tbody>
                      </table>
                    </div>
                    <div className="mainfoot">
                      <div className="left">
                        <p>SHOWING 1-10 OF 145</p>
                      </div>
                      <div className="right">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item">
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Previous"
                              >
                                <span aria-hidden="true">
                                  <i class="fas fa-angle-left"></i>
                                </span>
                              </a>
                            </li>
                            <li class="page-item">
                              <a class="page-link active" href="#">
                                1
                              </a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">
                                2
                              </a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#">
                                3
                              </a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">
                                  <i class="fas fa-angle-right"></i>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Delete
          setShow1={setassignShow2}
          show1={assignshow2}
          id={deleteId}
          updateList={getBankData}
          url={url}
        />
        <BankSidebar
          assignshow={assignshow}
          setassignShow={setassignShow}
          opType={opType}
          editId={editId}
          updateList={getBankData}
          setOpType={setOpType}
        />
      </div>
    </>
  );
};

export default Bank;
