import BannerManagement from "views/Banner Management/BannerManagement";
import BrandManagement from "views/BrandManagement/BrandManagement";
import Category from "views/Category/Category";
import Customer from "views/Customer/Customer";
import CustomerDetail from "views/Customer/CustomerDetail";
import Dashboard from "views/dashboard/Dashboard.js";
import OderDetaits from "views/Order/OderDetaits";
import Order from "views/Order/Order";
import Ourstaff from "views/Ourstaff/Ourstaff";
import Product from "views/Product/Product";
import Promo from "views/Promo/Promo";
import Ratelist from "views/Ratelist/Ratelist";
import Rider from "views/Rider/Rider";
import Role from "views/Role/Role";
import Setting from "views/Setting/Setting";
import DeliveryCharges from "views/Delivery Charges/DeliveryCharges";
import Support from "views/Support/Support";
import SupportDetail from "views/Support/SupportDetail";
import Unit from "views/Unit/Unit";
import Discount from "views/Discounts/Discount";
import DiscountCSV from "views/Discounts/DiscountCSV/DiscountCSV";
import Bank from "views/BankTransfer/Bank";
import Pushnotification from "views/Pushnotification/Pushnotification";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
    layout: "/admin",
  },
  {
    path: "/category",
    name: "Category",
    component: Category,
    layout: "/admin",
  },
  {
    path: "/order",
    name: "Order",
    component: Order,
    layout: "/admin",
  },
  {
    path: "/customer",
    name: "Customer",
    component: Customer,
    layout: "/admin",
  },
  {
    path: "/ourstaff",
    name: "Ourstaff",
    component: Ourstaff,
    layout: "/admin",
  },
  {
    path: "/rider",
    name: "Rider",
    component: Rider,
    layout: "/admin",
  },
  {
    path: "/promo",
    name: "Promo",
    component: Promo,
    layout: "/admin",
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    layout: "/admin",
  },
  {
    path: "/customerdetail",
    name: "CustomerDetail",
    component: CustomerDetail,
    layout: "/admin",
  },
  {
    path: "/orderdetail",
    name: "OrderDetail",
    component: OderDetaits,
    layout: "/admin",
  },
  {
    path: "/supportdetail",
    name: "SupportDetail",
    component: SupportDetail,
    layout: "/admin",
  },
  {
    path: "/role",
    name: "Role",
    component: Role,
    layout: "/admin",
  },
  {
    path: "/brandmanagement",
    name: "Brand Management",
    component: BrandManagement,
    layout: "/admin",
  },
  {
    path: "/unit",
    name: "Unit",
    component: Unit,
    layout: "/admin",
  },
  {
    path: "/bannermanagement",
    name: "Banner Management",
    component: BannerManagement,
    layout: "/admin",
  },
  {
    path: "/deliverycharges",
    name: "Delivery Charges",
    component: DeliveryCharges,
    layout: "/admin",
  },
  {
    path: "/pushnotification",
    name: "Push Notification",
    component: Pushnotification,
    layout: "/admin",
  },
  {
    path: "/discount",
    name: "Discount",
    component: Discount,
    layout: "/admin",
  },
  {
    path: "/discountCSV",
    name: "DiscountCSV",
    component: DiscountCSV,
    layout: "/admin",
  },
  {
    path: "/banktransfer",
    name: "Bank Information",
    component: Bank,
    layout: "/admin",
  },
  {
    path: "/ratelist",
    name: "Ratelist",
    component: Ratelist,
    layout: "/admin",
  },
];
export default routes;
