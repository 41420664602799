import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Environment from "utils/environment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useState, useEffect } from "react";

const AddBanner = ({ assignshowbanner, setassignShowbanner, getBanner }) => {
  const val = localStorage.getItem("accessToken");
  const [loader, setLoader] = useState(false);

  const [name, setName] = useState("");
  const [bannerType, setBannerType] = useState("");
  const handleClose = () => {
    setassignShowbanner(false);
  };

  const [image, setImage] = useState(null);
  const onImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const addBanner = async (e) => {
    if (!image) {
      toast.error("Upload Image");
    } else if (name.length === 0) {
      toast.error("Enter Name");
    } else if (bannerType.length === 0) {
      toast.error("Enter Type");
    } else {
      const formData = new FormData();
      if (name) {
        formData.append("bannerLink", name);
      }
      if (bannerType) {
        formData.append("type", bannerType);
      }
      if (image) {
        formData.append("bannerImage", image);
      }
      var config = {
        method: "post",
        url: `${Environment.base_url2}/banners`,
        data: formData,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then(function (response) {
          getBanner();
          toast.success(response?.data?.message);
          setLoader(false);
        })

        .catch(function (error) {
          setLoader(false);
          toast.error(error?.response?.data?.message);
        });
    }
  };

  return (
    <>
      <div className="content">
        <section className="sidebar-promoproduct brandmanagement">
          <Offcanvas
            show={assignshowbanner}
            onHide={handleClose}
            className="sidebar-promoproduct"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Add Banner</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="filedinput">
                <div className="upload-img">
                  <p>Banner Image*</p>
                  <div className="upload">
                    <input
                      type="file"
                      className="d-none"
                      id="upload"
                      onChange={onImageChange}
                    />
                    <label htmlFor="upload">
                      {image ? (
                        <img
                          src={URL.createObjectURL(image)}
                          alt="img"
                          className="img-fluid upload-img"
                        />
                      ) : (
                        <>
                          <div>
                            <img
                              src="\assestsss\gallery-add.svg"
                              className="img-fluid"
                            />
                            <h4 className="">Drag your image here</h4>
                            <p>
                              (Only *.jpeg and *.png images will be accepted)
                            </p>
                          </div>
                        </>
                      )}
                    </label>
                  </div>
                </div>
                <div className="option-field">
                  <p>Banner Link*</p>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Name"
                  />
                  <p>Banner Type*</p>
                  <input
                    value={bannerType}
                    onChange={(e) => setBannerType(e.target.value)}
                    type="text"
                    placeholder="Type"
                  />
                </div>
              </div>
              <div className="buttonss">
                <button className="">Cancel</button>
                <button className="ml-2" onClick={addBanner}>
                  Add Banner{" "}
                </button>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};

export default AddBanner;
