import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

function Dashboard() {
  const [show, setShow] = useState(false);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const [orderList, setOrderList] = useState([]);
  const [orderStats, setOrderStats] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getCustomerOrders = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setOrderList([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/orders?limit=" +
        limit +
        "&offset=" +
        offset +
        "&sort[]=createdAt@desc",
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        console.log("orders list: ", resData);
        setOrderList(resData?.orders);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const getOrderStats = async () => {
    setLoader(true);
    setOrderStats([]);
    const config = {
      method: "get",
      url: api_url + "/orders/stats",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        console.log("order stats: ", resData);
        setOrderStats(resData);
      })
      .catch((error) => {
        if (error?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
      });
  };

  const changeOrderStatus = async (id, status) => {
    const config = {
      method: "patch",
      url: api_url + "/orders/" + id + "/status",
      data: { status: status },
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        console.log("order status: ", res);
        toast.success(res?.data?.message);
        getCustomerOrders();
      })
      .catch((error) => {
        if (error?.response?.status == 501) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (error?.response?.status == 401) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          localStorage.removeItem("accessToken");
          history.push("/");
        } else {
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getCustomerOrders();
      getOrderStats();
    }
  }, [offset]);
  return (
    <>
      <div className="content">
        <section className="main-dashboard">
          <div className="row">
            <div className="col-sm-12 p-0">
              <div className="Overview_main">
                <h4 className="headdingg">Dashboard Overview</h4>
              </div>
              <div className="row ">
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 pl-0 padd-l padd">
                  <div className="carddashbord">
                    <div className="carrdmainflex">
                      <div className="cardpic">
                        <img
                          src="\dashbord\cardfst.svg"
                          alt="img"
                          className=""
                        />
                      </div>
                      <div className="cardpichnd">
                        <p className="headdinggfst">Total Order</p>
                        {orderStats != null ||
                        Object.keys(orderStats).length > 0 ? (
                          <p className="headdinggfstdd">
                            {" "}
                            {orderStats?.totalOrder}
                          </p>
                        ) : (
                          0
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 padd-r padd">
                  <div className="carddashbord">
                    <div className="carrdmainflex">
                      <div className="cardpic">
                        <img
                          src="\dashbord\cardsec.svg"
                          alt="img"
                          className=""
                        />
                      </div>
                      <div className="cardpichnd">
                        <p className="headdinggfst">Order Pending</p>
                        {orderStats != null ||
                        Object.keys(orderStats).length > 0 ? (
                          <p className="headdinggfstdd">
                            {" "}
                            {orderStats?.pendingOrder}
                          </p>
                        ) : (
                          0
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 padd thrdpadd">
                  <div className="carddashbord">
                    <div className="carrdmainflex">
                      <div className="cardpic">
                        <img
                          src="\dashbord\cardthrd.svg"
                          alt="img"
                          className=""
                        />
                      </div>
                      <div className="cardpichnd">
                        <p className="headdinggfst">Order Processing</p>
                        {orderStats != null ||
                        Object.keys(orderStats).length > 0 ? (
                          <p className="headdinggfstdd">
                            {" "}
                            {orderStats?.processingOrder}
                          </p>
                        ) : (
                          0
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12  pr-0 padd-r padd">
                  <div className="carddashbord">
                    <div className="carrdmainflex">
                      <div className="cardpic">
                        <img
                          src="\dashbord\cardfor.svg"
                          alt="img"
                          className=""
                        />
                      </div>
                      <div className="cardpichnd">
                        <p className="headdinggfst">Order Delivered</p>
                        {orderStats != null ||
                        Object.keys(orderStats).length > 0 ? (
                          <p className="headdinggfstdd">
                            {" "}
                            {orderStats?.deliveredOrder}
                          </p>
                        ) : (
                          0
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Overview_oder">
                <h4 className="ooddeerr">Latest Orders</h4>
                <Link to={"/admin/order"} className="tble_details">
                  <button className="viewall">View All</button>
                </Link>
              </div>
              <div class="table-responsive">
                <table class="table bashbord_table">
                  <thead className="bashbord_table_head">
                    <tr className="tablerow">
                      <th scope="col">Order id</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Device</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Status</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderList?.length > 0
                      ? orderList?.map((item) => {
                          return (
                            <>
                              <tr className="fstrowtable">
                                <td>
                                  <p className="fstrownum">{item?.id}</p>{" "}
                                </td>
                                <td className="avtimgandname">
                                  <p className="fstrownumsec ">
                                    {" "}
                                    {item?.user?.imageUrl ? (
                                    <img
                                      src={item?.user.imageUrl}
                                      alt="img"
                                      className="avatartable pr-2 img-fluid"
                                      style={{ width: "108px", height: "95px" }}
                                    />
                                  ) : (
                                    <img
                                      src="\assestsss\Frame 40.png" // Replace with the actual path to your default image
                                      alt="Default"
                                      className="avatartable pr-2 img-fluid"
                                      style={{ width: "108px", height: "95px" }}
                                    />
                                  )}
                                    {item?.user?.name}
                                  </p>
                                </td>
                                <td>
                                  <p className="fstrownumthrd">
                                  {moment (item?.createdAt)
                                  .format(
                                  "DD-MMM-YYYY HH:mm:ss"
                                    )}
                                  </p>
                                </td>
                                <td>
                                  <p className="fstrownumfour">
                                 {item?.device}
                                  </p>
                                </td>
                                <td>
                                  <p className="fstrownumfive">
                                    Rs {Math.round(item?.discount?
                                                    item?.total +
                                                      item?.deliveryFee - item?.discount?.discountAmount :item?.total +
                                                      item?.deliveryFee)}
                                  </p>
                                </td>
                                <td>
                                  {item?.status == "pending" ? (
                                    <span className="greenprocesssyellow">
                                      {item?.status}
                                    </span>
                                  ) : item?.status == "delivered" ? (
                                    <span className="greenprocesssgreen">
                                      {item?.status}
                                    </span>
                                  ) : item?.status == "processing" ? (
                                    <span className="greenprocesss">
                                      {item?.status}
                                    </span>
                                  ) : item?.status == "cancelled" ? (
                                    <span className="greenprocesssred">
                                      {item?.status}
                                    </span>
                                  ) : item?.status == "refund" ? (
                                    <span className="greenprocesssred">
                                      {item?.status}
                                    </span>
                                  ) : null}
                                </td>
                                <td>
                                  <div className="dropdown">
                                    <div className="">
                                      <div className="d-flex justify-content-center align-content-center px-2">
                                        <select
                                          // value={e.target.name}
                                          className="selector-size selector-options-text btndrop"
                                          onChange={(e) => {
                                            changeOrderStatus(
                                              item?.id,
                                              e.target.value
                                            );
                                          }}
                                        >
                                          <option selected hidden>
                                            Status
                                          </option>
                                          <option
                                            name="pending"
                                            value="pending"
                                            className="selector-options-text dropdown-item"
                                          >
                                            Pending
                                          </option>
                                          <option
                                            name="processing"
                                            value="processing"
                                            className="selector-options-text dropdown-item"
                                          >
                                            Processing
                                          </option>
                                          <option
                                            name="delivered"
                                            value="delivered"
                                            className="selector-options-text dropdown-item"
                                          >
                                            Delivered
                                          </option>
                                          <option
                                            name="refund"
                                            value="refund"
                                            className="selector-options-text dropdown-item"
                                          >
                                            Refund
                                          </option>
                                          <option
                                            name="cancelled"
                                            value="cancelled"
                                            className="selector-options-text dropdown-item"
                                          >
                                            Cancelled
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <button
                                      class="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <span className="pr-4">Status</span>
                                    </button>
                                    <div
                                      class="dropdown-menu menneuee"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a class="dropdown-item" href="#">
                                        Pending
                                      </a>
                                      <a class="dropdown-item" href="#">
                                        Processing
                                      </a>
                                      <a class="dropdown-item" href="#">
                                        Delivered
                                      </a>
                                      <a class="dropdown-item" href="#">
                                        Refund
                                      </a>
                                      <a class="dropdown-item" href="#">
                                        Cancelled</a>
                                    </div>
                                       */}
                                </td>
                                <td>
                                  {" "}
                                  {/* <a className="tble_details">Details</a> */}
                                  <Link
                                    to={{
                                      pathname: "/admin/orderdetail",
                                      state: {
                                        id: item?.id,
                                      },
                                    }}
                                    className="tble_details"
                                  >
                                    Details
                                  </Link>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
              <div className="mainfoot">
                  <div className="left">
                    {count > 0 && count <= limit ? (
                      <>
                        <p>
                          SHOWING 1-{count} OF {count}
                        </p>
                      </>
                    ) : count > limit ? (
                      <>
                        <p>
                          SHOWING{" "}
                          {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                          {limit * offset > count ? count : limit * offset} OF{" "}
                          {count}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>SHOWING 0 OF 0</p>
                      </>
                    )}
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={page}
                            page={offset}
                            onChange={(e, p) => setOffset(p)}
                            color="primary"
                          />
                        </Stack>
                      </ul>
                    </nav>
                  </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default Dashboard;
