import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import { Dropdown, Offcanvas } from "react-bootstrap";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const CreateDiscountModal = ({
  assignshow,
  setassignShow,
  setOpType,
  opType,
  editId,
  updateList,
}) => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();

  const [categoryScrollState, setCategoryScrollState] = useState(false);
  const [productScrollState, setProductScrollState] = useState(false);
  const [type,setType]=useState("");
  const [code, setCode] = useState("");
  const [criteria, setCriteria] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [discountAmount, setDiscountAmount] = useState();
  const [limitPerUser, setLimitPerUser] = useState();
  const [totalUsageLimit, setTotalUsageLimit] = useState();

  const handleClose = () => {
    setassignShow(false);
    setCategoryScrollState(false);
    setProductScrollState(false);
    setCode("");
    setCriteria();
    setDiscountAmount();
    setEndDate("");
    setStartDate("");
    setLimitPerUser();
    setTotalUsageLimit();
  };

  const addDiscount = async () => {
    // validateUserName();
    // if (!errors) {
    console.log(startDate);

    console.log(moment(startDate).format("MM-DD-YYYY"));
    const config = {
      method: "post",
      url: api_url + "/discounts",
      data: {
        code: code,
        criteria: criteria,
        limitPerUser: limitPerUser,
        totalUsageLimit: totalUsageLimit,
        discountAmount: discountAmount,
        type: type,
        startTime: moment(startDate).format("MM-DD-YYYY"),
        endTime: moment(endDate).format("MM-DD-YYYY"),
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        handleClose();
        updateList();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };

  const editDiscount = async () => {
    // validateUserName();
    // if (!errors) {
    console.log(startDate);

    console.log(moment(startDate).format("MM-DD-YYYY"));
    const config = {
      method: "patch",
      url: api_url + "/discounts/" + editId,
      data: {
        code: code,
        criteria: criteria,
        limitPerUser: limitPerUser,
        totalUsageLimit: totalUsageLimit,
        discountAmount: discountAmount,
        type: type,
        startTime: moment(startDate).format("MM-DD-YYYY"),
        endTime: moment(endDate).format("MM-DD-YYYY"),
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        handleClose();
        updateList();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          localStorage.removeItem("accessToken");

          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };

  const getDiscountDetails = async () => {
    const config = {
      method: "get",
      url: api_url + "/discounts/" + editId,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setCode(resData?.code);
        setCriteria(resData?.criteria);
        setDiscountAmount(resData?.discountAmount);
        setLimitPerUser(resData?.limitPerUser);
        setTotalUsageLimit(resData?.totalUsageLimit);
        setType(resData?.type)
        setStartDate(moment(resData?.startTime).format("YYYY-MM-DD"));
        setEndDate(moment(resData?.endTime).format("YYYY-MM-DD"));
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");

          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      });
    // }
  };

  useEffect(() => {
    if (!val) {
      localStorage.removeItem("accessToken");
      history.push("/");
      toast.error("Session Expired", {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      if (opType == "edit") {
        getDiscountDetails();
      }
    }
  }, [opType]);

  return (
    <>
      <div className="content">
        <section className="sidebar-promoproduct">
          <Offcanvas
            show={assignshow}
            onHide={handleClose}
            className="sidebar-promoproduct"
          >
            <Offcanvas.Header closeButton>
              {opType == "add" ? (
                <Offcanvas.Title>Create Discount</Offcanvas.Title>
              ) : opType == "edit" ? (
                <Offcanvas.Title>Edit Discount</Offcanvas.Title>
              ) : null}
            </Offcanvas.Header>
            <Offcanvas.Body className="canvas-body">
              <div className="filedinput">
                <p>
                  Code <span className="red">*</span>{" "}
                </p>
                <input
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  type="text"
                  placeholder="Code"
                />
                <p>
                  Qualification Criteria
                  <span className="red">*</span>
                </p>
                <input
                  type="number"
                  placeholder="Qualification Criteria"
                  value={criteria}
                  onChange={(e) => setCriteria(e.target.value)}
                />
                <p>
                  Start Time<span className="red">*</span>
                </p>
                <div className="catagoryfield">
                  <input
                    type="date"
                    placeholder="DD/MM/YY"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <p>
                  End Time<span className="red">*</span>
                </p>
                <div className="catagoryfield">
                  <input
                    type="date"
                    placeholder="DD/MM/YY"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>

                <p>
                  Discount Amount
                  <span className="red">*</span>
                </p>
                <input
                  type="number"
                  placeholder="Discount Amount"
                  value={discountAmount}
                  onChange={(e) => setDiscountAmount(e.target.value)}
                />
                    <p>
                  Type
                  <span className="red">*</span>
                </p>
                <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {type != null ? <> {type}</> : "select type"}
                        <img src="\assestsss\arrow 1.svg" alt="arrow" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>

                        <Dropdown.Item    onClick={(e) => {
                                  setType("fixed");
                                }}
                              >
                          fixes
                        </Dropdown.Item>
                        <Dropdown.Item onClick={(e) => {
                                  setType("percentage");
                                }}>
                          Percentage
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                <p>
                  Limit Per User
                  <span className="red">*</span>
                </p>
                <input
                  type="number"
                  placeholder="0"
                  value={limitPerUser}
                  onChange={(e) => setLimitPerUser(e.target.value)}
                />
                <p>
                  Total Usage Limit
                  <span className="red">*</span>
                </p>
                <input
                  type="number"
                  placeholder="0"
                  value={totalUsageLimit}
                  onChange={(e) => setTotalUsageLimit(e.target.value)}
                />
              </div>
              {/* <p className="text-danger pt-4">
                {lengthError}
                </p> */}
              <div className="buttonss">
                <button className="" onClick={handleClose}>
                  Cancel
                </button>

                {opType == "add" ? (
                  <button className="addrole ml-2" onClick={addDiscount}>
                    Create Discount
                  </button>
                ) : opType == "edit" ? (
                  <button className="addrole ml-2" onClick={editDiscount}>
                    Save Discount
                  </button>
                ) : null}
                {/* <button className="ml-2">Create Promo </button> */}
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};

export default CreateDiscountModal;
