import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./product.scss";
import Delete from "views/Delete_Modal/Delete";
import EditProduct from "./EditProduct";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Product = () => {
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const url = api_url + "/store/products/";

  const [searchQuery, setSearchQuery] = useState("");
  const [publishedFilter, setPublishedFilter] = useState(false);
  const [unpublishedFilter, setUnpublishedFilter] = useState(false);
  const [inStockFilter, setInStockFilter] = useState(false);
  const [outOfStockFilter, setOutOfStockFilter] = useState(false);
  const [pharmacy,setPharmacy] = useState(false);
  const [grocery,setGrocery] = useState(false);
  const [manual,setManual] = useState(false);

  const handlePublishedFilter = (e) => {
    if (e.target.checked) {
      setPublishedFilter(true);
      setUnpublishedFilter(false);
    } else if (!e.target.checked) {
      setPublishedFilter(false);
    }
  };

  const handleUnpublishedFilter = (e) => {
    if (e.target.checked) {
      setUnpublishedFilter(true);
      setPublishedFilter(false);
    } else if (!e.target.checked) {
      setUnpublishedFilter(false);
    }
  };
  const handleInStockFilter = (e) => {
    if (e.target.checked) {
      setInStockFilter(true);
      setOutOfStockFilter(false);
    } else if (!e.target.checked) {
      setInStockFilter(false);
    }
  };

  const handleOutOfStockFilter = (e) => {
    if (e.target.checked) {
      setOutOfStockFilter(true);
      setInStockFilter(false);
    } else if (!e.target.checked) {
      setOutOfStockFilter(false);
    }
  };


  const handlePharmacy = (e) => {
    if (e.target.checked) {
      setPharmacy(true);
      setGrocery(false);
      setManual(false);
    } else if (!e.target.checked) {
      setPharmacy(false);
    }
  };


  const handleGrocery = (e) => {
    if (e.target.checked) {
      setGrocery(true);
      setPharmacy(false);
      setManual(false);
    } else if (!e.target.checked) {
      setGrocery(false);
    }
  };

  const handleManual = (e) => {
    if (e.target.checked) {
      setManual(true);
      setGrocery(false);
      setPharmacy(false);
    } else if (!e.target.checked) {
      setManual(false);
    }
  };

  const getProducts = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setProductsData([]);
    const config = {
      method: "get",
      url: api_url + "/store/products/admin-products?limit=" + limit + "&offset=" + offset + "&search=name=" + searchQuery + "&" +
        `${
          !publishedFilter &&
          !unpublishedFilter &&
          !inStockFilter &&
          !outOfStockFilter
            ? ""
            : publishedFilter || unpublishedFilter
            ? "filter=isPublish=eq:" + publishedFilter
            : inStockFilter
            ? "filter=quantity=gt:0"
            : outOfStockFilter
            ? "filter=quantity=lte:0"
            : ""
        }` +`${
          grocery
            ? "&type=grocer"
            : pharmacy
            ? "&type=PHLP"
            :  manual
            ? "&type=manual"
            : ""
        }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setProductsData(resData?.products);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const publishUnpublishProduct = async (id) => {
    // setLoader(true);
    const config = {
      method: "patch",
      url: api_url + "/store/products/" + id + "/is-publish",
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        // setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getProducts();
    }
  }, [
    offset,
    publishedFilter,
    unpublishedFilter,
    inStockFilter,
    outOfStockFilter,
    searchQuery,
    grocery,
    pharmacy,
    manual,
  ]);

  return (
    <>
      <div className="content">
        <section className="product">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 col-12 p-0">
                <div className="parent-heading">
                  <h4>Products</h4>
                  <div className="itemsssss">
                    <div className="search-field">
                      <img
                        src="\nav-assets\search-normal.svg"
                        alt="img"
                        className="search-icon"
                      />
                      <input
                        type="text"
                        placeholder="Search"
                        className="parent-input"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value.replace(/\+/g, '%2B').replace(/&/g, '%26'))}
                      />
                    </div>
                    <div class="dropdown">
                      <button
                        class=""
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="\assestsss\filter_111.svg"
                          alt="img"
                          className="img-fluid mr-2"
                        />
                        Filter
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={publishedFilter}
                            onChange={(e) => handlePublishedFilter(e)}
                          />
                          <label>Published</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={unpublishedFilter}
                            onChange={(e) => handleUnpublishedFilter(e)}
                          />
                          <label>Unpublished</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={inStockFilter}
                            onChange={(e) => handleInStockFilter(e)}
                          />
                          <label>In Stock</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={outOfStockFilter}
                            onChange={(e) => handleOutOfStockFilter(e)}
                          />
                          <label>Out of Stock</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={pharmacy}
                            onChange={(e) => handlePharmacy(e)}
                          />
                          <label>pharmacy</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={grocery}
                            onChange={(e) => handleGrocery(e)}
                          />
                          <label>Grocery</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={manual}
                            onChange={(e) => handleManual(e)}
                          />
                          <label>Manual</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">PRODUCT NAME</th>
                        <th scope="col">CATEGORY</th>
                        <th scope="col">PRICE</th>
                        <th scope="col">PackSize</th>
                        <th scope="col">STOCK</th>
                        {/* <th scope="col">STATUS</th> */}
                        <th scope="col">PUBLISH</th>
                        <th scope="col">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {productsData.length > 0 ? (
                        productsData.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td className="id">{item?.sourceProductId}</td>
                                <td>
                                  <img
                                    src={item?.imageUrl}
                                    alt="img"
                                    className="img-fluid mr-2"
                                  />{" "}
                                  {item?.name}
                                </td>
                                <td>{item?.category[0]?.name}</td>
                                <td className="text-caps">Rs. {item?.price}</td>
                                <td className="text-caps">{item?.packSize}</td>
                                <td>{item?.quantity}</td>
                                {/* <td>
                                  {item?.status == "selling" ? (
                                    <>
                                      <span className="btn-selling">
                                        Selling
                                      </span>
                                    </>
                                  ) : item?.status == "outOfStock" ? (
                                    <span className="btn-selling stockout">
                                      Out of Stock
                                    </span>
                                  ) : null}
                                </td> */}
                                <td>
                                  <div className="switch-input">
                                    <input
                                      class="tgl tgl-ios"
                                      id={(index + offset).toString()}
                                      type="checkbox"
                                      defaultChecked={item?.isPublish}
                                      onChange={() =>
                                        publishUnpublishProduct(item?.id)
                                      }
                                    />
                                    <label
                                      class="tgl-btn"
                                      for={(index + offset).toString()}
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    onClick={() => {
                                      console.log("abab");
                                      setShow(true);
                                      setOpType("edit");
                                      setEditId(item?.id);
                                    }}
                                  >
                                    <img
                                      src="\users-assets\edit-icon.svg"
                                      alt="img"
                                      className="img-fluid mr-2"
                                    />
                                  </a>
                                  <a
                                    onClick={() => {
                                      setShow1(true);
                                      setDeleteId(item?.id);
                                    }}
                                  >
                                    <img
                                      src="\users-assets\trash-icon.svg"
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : loader ? (
                        <tr>
                          <td colSpan="8" className="text-center">
                            <div className="text-center">
                              {<Spinner animation="border" variant="info" />}
                              {/* <h4>No Categories</h4> */}
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <p>No Records</p>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    {count > 0 && count <= limit ? (
                      <>
                        <p>
                          SHOWING 1-{count} OF {count}
                        </p>
                      </>
                    ) : count > limit ? (
                      <>
                        <p>
                          SHOWING{" "}
                          {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                          {limit * offset > count ? count : limit * offset} OF{" "}
                          {count}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>SHOWING 0 OF 0</p>
                      </>
                    )}
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={page}
                            page={offset}
                            onChange={(e, p) => setOffset(p)}
                            color="primary"
                          />
                        </Stack>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Delete
          setShow1={setShow1}
          show1={show1}
          id={deleteId}
          updateList={getProducts}
          url={url}
        />
        <EditProduct
          show={show}
          setShow={setShow}
          updateList={getProducts}
          editId={editId}
          setEditId={setEditId}
        />
      </div>
    </>
  );
};

export default Product;
