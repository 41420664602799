import React from 'react'
import "./login.scss"
import { Link } from 'react-router-dom'
const Newpassword = () => {
    return (
        <>

            <div className='content'>
                <section className="newpassword">
                    <div className="container-fluid h-100">
                        <div className="row h-100">
                            <div className="col-sm-7 m-auto">
                                <div className='newpasswordcard'>
                                    <div className='textcontent'>
                                        <h2>Create New Password</h2>
                                        <p>Your new password must be different  from previously used<br></br> passwords.</p>
                                        <div className='newpasswordinput'>
                                            < h3 className='inputpara mt-0'>New Password</h3>
                                            <div className='parent'>
                                                <input className='maininput' placeholder='New Password' type="text" />
                                                <img src='\assestsss\eye.svg' alt='img' className='img-fluid' />
                                            </div>
                                            <div className='red'>
                                                
                                                <input className='maininput' placeholder='New Password' type="password" />
                                                <img src='\assestsss\eye.svg' alt='img' className='img-fluid' />
                                                
                                            </div>
                                            <h3>Password Must:</h3>
                                            <div className='headingsss'>
                                                <ul>
                                                    <li><p>Be at least 8 characters long</p></li>
                                                    <li><p>Have at least one uppercase</p></li>
                                                    <li><p>Contain at least one special character</p></li>
                                                </ul>
                                            </div>
                                            < h3 className='inputpara mt-0'>Confirm New Password</h3>
                                            <div className='parent'>
                                                
                                                <input className='maininput' placeholder='Confirm New Password' type="text" />
                                                <img src='\assestsss\eye.svg' alt='img' className='img-fluid' />
                                                
                                            </div>
{/*                                            
                                            <div className='confirm'>
                                                <Link to="/success">  <button>Confirm</button></Link>
                                              
                                            </div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Newpassword