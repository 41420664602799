import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./role.scss";
import Delete from "views/Delete_Modal/Delete";
import EditRole from "./EditRole";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
const Role = () => {
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url3;
  const api_url1 = Environment.base_url2;

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [rolesData, setRolesData] = useState([]);

  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");

  const FetchRefreshToken = async () => {
    localStorage.removeItem("accessToken");
    let refreshVal = localStorage.getItem("refreshToken");

    var config = {
      method: "post",
      url: api_url1 + `/auth/staffs/refresh-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { refreshToken: refreshVal },
    };
    await axios(config)
      .then(function (response) {
        localStorage.removeItem("refreshToken");
        console.log(response.data.data);
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);
        history.push("/admin/dashboard");
      })
      .catch(function (error) {
        console.log(error);
        // history.push('/login');
        if (error.response.data.status === 400) {
          // toast.error('Validation Failed', {
          //   position: 'top-right',
          //   autoClose: 3000,
          // });
          console.log(error);
        } else if (error.response.data.status === 401) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          history.push("/");
        } else if (error.response.data.status === 403) {
          toast.error("403", {
            position: "top-right",
            autoClose: 3000,
          });
        } else if (error.response.data.status === 404) {
          toast.error("Whitelisted user not found", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      });
  };

  const getRoles = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setRolesData([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/roles?limit=" +
        limit +
        "&offset=" +
        offset +
        "&minimal=false",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setRolesData(resData?.roles);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);

        setPage(resData?.pages);
        // for (let i = 1; i <= responseData?.pages; i++) {
        //   pageLength.push(i);
        // }
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const publishUnpublishRoles = async (id) => {
    setLoader(true);
    const config = {
      method: "patch",
      url: api_url + "/roles/" + id + "/is-publish",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getRoles();
    }
  }, [offset]);

  return (
    <>
      <div className="content">
        <section className="Role">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 col-12 p-0">
                <div className="parent-heading">
                  <h4>Role Management</h4>
                  <div className="direc">
                    {/* <div class="dropdown">
                      <button
                        class=""
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="\assestsss\filter_111.svg"
                          alt="img"
                          className="img-fluid mr-2"
                        />
                        Filter
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
                          <label>All</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
                          <label>Unpublished</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
                          <label>Out of Stock</label>
                        </div>
                      </div>
                    </div> */}

                    <button
                      onClick={() => {
                        setShow(true);
                        setOpType("add");
                      }}
                      className="rolebtn"
                    >
                      <img src="" className="img-fluid" />
                      <img src="\assestsss\add.svg" className="img-fluid" /> Add
                      Role
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>

                        <th scope="col">NAME</th>
                        <th scope="col">Description</th>
                        <th scope="col">Add Date</th>
                        <th scope="col">PUBLISH</th>
                        <th scope="col">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {rolesData?.length > 0 ? (
                        rolesData?.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>{index + 1}</td>

                                <td>{item?.name}</td>
                                <td>{item?.description}</td>
                                <td>
                                  {" "}
                                  {moment(item?.createdAt).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>
                                  <div className="switch-input">
                                    <input
                                      class="tgl tgl-ios"
                                      id={(index + offset).toString()}
                                      type="checkbox"
                                      defaultChecked={item?.isPublish}
                                      onChange={() => {
                                        publishUnpublishRoles(item?.id);
                                      }}
                                    />
                                    <label
                                      class="tgl-btn"
                                      for={(index + offset).toString()}
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    onClick={() => {
                                      setShow(true);
                                      setOpType("edit");
                                      setEditId(item?.id);
                                    }}
                                  >
                                    <img
                                      src="\users-assets\edit-icon.svg"
                                      alt="img"
                                      className="img-fluid mr-2"
                                    />
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            <div className="text-center">
                              {<Spinner animation="border" variant="info" />}
                              {/* <h4>No Categories</h4> */}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    {count > 0 && count <= limit ? (
                      <>
                        <p>
                          SHOWING 1-{count} OF {count}
                        </p>
                      </>
                    ) : count > limit ? (
                      <>
                        <p>
                          SHOWING{" "}
                          {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                          {limit * offset > count ? count : limit * offset} OF{" "}
                          {count}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>SHOWING 0 OF 0</p>
                      </>
                    )}
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        {offset > 1 ? (
                          <>
                            <a
                              class="page-link"
                              href="#"
                              aria-label="Previous"
                              onClick={() => setOffset((prev) => prev - 1)}
                            >
                              <span aria-hidden="true">
                                <i class="fas fa-angle-left"></i>
                              </span>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}

                        {pageCount.length > 1 ? (
                          <>
                            {pageCount.map((item) => {
                              return (
                                <>
                                  <li className="page-item">
                                    <a
                                      className={
                                        item + 1 == offset
                                          ? "page-link active"
                                          : "page-link"
                                      }
                                      href="#"
                                      onClick={() => setOffset(item + 1)}
                                    >
                                      {item + 1}
                                    </a>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : null}

                        <li class="page-item">
                          {offset < page ? (
                            <>
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Next"
                                onClick={() => setOffset((prev) => prev + 1)}
                              >
                                <span aria-hidden="true">
                                  <i class="fas fa-angle-right"></i>
                                </span>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Delete setShow1={setShow1} show1={show1} />
        <EditRole
          show={show}
          setShow={setShow}
          opType={opType}
          setOpType={setOpType}
          editId={editId}
          updateList={getRoles}
        />
      </div>
    </>
  );
};

export default Role;
