import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./setting.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Setting = () => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url3;
  const history = useHistory();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(false);
  const [changePasswordBool, setChangePasswordBool] = useState(false);
  const [curr, setCurrent] = useState("");
  const [neww, setNew] = useState("");
  const [confirm, setConfirm] = useState("");
  const [token, setToken] = useState("");
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [showPass3, setShowPass3] = useState(false);
  const [userName, setUsername] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userContactNo, setUserContactNo] = useState("");
  const [userRole, setUserRole] = useState("");

  const [userNameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [image, setImage] = useState("");
  const [userData, setUserData] = useState();
  const [accountImage, setAccountImage] = useState();

  const [baseImage, setBaseImage] = useState("");

  let passError = false;

  const handlePass1 = () => {
    setShowPass1(!showPass1);
  };

  const handlePass2 = () => {
    setShowPass2(!showPass2);
  };

  const handlePass3 = () => {
    setShowPass3(!showPass3);
  };

  const setCurrPass = (e) => {
    setCurrent(e.target.value);
  };
  const setNewPass = (e) => {
    setNew(e.target.value);
  };
  const setConPass = (e) => {
    setConfirm(e.target.value);
    setPasswordError("");
  };
  const handleChangePassword = () => {
    setChangePasswordBool(!changePasswordBool);
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getAdminDetail();
    }
  }, []);

  const getAdminDetail = async () => {
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/staffs/my-profile",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const responseData = res?.data?.data;
        setUserData(responseData);
        console.log("data: ", responseData);
        setUsername(responseData?.name);
        setUserEmail(responseData?.email);
        setAccountImage(responseData?.imageUrl);
        setUserContactNo(responseData?.contactNo);
        setUserRole(responseData?.role);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/admin/dashboard");
        } else {
          toast.error(err?.response?.data?.msg, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };
  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }

  const changePass = async () => {
    setPasswordError("");

    validatepassword();
    if (!passError) {
      if (neww != confirm) {
        setPasswordError("New & Confirm Password must be same");
      } else {
        if (checkPassword(neww)) {
          setPasswordError("");
          setLoader(true);
          const data = {
            currentPassword: curr,
            newPassword: neww,
            confirmPassword: confirm,
          };
          await axios
            .patch(`${api_url}/staffs/password`, data, {
              headers: {
                Authorization: "Bearer " + val,
              },
            })
            .then((res) => {
              toast.success(res?.data?.message);
              handleClose();
              setLoader(false);
            })
            .catch((err) => {
              if (err?.response?.statusCode == 501) {
                // localStorage.clear();
                history.push("/");
              } else if (err?.response?.data?.msg) {
                toast.error(err?.response?.data?.msg, {
                  position: "top-right",
                  autoClose: 2000,
                });
              } else {
                toast.error(err?.response?.data?.message, {
                  position: "top-right",
                  autoClose: 2000,
                });
              }
              setLoader(false);
            });
        } else {
          setPasswordError(
            "Password should be 8-16 characters, should contain atleast 1 special character (/ $ @ ), atleast one upper and one lower case letter and atleast one number"
          );
        }
      }
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("UserRole");
    localStorage.removeItem("userName");
    localStorage.removeItem("profilePicture");
    window.location.href = "/";
  };
  let errors = true;

  const editProfile = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    if (isValidEmail(userEmail)) {
      const formData = new FormData();
      if (userName) {
        formData.append("name", userName);
      }
      if (userEmail) {
        formData.append("email", userEmail);
      }
      if (userRole) {
        formData.append("role", userRole);
      }
      if (userContactNo) {
        formData.append("contactNo", userContactNo);
      }
      if (image) {
        formData.append("profileImage", image);
      }
      const config = {
        method: "patch",
        url: api_url + "/staffs/profile",
        data: formData,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          toast.success(res?.data?.msg);
          if (userName) {
            localStorage.setItem("userName", userName);
          }
          if (image) {
            localStorage.setItem("profilePicture", baseImage);
            setAccountImage(baseImage);
          }
          setUsername("");
          setLoader(false);
          // handleClose();
        })
        .catch((err) => {
          setUsername("");

          if (err?.response?.statusCode == 501) {
            localStorage.clear();
            history.push("/");
          } else {
            console.log("error meessage: ", err?.response?.data?.message);
            toast.error(err?.response?.data?.message, {
              position: "top-right",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    } else {
      toast.error("Email is invalid");
    }
    // }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  useEffect(() => {
    if (!val) {
      history.push("/");
    }
  }, []);

  const validatepassword = () => {
    if (!neww || neww === "") {
      passError = true;
    } else {
      passError = false;
    }
  };

  return (
    <>
      <div className="content">
        <section className="setting">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 p-0">
                <div className="main-heading">
                  <h2>EditProfile</h2>
                </div>
                <div className="main-inputfelid">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="filedinput">
                        <p>Name</p>
                        <input
                          type="text"
                          placeholder="Enter Name"
                          value={userName}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                        <p>Email Address</p>
                        <input
                          type="text"
                          placeholder="Enter Email"
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                        <p>Contact Number</p>
                        <input
                          className="admin"
                          type="number"
                          placeholder="Enter Contact Number"
                          value={userContactNo}
                          onChange={(e) => setUserContactNo(e.target.value)}
                        />
                        <p>Your Role</p>
                        <input
                          className="admin"
                          type="text"
                          placeholder="Enter Role"
                          value={userRole}
                          onChange={(e) => setUserRole(e.target.value)}
                        />
                        <div className="password">
                          <p>Password</p>
                          <input
                            type="password"
                            placeholder=""
                            value={"aaaaaa"}
                          />
                          <a className="" onClick={handleShow}>
                            Change Password
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="bannerss">
                        <h4>Profile Image</h4>
                        <div className="item-upload">
                          <ul className="list-inline ">
                            <li className="list-inline-item">
                              <input
                                id="upload"
                                type="file"
                                className="d-none"
                                onChange={uploadImages}
                              />
                              <label for="upload" className="">
                                {baseImage ? (
                                  <img
                                    src={baseImage}
                                    alt="img"
                                    className="img-fluid upload-img"
                                  />
                                ) : (
                                  <>
                                    {accountImage ? (
                                      <img
                                        src={accountImage}
                                        alt="img"
                                        className="img-fluid brdr-img"
                                      />
                                    ) : (
                                      <div>
                                        <img
                                          src="\assestsss\gallery-add.svg"
                                          className="img-fluid"
                                        />
                                        <h4 className="">
                                          Drag your image here
                                        </h4>
                                        <p>
                                          (Only *.jpeg and *.png images will be
                                          accepted)
                                        </p>
                                      </div>
                                    )}

                                    {/* <h6>Upload user</h6> */}
                                  </>
                                )}
                              </label>
                              {/* {!baseImage && (
                                <div>
                                  <h4 className="">Drag your image here</h4>
                                  <p>
                                    (Only *.jpeg and *.png images will be
                                    accepted)
                                  </p>
                                </div>
                              )} */}
                            </li>
                          </ul>
                        </div>
                        <div className="img-content">
                          <a className="">
                            {/* <img
                              src={accountImage}
                              alt="img"
                              className="img-fluid brdr-img"
                            /> */}
                            {/* <img
                              src="\assestsss\content.png"
                              className="img-fluid brdr-img"
                            /> */}
                          </a>
                        </div>
                        <div className="buttonupload text-right">
                          <button className="" onClick={editProfile}>
                            Update Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        className="modal-password"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header>
          <Modal.Title>Change Password</Modal.Title>
          <img
            onClick={() => setShow(false)}
            closeButton
            src="\assestsss\close-circle.svg"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="changepassword">
            <p>Current Password</p>
            <div className="passwordicon">
              <input
                type={showPass1 ? "text" : "password"}
                placeholder="Current Password"
                onChange={setCurrPass}
              />

              <button className="passeye" onClick={handlePass1}>
                <img src="\assestsss\eye.svg" className="img-fluid eye"></img>
              </button>
            </div>
          </div>
          <div className="changepassword">
            <p>New Password</p>
            <div className="passwordicon">
              <input
                type={showPass2 ? "text" : "password"}
                placeholder="New Password"
                onChange={setNewPass}
              />
              <button className="passeye" onClick={handlePass2}>
                <img src="\assestsss\eye.svg" className="img-fluid eye"></img>
              </button>
            </div>
          </div>
          <div className="changepassword">
            <p>Confirm New Password</p>
            <div className="passwordicon">
              <input
                className="lastinput"
                type={showPass3 ? "text" : "password"}
                placeholder="Confirm New Password"
                onChange={setConPass}
              />

              <p className="text-danger">{passwordError}</p>
              <button className="passeye" onClick={handlePass3}>
                <img src="\assestsss\eye.svg" className="img-fluid eye"></img>
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="" onClick={() => setShow(false)} closeButton>
            Cancel
          </button>
          <button className="" onClick={changePass}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Setting;
