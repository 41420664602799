import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import { Search } from "@material-ui/icons";

const PromoSidebar = ({
  assignshowtwo,
  setassignShowtwo,
  setShow1,
  getPromos,
  update,
  id,
}) => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [promocode, setPromocode] = useState("");
  const [discount, setDiscount] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [productname, setProductname] = useState("");
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [lengthError, setLengthError] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [productsList, setProductsList] = useState([]);

  // const [deleteId, setDeleteId] = useState(null);
  // const [editpromos, setEditpromos] = useState([]);
  function Editpromo(id) {
    axios
      .get(`${Environment.base_url}/promos/${id}`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        const responseData = res?.data?.data;
        setPromocode(responseData?.promoCode);
        setDiscount(responseData?.discount);
        setStartdate(responseData?.startTime);
        setEnddate(responseData?.endTime);
        setProductname(responseData?.promotionApplies[0]?.product?.name);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // promodetail show
  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      if (assignshowtwo) {
        Editpromo(update);
      }
    }
  }, [assignshowtwo]);

  //scroll

  const [data, setData] = useState([]);
  const [categoryScrollState, setCategoryScrollState] = useState(false);
  const [categoryPage, setCategoryPage] = useState(1);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);

  const [productScrollState, setProductScrollState] = useState(false);
  const [productPage, setProductPage] = useState(1);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const handleCategoryScroll = () => {
    console.log("hitting handle Scroll ");

    const element = document.querySelector(".scrollable");
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight - 5 &&
      !isCategoryLoading
    ) {
      setIsCategoryLoading(true);
    }
  };

  const handleProductScroll = () => {
    console.log("hitting handle Scroll ");

    const element = document.querySelector(".scrollableProd");
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight - 5 &&
      !isCategoryLoading
    ) {
      setIsProductLoading(true);
    }
  };

  useEffect(() => {
    if (isCategoryLoading) {
      fetchCategoriesData();
    }
    const scrollableElement = document.querySelector(".scrollable");

    console.log("scrollable Element: ", scrollableElement);

    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleCategoryScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleCategoryScroll);
      }
    };
  }, [isCategoryLoading, categoryScrollState]);

  useEffect(() => {
    if (isProductLoading) {
      fetchProductData();
    }
    const scrollableElement = document.querySelector(".scrollableProd");

    console.log("scrollable Element: ", scrollableElement);

    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleProductScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleProductScroll);
      }
    };
  }, [isProductLoading, productScrollState]);
  // const handleScroll = () => {
  //   const element = contentRef.current;
  //   const scrollPosition = element.scrollHeight - element.scrollTop;
  //   const scrollHeight = element.clientHeight;
  //   if (scrollPosition <= scrollHeight + 5 && !isCategoryLoading.current) {
  //     isCategoryLoading.current = true;
  //   }
  // };

  const fetchCategoriesData = async () => {
    console.log("hitting! ");
    try {
      setLoader(true);
      const config = {
        method: "get",
        url: `${api_url}/store/categories?limit=5&offset=${categoryPage}&minimal=true&allCategories=false`,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          setCategoriesList((prevData) => [...prevData, ...resData]);
          setCategoryPage((prevPage) => prevPage + 1);
          console.log("searrch result: ", resData);
          // setSearchResults(resData?.categories);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else if (err?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            // FetchRefreshToken();
            console.log("refresh token: ", err?.response);
          }
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
          setLoader(false);
        });

      // setData(prevData => [...prevData, ...newData]);
      // setPage(prevPage => prevPage + 1);
      setIsCategoryLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProductData = async () => {
    console.log("hitting! ");
    try {
      setLoader(true);
      const config = {
        method: "get",
        url: `${api_url}/store/products?limit=5&offset=${productPage}&minimal=true`,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          setProductsList((prevData) => [...prevData, ...resData]);
          setProductPage((prevPage) => prevPage + 1);
          console.log("searrch result: ", resData);
          // setSearchResults(resData?.categories);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else if (err?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            // FetchRefreshToken();
            console.log("refresh token: ", err?.response);
          }
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
          setLoader(false);
        });

      // setData(prevData => [...prevData, ...newData]);
      // setPage(prevPage => prevPage + 1);
      setIsProductLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClosetwo = () => {
    setassignShowtwo(false);
    setCategoryScrollState(false);
    setProductScrollState(false);
  };

  //   const addPromo = async () => {
  //     setLoader(true);
  //     if (category.length > 0 || product.length > 0) {
  //       const config = {
  //         method: "post",
  //         url: api_url + "/promos",
  //         data: {
  //           discount: discount,
  //           startTime: startdate,
  //           endTime: enddate,
  //           ...(category.length > 0 && { productCategoryIds: category }),
  //           ...(product.length > 0 && { productIds: product }),
  //         },
  //         headers: {
  //           Authorization: "Bearer " + val,
  //         },
  //       };
  //       await axios(config)
  //         .then((res) => {
  //           handleClosetwo();
  //           toast.success(res?.data?.message);
  //           setLoader(false);
  //         })
  //         .catch((err) => {
  //           if (err?.response?.statusCode == 501) {
  //             history.push("/");
  //           } else {
  //             console.log("error meessage: ", err?.response?.data?.message);
  //             toast.error(err?.response?.data?.message, {
  //               position: "bottom-left",
  //               autoClose: 2000,
  //             });
  //           }
  //           setLoader(false);
  //         });
  //     } else {
  //       setLengthError("Please select Category or Product!");
  //     }
  //   };

  const handleCategoriesList = async () => {
    // setSearchQuery(e);
    setLoader(true);
    const config = {
      method: "get",
      url:
        api_url +
        "/store/categories?limit=10&offset=1&minimal=true&allCategories=false",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setCategoriesList(resData);
        console.log("searrch result: ", resData);
        // setSearchResults(resData?.categories);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });

    // }
  };

  const handleProductsList = async () => {
    // setSearchQuery(e);
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/store/products?limit=10&offset=1&minimal=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setProductsList(resData);
        console.log("searrch result: ", resData);
        // setSearchResults(resData?.categories);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });

    // }
  };

  const handleSearchQuery = async (e) => {
    setSearchQuery(e);
    setLoader(true);
    if (e) {
      const config = {
        method: "get",
        url: api_url + "/store/categories?search=name=" + e,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          console.log("searrch result: ", resData);
          setSearchResults(resData?.categories);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else if (err?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            // FetchRefreshToken();
            console.log("refresh token: ", err?.response);
          }
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
          setLoader(false);
        });
    } else {
      setLoader(false);
      setSearchResults([]);
    }

    // }
  };

  useEffect(() => {
    handleCategoriesList();
    handleProductsList();
  }, [assignshowtwo]);

  // dropdown value show category
  const [selectedcheck, setSelectedCheck] = useState([]);
  const [viewProd, setViewProds] = useState(false);
  const [rend, setRend] = useState(false);

  const AddCategory = (e, item) => {
    let dumArr = selectedcheck;
    if (e.target.checked === true) {
      dumArr.push(item);
    } else if (e.target.checked === false) {
      // dumArr.pop();
    }
    setSelectedCheck(dumArr);
    setRend(!rend);
  };

  // dropdown value show product
  const [productcheck, setProductCheck] = useState([]);
  const [viewProduct, setViewProduct] = useState(false);
  const [productrend, setProductRend] = useState(false);

  const AddProduct = (e, item) => {
    let dumArray = productcheck;
    if (e.target.checked === true) {
      dumArray.push(item);
    } else if (e.target.checked === false) {
      // dumArray.pop();
    }
    setProductCheck(dumArray);
    setProductRend(!productrend);
  };

  // update promo
  const Updatepromo = async () => {
    if (discount.length === 0) {
      toast.error("Enter Cnic");
    } else if (startdate.length === 0) {
      toast.error("Enter Email");
    } else if (enddate.length === 0) {
      toast.error("Enter Contact no");
    } else {
      const formData = new FormData();

      if (discount) {
        formData.append("discount", discount);
      }
      if (startdate) {
        formData.append("startTime", startdate);
      }
      if (enddate) {
        formData.append("endTime", enddate);
      }
      var config = {
        method: "patch",
        url: `${Environment.base_url}/promos/${update}`,
        data: {
          discount: discount,
          startTime: startdate,
          endTime: enddate,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then(function (response) {
          getPromos();
          toast.success(response?.data?.message);
          setLoader(false);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          setLoader(false);
        });
    }
  };

  // const DeleteCategory = async () => {
  //     setLoader(true);
  //     const config = {
  //         method: "delete",
  //         url: `${api_url}$/promos/${id}/category/${promoId}`,
  //         headers: {
  //             Authorization: "Bearer " + val,
  //         },
  //     };
  //     await axios(config)
  //         .then((res) => {
  //             setLoader(false);
  //             setShow1(false);
  //         })
  //         .catch((err) => {
  //             if (err?.response?.status == 501) {
  //                 history.push("/");
  //             } else {
  //                 console.log("error meessage: ", err?.response?.data?.message);
  //                 toast.error(err?.response?.data?.message, {
  //                     position: "top-right",
  //                     autoClose: 2000,
  //                 });
  //             }
  //             setLoader(false);
  //         });
  // };

  return (
    <>
      <div className="content">
        <section className="sidebar-promoproduct">
          <Offcanvas
            show={assignshowtwo}
            onHide={handleClosetwo}
            className="sidebar-promoproduct"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Edit Promo</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="filedinput">
                <p>Promo Code</p>
                <input
                  value={promocode}
                  onChange={(e) => setPromocode(e.target.value)}
                  type="text"
                  placeholder="Promo Code"
                />
                <p>Discount %*</p>
                <input
                  type="text"
                  placeholder="Discount %"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
                <p>Start Time*</p>
                <div className="catagoryfield">
                  <input
                    type="text"
                    placeholder="DD/MM/YY"
                    value={startdate}
                    onChange={(e) => setStartdate(e.target.value)}
                  />
                </div>
                <p>End Time*</p>
                <div className="catagoryfield">
                  <input
                    type="text"
                    placeholder="DD/MM/YY"
                    value={enddate}
                    onChange={(e) => setEnddate(e.target.value)}
                  />
                </div>

                <div class="dropdown">
                  <div className="dropdownbtn">
                    <p className="inputpara">
                      Category<span className="red">*</span>
                    </p>
                    <Dropdown
                      onClick={() => {
                        setProductScrollState(true);
                        setProductname();
                      }}
                    >
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {/* {category.length > 0 ? (
                                                    <>{category.length} items selected</>
                                                ) : category.length == 0 ? (
                                                    <>{selectedcheck?.map((item, index) => {
                                                        return (
                                                            <p key={index} className="text-white">{item?.name}</p>
                                                        )
                                                    })}</>
                                                ) : null} */}
                        <img src="\assestsss\arrow 1.svg" alt="arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="main-parent">
                          <input
                            className="maininput"
                            placeholder={productname}
                            type="text"
                            value={searchQuery}
                            onChange={(e) => handleSearchQuery(e.target.value)}
                          />
                          <img
                            src="\assestsss\search-normal.svg"
                            className="img-fluid"
                          />
                        </div>
                        <div className="scrollable list-body">
                          {categoriesList.length > 0
                            ? categoriesList.map((item, index) => {
                                return (
                                  <>
                                    <div class="form-group ">
                                      <input
                                        type="checkbox"
                                        id={index + item}
                                        onChange={(e) => {
                                          AddCategory(e, item);
                                          setViewProds(false);
                                        }}
                                      />
                                      <label for={index + item}>
                                        <span>
                                          <img
                                            src={item?.imageUrl}
                                            alt="img"
                                            className="koi-bhi mr-2"
                                          />
                                        </span>
                                        {item?.name}
                                      </label>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </div>
                        <div></div>
                        <div className="AddSelected">
                          <button onClick={() => setViewProds(true)}>
                            Add Selected
                          </button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="list-body-category">
                      <>
                        <Table className="table-category">
                          <thead className="thead-categories">
                            <tr>
                              <th className="th-id">ID</th>
                              <th>PRODUCT NAMEt</th>
                            </tr>
                          </thead>
                          {selectedcheck.length > 0 && viewProd
                            ? selectedcheck.map((item, index) => {
                                return (
                                  <tbody
                                    className="tbody-categories"
                                    key={index}
                                  >
                                    <tr>
                                      <td className="item-id">{item?.id}</td>
                                      <td className="item-name">
                                        {" "}
                                        <label
                                          className="mb-0"
                                          for={index + item}
                                        >
                                          <span>
                                            <img
                                              src={item?.imageUrl}
                                              alt="img"
                                              className="image-categories mr-2"
                                            />
                                          </span>
                                          {item?.name}
                                        </label>
                                      </td>
                                      <td className="delete-img">
                                        <div>
                                          {" "}
                                          <img
                                            src="\assestsss\trash.svg"
                                            className="img-fluid "
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            : null}
                        </Table>
                      </>
                    </div>
                  </div>
                </div>

                <div className="catagoryfield">
                  <div class="dropdown">
                    <div className="dropdownbtn">
                      <p className="inputpara">
                        Product Name<span className="red">*</span>
                      </p>
                      <Dropdown onClick={() => setCategoryScrollState(true)}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {/* {category.length > 0 ? (
                                                        <>{category.length} items selected</>
                                                    ) : category.length == 0 ? (
                                                        <>{productcheck?.map((item, index) => {
                                                            return (
                                                                <p key={index} className="text-white">{item?.name}</p>
                                                            )
                                                        })}</>
                                                    ) : null} */}
                          <>{category.length} items selected</>
                          <img src="\assestsss\arrow 1.svg" alt="arrow" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="main-parent">
                            <input
                              className="maininput"
                              placeholder="Product Name"
                              type="text"
                              value={searchQuery}
                              onChange={(e) =>
                                handleSearchQuery(e.target.value)
                              }
                            />
                            <img
                              src="\assestsss\search-normal.svg"
                              className="img-fluid"
                            />
                          </div>

                          <div className="scrollableProd list-body">
                            {productsList.length > 0
                              ? productsList.map((item, index) => {
                                  return (
                                    <>
                                      <div class="form-group ">
                                        <input
                                          type="checkbox"
                                          id={index + item}
                                          onChange={(e) => {
                                            AddProduct(e, item);
                                            setViewProduct(false);
                                          }}
                                        />
                                        <label for={index + item}>
                                          <span>
                                            <img
                                              src={item?.imageUrl}
                                              alt="img"
                                              className="koi-bhi mr-2"
                                            />
                                          </span>
                                          {item?.name}
                                        </label>
                                      </div>
                                    </>
                                  );
                                })
                              : null}
                          </div>
                          <div className="AddSelected">
                            <button onClick={() => setViewProduct(true)}>
                              Add Selected
                            </button>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="list-body-category">
                        <>
                          <Table className="table-category">
                            <thead className="thead-categories">
                              <tr>
                                <th className="th-id">ID </th>
                                <th>PRODUCT NAME</th>
                              </tr>
                            </thead>
                            {productcheck.length > 0 && viewProduct
                              ? productcheck.map((item, index) => {
                                  return (
                                    <tbody
                                      className="tbody-categories"
                                      key={index}
                                    >
                                      <tr>
                                        <td className="item-id">{item?.id}</td>
                                        <td className="item-name">
                                          {" "}
                                          <label
                                            className="mb-0"
                                            for={index + item}
                                          >
                                            <span>
                                              <img
                                                src={item?.imageUrl}
                                                alt="img"
                                                className="image-categories mr-2"
                                              />
                                            </span>
                                            {item?.name}
                                          </label>
                                        </td>
                                        <td className="delete-img">
                                          <a>
                                            <img
                                              src="\assestsss\trash.svg"
                                              className="img-fluid "
                                            />
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              : null}
                          </Table>
                        </>
                      </div>
                    </div>
                  </div>
                  {/* <input
                    type="text"
                    placeholder="Product Name*"
                    value={product}
                    onChange={(e) => {
                      setProduct(e.target.value);
                      setLengthError("");
                    }}
                  /> */}
                </div>
              </div>
              <p className="text-danger pt-4">{lengthError}</p>
              <div className="buttonss">
                <button className="" onClick={handleClosetwo}>
                  Cancel
                </button>
                <button
                  className="ml-2"
                  onClick={() => {
                    Updatepromo();
                  }}
                >
                  Create Promo{" "}
                </button>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};

export default PromoSidebar;
