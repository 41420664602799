import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./Discount.scss";
import CreateDiscountModal from "./CreateDiscountModal";
import Delete from "views/Delete_Modal/Delete";
import dropdownDownArrow from "../../assets/img/dropdown-down-arrow.svg";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import moment from "moment";

const Discount = () => {
  const [assignshow, setassignShow] = useState(false);
  const [assignshow2, setassignShow2] = useState(false);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [discountsData, setDiscountsData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const url = api_url + "/discounts/";

  const [searchQuery, setSearchQuery] = useState("");
  const [publishedFilter, setPublishedFilter] = useState(false);
  const [unpublishedFilter, setUnpublishedFilter] = useState(false);

  const handlePublishedFilter = (e) => {
    if (e.target.checked) {
      setPublishedFilter(true);
      setUnpublishedFilter(false);
    } else if (!e.target.checked) {
      setPublishedFilter(false);
    }
  };

  const handleUnpublishedFilter = (e) => {
    if (e.target.checked) {
      setUnpublishedFilter(true);
      setPublishedFilter(false);
    } else if (!e.target.checked) {
      setUnpublishedFilter(false);
    }
  };

  const getDiscounts = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setDiscountsData([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/discounts?limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=code=" +
        searchQuery +
        "&" +
        `${
          !publishedFilter && !unpublishedFilter
            ? ""
            : publishedFilter || unpublishedFilter
            ? "filter=isPublish=eq:" + publishedFilter
            : ""
        }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setDiscountsData(resData?.discountCoupons);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const publishUnpublishDiscount = async (id) => {
    // setLoader(true);
    const config = {
      method: "patch",
      url: api_url + "/discounts/" + id + "/is-publish",
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        // setLoader(false);
        getDiscounts();
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getDiscounts();
    }
  }, [offset, publishedFilter, unpublishedFilter, searchQuery]);

  return (
    <div className="content">
      <section className="Discount">
        <div className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 p-0">
                <div className="customerdetailheading">
                  <div className="parent-heading">
                    <h3>Discount</h3>
                    <div
                      className="itemsssss backbutton"
                      style={{ maxWidth: "718px", width: "100%" }}
                    >
                      <div className="search-field w-100">
                        <img
                          src="\nav-assets\search-normal.svg"
                          alt="img"
                          className="search-icon"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="parent-input"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div class="dropdown dropdown-text-parent discount-search">
                          <span
                            class="dropdown-text"
                            type="button"
                            id="dropdownMenuButtonww"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            by Code
                            {/* <img
                              src={dropdownDownArrow}
                              alt="img"
                              className="img-fluid ml-2"
                            /> */}
                          </span>

                          {/* <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButtonww"
                          >
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="published"
                                checked={publishedFilter}
                                onChange={(e) => handlePublishedFilter(e)}
                              />
                              <label>Published</label>
                            </div>
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="unpublished"
                                checked={unpublishedFilter}
                                onChange={(e) => handleUnpublishedFilter(e)}
                              />
                              <label>Unpublished</label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* Filters */}
                      <div class="dropdown">
                        <button
                          class="filter-dropdown-button"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="\assestsss\filter_111.svg"
                            alt="img"
                            className="img-fluid mr-2"
                          />
                          Filter by Status
                        </button>

                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="published"
                              checked={publishedFilter}
                              onChange={(e) => handlePublishedFilter(e)}
                            />
                            <label>Published</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="unpublished"
                              checked={unpublishedFilter}
                              onChange={(e) => handleUnpublishedFilter(e)}
                            />
                            <label>Unpublished</label>
                          </div>
                        </div>
                      </div>
                      {/* Filters */}
                      <button
                        className="createbtn create-discount"
                        onClick={() => {
                          setassignShow(true);
                          setOpType("add");
                        }}
                      >
                        <img src="" className="img-fluid" alt="" />
                        <img
                          src="\assestsss\add.svg"
                          className="img-fluid"
                          alt=""
                        />{" "}
                        Create Discount
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-customer">
                  <div className="table-customer">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Code</th>
                          <th scope="col">Start Time</th>
                          <th scope="col">END Time</th>
                          <th scope="col">Criteria</th>
                          <th scope="col">Amount</th>
                          <th scope="col">Percentage</th>
                          <th scope="col">PUBLISH</th>
                          <th scope="col">status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {discountsData?.length > 0 ? (
                          discountsData?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{item?.code}</td>
                                  <td>
                                    {moment(item?.startTime).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(item?.endTime).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </td>
                                  <td>Rs. {item?.criteria}</td>
                                  <td>Rs. {item?.discountAmount}</td>
                                  <td>{item?.type}</td>
                                  <td>
                                    <div className="switch-input">
                                      <input
                                        class="tgl tgl-ios"
                                        type="checkbox"
                                        id={(index + offset).toString()}
                                        defaultChecked={item?.isPublish}
                                        onChange={() =>
                                          publishUnpublishDiscount(item?.id)
                                        }
                                      />
                                      <label
                                        for={(index + offset).toString()}
                                        class="tgl-btn"
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="detailbutton">
                                      {item?.isPublish ? (
                                        <span className="blue-span">
                                          Published
                                        </span>
                                      ) : (
                                        <span className="red-span">
                                          Unublished
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="images">
                                      <img
                                        src="\assestsss\edit-2.png"
                                        className="img-fluid"
                                        alt=""
                                        onClick={() => {
                                          setassignShow(true);
                                          setOpType("edit");
                                          setEditId(item?.id);
                                        }}
                                      />
                                      <img
                                        src="\assestsss\trash.svg"
                                        className="img-fluid"
                                        alt=""
                                        onClick={() => {
                                          setassignShow2(true);
                                          setDeleteId(item?.id);
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : loader ? (
                          <tr>
                            <td colSpan="8" className="text-center">
                              <div className="text-center">
                                {<Spinner animation="border" variant="info" />}
                                {/* <h4>No Categories</h4> */}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <p>No Records</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    {/* {count > 0 && count <= limit ? (
                          <>
                            <p>
                              SHOWING 1-{count} OF {count}
                            </p>
                          </>
                        ) : count > limit ? (
                          <>
                            <p>
                              SHOWING{" "}
                              {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                              {limit * offset > count ? count : limit * offset}{" "}
                              OF {count}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>SHOWING 0 OF 0</p>
                          </>
                        )} */}
                    <p>SHOWING 0 OF 0</p>
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        {/* <li class="page-item">
                              {offset > 1 ? (
                                <>
                                  <a
                                    class="page-link"
                                    href="#"
                                    aria-label="Previous"
                                    onClick={() =>
                                      setOffset((prev) => prev - 1)
                                    }
                                  >
                                    <span aria-hidden="true">
                                      <i class="fas fa-angle-left"></i>
                                    </span>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                            </li> */}
                        {/* {pageCount.length > 1 ? (
                              <>
                                {pageCount.map((item) => {
                                  return (
                                    <>
                                      <li className="page-item">
                                        <a
                                          className={
                                            item + 1 == offset
                                              ? "page-link active"
                                              : "page-link"
                                          }
                                          href="#"
                                          onClick={() => setOffset(item + 1)}
                                        >
                                          {item + 1}
                                        </a>
                                      </li>
                                    </>
                                  );
                                })}
                              </>
                            ) : null} */}{" "}
                        <li class="page-item">
                          {/* {offset < page ? (
                                <>
                                  <a
                                    class="page-link"
                                    href="#"
                                    aria-label="Next"
                                    onClick={() =>
                                      setOffset((prev) => prev + 1)
                                    }
                                  >
                                    <span aria-hidden="true">
                                      <i class="fas fa-angle-right"></i>
                                    </span>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )} */}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CreateDiscountModal
        assignshow={assignshow}
        setassignShow={setassignShow}
        updateList={getDiscounts}
        setOpType={setOpType}
        opType={opType}
        editId={editId}
        setEditId={setEditId}
      />
      <Delete
        show1={assignshow2}
        setShow1={setassignShow2}
        id={deleteId}
        updateList={getDiscounts}
        url={url}
      />
    </div>
  );
};

export default Discount;
