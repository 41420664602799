import React from "react";
import "./login.scss";
import { Link } from "react-router-dom";
const Checkemail = () => {
  return (
    <>
      <div className="content">
        <section className="checkemail">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-sm-7 m-auto">
                <div className="checkemailcard">
                  <div className="imagecard">
                    <img
                      src="\assestsss\Confirmed-cuate 1.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <h3>Check your Email</h3>
                  <p>
                    We have sent a password recovery instructions to your
                    <br></br> email.
                  </p>
                  <div className="ok">
                    <Link to="/adminlogin">
                      {" "}
                      <button>Ok</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Checkemail;
