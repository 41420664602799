import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Delete from "views/Delete_Modal/Delete";
import "./ourstaff.scss";
import UpdateStaff from "./UpdateStaff";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

const Ourstaff = () => {
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url3;
  const api_url1 = Environment.base_url2;

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [staffData, setStaffData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");

  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const url = api_url + "/staffs/";

  const [searchQuery, setSearchQuery] = useState("");
  const [filterName, setFilterName] = useState("");
  const [filterRole, setFilterRole] = useState("");

  const [nameFilter, setNameFilter] = useState(false);
  const [emailFilter, setEmailFilter] = useState(false);
  const [phoneNumberFilter, setPhoneNumberFilter] = useState(false);
  const [roleFilter, setRoleFilter] = useState(false);

  const handleNameFilter = (e) => {
    if (e.target.checked) {
      setFilterName("name");
      setFilterRole("name");
      setNameFilter(true);
      setEmailFilter(false);
      setPhoneNumberFilter(false);
      setRoleFilter(false);
    } else if (!e.target.checked) {
      setNameFilter(false);
      setFilterName("name");
    }
  };

  const handleEmailFilter = (e) => {
    if (e.target.checked) {
      setFilterName("email");
      setFilterRole("email");

      setEmailFilter(true);
      setNameFilter(false);
      setPhoneNumberFilter(false);
      setRoleFilter(false);
    } else if (!e.target.checked) {
      setEmailFilter(false);
      setFilterName("name");
    }
  };

  const handlePhoneNumberFilter = (e) => {
    if (e.target.checked) {
      setFilterName("phoneNumber");
      setFilterRole("phoneNumber");

      setPhoneNumberFilter(true);
      setNameFilter(false);
      setEmailFilter(false);
      setRoleFilter(false);
    } else if (!e.target.checked) {
      setPhoneNumberFilter(false);
      setFilterName("name");
    }
  };
  const handleRoleFilter = (e) => {
    if (e.target.checked) {
      setFilterName("name");
      setFilterRole("role");

      setRoleFilter(true);

      setPhoneNumberFilter(false);
      setNameFilter(false);
      setEmailFilter(false);
    } else if (!e.target.checked) {
      setRoleFilter(false);
      setFilterName("name");
    }
  };

  const FetchRefreshToken = async () => {
    localStorage.removeItem("accessToken");
    let refreshVal = localStorage.getItem("refreshToken");
    var config = {
      method: "post",
      url: api_url1 + `/auth/staffs/refresh-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { refreshToken: refreshVal },
    };
    await axios(config)
      .then(function (response) {
        localStorage.removeItem("refreshToken");
        console.log(response.data.data);
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);
        history.push("/admin/dashboard");
      })
      .catch(function (error) {
        console.log(error);
        // history.push('/login');
        if (error.response.data.status === 400) {
          // toast.error('Validation Failed', {
          //   position: 'top-right',
          //   autoClose: 3000,
          // });
          console.log(error);
        } else if (error.response.data.status === 401) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          history.push("/");
        } else if (error.response.data.status === 403) {
          toast.error("403", {
            position: "top-right",
            autoClose: 3000,
          });
        } else if (error.response.data.status === 404) {
          toast.error("Whitelisted user not found", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      });
  };

  const getStaffList = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setStaffData([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/staffs?limit=" +
        limit +
        "&offset=" +
        offset +
        `&${filterName ? "search=" + filterName + "=" + searchQuery : ""}&${
          filterRole == "role" ? "searchByRole=true" : "searchByRole=false"
        }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setStaffData(resData?.staff);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);

        setPage(resData?.pages);
        // for (let i = 1; i <= responseData?.pages; i++) {
        //   pageLength.push(i);
        // }
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getStaffList();
    }
  }, [offset, searchQuery]);

  return (
    <>
      <div className="content">
        <section className="ourstaff">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 col-12 p-0">
                <div className="parent-heading">
                  <h4>All Staff</h4>
                  <div
                    className="itemsssss"
                    style={{ maxWidth: "373px", width: "100%" }}
                  >
                    <div className="search-field w-100">
                      <img
                        src="\nav-assets\search-normal.svg"
                        alt="img"
                        className="search-icon"
                      />
                      <input
                        type="text"
                        placeholder="Search"
                        className="parent-input"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <div class="dropdown dropdown-text-parent">
                        <h6
                          className="dropdown-text"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          By {filterRole}{" "}
                          <img
                            src="\assestsss\arrow1.svg"
                            alt="img"
                            className="img-fluid ml-1"
                          />
                        </h6>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={nameFilter}
                              onChange={(e) => handleNameFilter(e)}
                            />
                            <label>By Name</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={emailFilter}
                              onChange={(e) => handleEmailFilter(e)}
                            />
                            <label>By Email</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={phoneNumberFilter}
                              onChange={(e) => handlePhoneNumberFilter(e)}
                            />
                            <label>By Phone</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={roleFilter}
                              onChange={(e) => handleRoleFilter(e)}
                            />
                            <label>By Role</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a
                      className="addcategory-btn"
                      onClick={() => {
                        setShow(true);
                        setOpType("add");
                      }}
                    >
                      <img
                        src="../../assestsss/add.svg"
                        class="img-fluid"
                        alt=""
                      />
                      Add Staff
                    </a>
                  </div>
                </div>
                <div className="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">NAME</th>
                        <th scope="col">Email</th>
                        <th scope="col">Contact</th>
                        <th scope="col">joining date</th>
                        <th scope="col">Role</th>
                        <th scope="col">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {staffData ? (
                        staffData.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td className="id">3680</td>
                                <td>
                                  <img
                                    src={item?.imageUrl}
                                    alt="img"
                                    className="img-fluid mr-2 staff-pic"
                                  />{" "}
                                  {item?.name}
                                </td>
                                <td>{item?.email}</td>
                                <td>{item?.phoneNumber}</td>
                                <td>
                                  {moment(item?.joiningDate).format(
                                    "DD-MMM-YYYY"
                                  )}
                                </td>
                                <td>{item?.role?.name}</td>
                                <td>
                                  <a
                                    onClick={() => {
                                      setShow(true);
                                      setOpType("edit");
                                      setEditId(item?.id);
                                    }}
                                  >
                                    <img
                                      src="\users-assets\edit-icon.svg"
                                      alt="img"
                                      className="img-fluid mr-2"
                                    />
                                  </a>
                                  <a
                                    onClick={() => {
                                      setShow1(true);
                                      setDeleteId(item?.id);
                                    }}
                                  >
                                    <img
                                      src="\users-assets\trash-icon.svg"
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </a>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            <div className="text-center">
                              {<Spinner animation="border" variant="info" />}
                              {/* <h4>No Categories</h4> */}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    {count > 0 && count <= limit ? (
                      <>
                        <p>
                          SHOWING 1-{count} OF {count}
                        </p>
                      </>
                    ) : count > limit ? (
                      <>
                        <p>
                          SHOWING{" "}
                          {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                          {limit * offset > count ? count : limit * offset} OF{" "}
                          {count}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>SHOWING 0 OF 0</p>
                      </>
                    )}
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item">
                          {offset > 1 ? (
                            <>
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Previous"
                                onClick={() => setOffset((prev) => prev - 1)}
                              >
                                <span aria-hidden="true">
                                  <i class="fas fa-angle-left"></i>
                                </span>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                        {pageCount.length > 1 ? (
                          <>
                            {pageCount.map((item) => {
                              return (
                                <>
                                  <li className="page-item">
                                    <a
                                      className={
                                        item + 1 == offset
                                          ? "page-link active"
                                          : "page-link"
                                      }
                                      href="#"
                                      onClick={() => setOffset(item + 1)}
                                    >
                                      {item + 1}
                                    </a>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : null}

                        <li class="page-item">
                          {offset < page ? (
                            <>
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Next"
                                onClick={() => setOffset((prev) => prev + 1)}
                              >
                                <span aria-hidden="true">
                                  <i class="fas fa-angle-right"></i>
                                </span>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Delete
          setShow1={setShow1}
          show1={show1}
          id={deleteId}
          updateList={getStaffList}
          url={url}
        />
        <UpdateStaff
          show={show}
          setShow={setShow}
          setOpType={setOpType}
          opType={opType}
          editId={editId}
          updateList={getStaffList}
        />
      </div>
    </>
  );
};

export default Ourstaff;
