import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Environment from "utils/environment";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import "./deliverycharges.scss";

const DeliveryCharges = () => {
  const val = localStorage.getItem("accessToken");
  const history = useHistory();
  const [newPrice, setNewPrice] = useState("");
  const [minOrderValue, setMinOrder] = useState("");
  const [distance, setDistance] = useState("");

  const [loader, setLoader] = useState(false);
  const [post, setPost] = useState([]);
  const metaData = async () => {
    await axios
      .get(`${Environment.base_url3}/metadata`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setPost(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      metaData();
    }
  }, []);

  const UpdateMetadata = async (e) => {
    const config = {
      method: "patch",
      url: `${Environment.base_url3}/metadata/${1}`,
      data: {
        deliveryFee: newPrice,
        deliveryRadius: distance,
        minOrder: minOrderValue,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        metaData();
        toast.success(res?.data?.message);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  return (
    <>
      <div className="content">
        <section className="charges">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 p-0">
                <h1 className="main-text">Delivery Charges</h1>
                <div className="charges-main">
                  <h3>Delivery Charges per KM (Rs)</h3>
                  <div className="d-flex updatee pb-3">
                    <input
                      placeholder={post?.deliveryFee}
                      value={newPrice}
                      onChange={(e) => setNewPrice(e.target.value)}
                    ></input>
                  </div>
                  <h3 className="pt-2">
                    Maximum Distance for free delivery (KM)
                  </h3>
                  <div className="d-flex updatee pb-3">
                    <input
                      placeholder=""
                      value={distance}
                      onChange={(e) => setDistance(e.target.value)}
                    ></input>
                  </div>
                  <h3 className="pt-2">Minimum Order Value (Rs)</h3>
                  <div className="d-flex updatee pb-3">
                    <input
                      placeholder=""
                      value={minOrderValue}
                      onChange={(e) => setMinOrder(e.target.value)}
                    ></input>
                  </div>
                  <button onClick={UpdateMetadata}>Update</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DeliveryCharges;
