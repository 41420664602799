import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./login.scss";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { Search } from "@material-ui/icons";
const Forgetpassword = () => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url2;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const forgotPassword = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setError("");
    if (isValidEmail(email)) {
      const config = {
        method: "post",
        url: api_url + "/admins/forget-password",
        data: {
          email: email,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          // toast.success(res?.data?.message);
          history.push("/Checkemail");
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.data?.statusCode == 501) {
            history.push("/");
          } else if (err?.response?.data?.statusCode == 404) {
            setError("Admin doesn't exist!");
          } else {
            console.log("error meessage: ", err?.response?.data?.message);
            toast.error(err?.response?.data?.message, {
              position: "bottom-left",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    } else {
      setError("Invalid Email! Please enter a correct email address.");
    }

    // }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  return (
    <>
      <div className="content">
        <section className="main-forgetpassword">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-sm-7 m-auto">
                <div className="maincardforget">
                  <div className="imagecard">
                    <img
                      src="\assestsss\Digital nomad-rafiki 1.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="main-text">
                    <h3>Forgot your Password?</h3>
                    <p>
                      Enter your registered email to receive password reset
                      <br></br> instructions.
                    </p>
                    <div className="parainput">
                      <h3 className="inputpara mt-0">Email address</h3>
                      <input
                        className="maininput"
                        placeholder="Email Address"
                        type="text"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError("");
                        }}
                      />
                      <p className="text-danger">{error}</p>
                    </div>
                    <div className="send">
                      {/* <Link to="/Checkemail"> */}{" "}
                      <button onClick={forgotPassword}>Send</button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Forgetpassword;
