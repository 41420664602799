import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./pushnotification.scss";
import Delete from "views/Delete_Modal/Delete";
import EditProduct from "./EditProduct";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Offcanvas } from "react-bootstrap";

const Pushnotification = () => {
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const val = localStorage.getItem("accessToken");
  const [baseImage, setBaseImage] = useState("");
  const [image, setImage] = useState("");
  
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");
  const [title, setTitle] = useState("");
  const [discription, setDiscription] = useState("");

  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const url = api_url + "/store/products/";

  const [searchQuery, setSearchQuery] = useState("");
  const [publishedFilter, setPublishedFilter] = useState(false);
  const [unpublishedFilter, setUnpublishedFilter] = useState(false);
  const [inStockFilter, setInStockFilter] = useState(false);
  const [outOfStockFilter, setOutOfStockFilter] = useState(false);
  const [pharmacy, setPharmacy] = useState(false);
  const [grocery, setGrocery] = useState(false);
  const [manual, setManual] = useState(false);

  const handlePublishedFilter = (e) => {
    if (e.target.checked) {
      setPublishedFilter(true);
      setUnpublishedFilter(false);
    } else if (!e.target.checked) {
      setPublishedFilter(false);
    }
  };

  const handleUnpublishedFilter = (e) => {
    if (e.target.checked) {
      setUnpublishedFilter(true);
      setPublishedFilter(false);
    } else if (!e.target.checked) {
      setUnpublishedFilter(false);
    }
  };
  const handleInStockFilter = (e) => {
    if (e.target.checked) {
      setInStockFilter(true);
      setOutOfStockFilter(false);
    } else if (!e.target.checked) {
      setInStockFilter(false);
    }
  };

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };
  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };



  const handlePharmacy = (e) => {
    if (e.target.checked) {
      setPharmacy(true);
      setGrocery(false);
      setManual(false);
    } else if (!e.target.checked) {
      setPharmacy(false);
    }
  };


  const handleGrocery = (e) => {
    if (e.target.checked) {
      setGrocery(true);
      setPharmacy(false);
      setManual(false);
    } else if (!e.target.checked) {
      setGrocery(false);
    }
  };

  const handleManual = (e) => {
    if (e.target.checked) {
      setManual(true);
      setGrocery(false);
      setPharmacy(false);
    } else if (!e.target.checked) {
      setManual(false);
    }
  };

  const getProducts = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setProductsData([]);
    const config = {
      method: "get",
      url: api_url + "/store/products/admin-products?limit=" + limit + "&offset=" + offset + "&search=name=" + searchQuery + "&" +
        `${!publishedFilter &&
          !unpublishedFilter &&
          !inStockFilter &&
          !outOfStockFilter
          ? ""
          : publishedFilter || unpublishedFilter
            ? "filter=isPublish=eq:" + publishedFilter
            : inStockFilter
              ? "filter=quantity=gt:0"
              : outOfStockFilter
                ? "filter=quantity=lte:0"
                : ""
        }` + `${grocery
          ? "&type=grocer"
          : pharmacy
            ? "&type=PHLP"
            : manual
              ? "&type=manual"
              : ""
        }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setProductsData(resData?.products);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const publishUnpublishProduct = async (id) => {
    // setLoader(true);
    const config = {
      method: "patch",
      url: api_url + "/store/products/" + id + "/is-publish",
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        // setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getProducts();
    }
  }, [
    offset,
    publishedFilter,
    unpublishedFilter,
    inStockFilter,
    outOfStockFilter,
    searchQuery,
    grocery,
    pharmacy,
    manual,
  ]);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const Addunit = async (e) => {
    if (title.length === 0) {
        toast.error("Enter Title")
    } else if (discription.length === 0) {
        toast.error("Enter Discription")
    } else {
      const formData = new FormData();
      if (title) {
        formData.append('title', title);
        }
        if (discription) {
          
          formData.append('description', discription);
        }
        if (image) {
          
          formData.append('notificationImage', image);
        }
        const config = {
            method: "post",
            url: `${Environment.base_url4}/notifications/push-notification`,
            data: formData,
            headers: {
                Authorization: "Bearer " + val,
            },
        };
        await axios(config)
            .then((res) => {
              handleClose2();
                toast.success(res?.data?.message);
                setLoader(false);
            })
            .catch((err) => {
                if (err?.response?.statusCode == 501) {
                    history.push("/");
                } else {
                    console.log("error meessage: ", err?.response?.data?.message);
                    toast.error(err?.response?.data?.message, {
                        position: "bottom-left",
                        autoClose: 2000,
                    });
                }
                setLoader(false);
            });
    }
}
  return (
    <>
      <div className="content">
        <section className="product">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 col-12 p-0">
                <div className="parent-heading">
                  <h4>Push Notifications</h4>
                  <div className="itemsssss">
                    <div className="search-field">
                      <img
                        src="\nav-assets\search-normal.svg"
                        alt="img"
                        className="search-icon"
                      />
                      <input
                        type="text"
                        placeholder="Search"
                        className="parent-input"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value.replace(/\+/g, '%2B').replace(/&/g, '%26'))}
                      />
                    </div>
                    <div class="dropdown">
                      <button
                        class=""
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img
                          src="\assestsss\filter_111.svg"
                          alt="img"
                          className="img-fluid mr-2"
                        />
                        Filter
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={publishedFilter}
                            onChange={(e) => handlePublishedFilter(e)}
                          />
                          <label>Fired</label>
                        </div>
                        <div className="input-field">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                            checked={unpublishedFilter}
                            onChange={(e) => handleUnpublishedFilter(e)}
                          />
                          <label>Scheduled</label>
                        </div>

                      </div>
                    </div>
                    <div className="right">
                      <a
                        className="addcategory-btn"
                        onClick={() => {
                          handleShow2();
                          setOpType("add");
                        }}
                      >
                        <img src="\assestsss\add.svg" className="img-fluid" /> Add
                        Fire Notification
                      </a>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Fired at</th>
                        <th scope="col">Notification </th>
                        <th scope="col">Fired</th>
                        <th scope="col">EDIT</th>
                        {/* <th scope="col">PackSize</th>
                        <th scope="col">STOCK</th> */}
                        {/* <th scope="col">STATUS</th> */}
                        {/* <th scope="col">PUBLISH</th>
                        <th scope="col">ACTIONS</th> */}
                      </tr>
                    </thead>
                    <tbody>
                    
                              <tr>
                                <td className="id">Aug 28, 2022
                                  02:31 PM</td>
                                <td>
                                  <svg className="mx-2" xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
                                    <path d="M19.9754 11.2026C19.9563 11.1804 19.9282 11.1672 19.8986 11.1672H0.101515C0.0719417 11.1672 0.0440113 11.1802 0.0247065 11.2026C0.00540167 11.2247 -0.00322389 11.2545 0.00108889 11.2839C0.00396407 11.3024 0.302573 13.1355 2.62018 13.1355H17.3799C19.6973 13.1355 19.996 11.3024 19.999 11.2839C20.0031 11.2545 19.9945 11.2247 19.9754 11.2026ZM11.4889 12.4935H8.51102V11.8088H11.4889V12.4935Z" fill="#009DE0" />
                                    <path d="M2.15787 10.4546H17.842C18.0687 10.4546 18.2528 10.2706 18.2528 10.0438V0.410741C18.2528 0.184012 18.0687 0 17.842 0H2.15787C1.93114 0 1.74713 0.184012 1.74713 0.410741V10.0438C1.74713 10.2706 1.93114 10.4546 2.15787 10.4546ZM3.18472 1.43759H16.8152V9.01699H3.18472V1.43759Z" fill="#009DE0" />
                                  </svg>
                                  <img
                                    src="\assestsss\Bigicture.png"
                                    alt="img"
                                    className="img-fluid mr-2 inertablebigimg"
                                  />{" "}
                                  Our pick to made you wow 😯
                                </td>
                                {/* <td>{item?.category[0]?.name}</td>
                                <td className="text-caps">Rs. {item?.price}</td>
                                <td className="text-caps">{item?.packSize}</td> */}
                                {/* <td>{item?.quantity}</td> */}
                                {/* <td>
                                  {item?.status == "selling" ? (
                                    <>
                                      <span className="btn-selling">
                                        Selling
                                      </span>
                                    </>
                                  ) : item?.status == "outOfStock" ? (
                                    <span className="btn-selling stockout">
                                      Out of Stock
                                    </span>
                                  ) : null}
                                </td> */}
                                <td>
                                  <div className="switch-input">
                                    <input
                                      class="tgl tgl-ios"
                                    
                                      type="checkbox"
                                     
                                    />
                                    <label
                                      class="tgl-btn"
                                     
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <a
                                   
                                  >
                                    <img
                                      src="\users-assets\edit-icon.svg"
                                      alt="img"
                                      className="img-fluid mr-2"
                                    />
                                  </a>
                                  <a
                                  
                                  >
                                    <img
                                      src="\users-assets\trash-icon.svg"
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </a>
                                </td>
                              </tr>
                     
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                   
                        <p>SHOWING 0 OF 0</p>
                   
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={page}
                            page={offset}
                            onChange={(e, p) => setOffset(p)}
                            color="primary"
                          />
                        </Stack>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Delete
          setShow1={setShow1}
          show1={show1}
          id={deleteId}
          updateList={getProducts}
          url={url}
        />
        <EditProduct
          show={show}
          setShow={setShow}
          updateList={getProducts}
          editId={editId}
          setEditId={setEditId}
        />
        <Offcanvas show={show2} onHide={handleClose2}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add Notification</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
              <div className="bannerss">
                <h4>Notification Image</h4>
                <div className="item-upload">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <input
                        id="upload"
                        type="file"
                        className="d-none"
                        onChange={uploadImages}
                      />
                      <label for="upload" className="">
                        {baseImage ? (
                          <img
                            src={baseImage}
                            alt="img"
                            className="img-fluid upload-img"
                          />
                        ) : (
                          <>
                            <div>
                              <img
                                src="\assestsss\gallery-add.svg"
                                className="img-fluid"
                              />
                              <h4 className="">Drag your image here</h4>
                              <p>
                                (Only *.jpeg and *.png images will be accepted)
                              </p>
                            </div>

                            {/* <h6>Upload user</h6> */}
                          </>
                        )}
                        {/* <img src="\assestsss\Layer.svg" className="img-fluid" /> */}
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="filedinput">
                
                  <p>Title*</p>
                  <input
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <p>Discription</p>
                  <textarea
                    className="productarea"
                    placeholder="Discription"
                    value={discription}
                    onChange={(e) => setDiscription(e.target.value)}
                  />
                  {/* <p>Category</p> */}

                 
             
                  {/* <div class="dropdown">
                    <button
                      class="btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Select Parent Category
                      <img src="\assestsss\arrow1.svg" alt="img" className="" />
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                    </div>
                  </div> */}

                 
                 

                  {/* <p>Child Category</p>
                  <div class="dropdown">
                    <button
                      class="btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Select Parent Category
                      <img src="\assestsss\arrow1.svg" alt="img" className="" />
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                    </div>
                  </div> */}

              
                
                  {/* <p>Sale Price</p>
                  <input
                    type="text"
                    placeholder="Product Name*"
                    value={salePrice}
                    onChange={(e) => setSalePrice(e.target.value)}
                  /> */}
                </div>
                <div className="buttonss">
                  <button className="" onClick={handleClose2}>
                    Cancel
                  </button>
                  <button className="ml-2" onClick={Addunit}>
                    Push Notification{" "}
                  </button>
                </div>
              </div>
            </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Pushnotification;
