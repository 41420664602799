import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import axios from "axios";
import { riders } from "./Rider";
import "react-toastify/dist/ReactToastify.css";
import Environment from "utils/environment";
import { ToastContainer, toast } from "react-toastify";

// import { edit } from "jquery";
const Editrider = ({ editrider, setEditrider, edit, update, getRiders }) => {
  const val = localStorage.getItem("accessToken");
  const handleClose = () => setEditrider(false);

  const [name, setName] = useState("");
  const [cnic, setCnic] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [date, setDate] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (edit) {
      setName(edit?.name);
      setCnic(edit?.cnicNumber);
      setEmail(edit?.email);
      setContact(edit?.phoneNumber);
      setDate(edit?.joiningDate);
      setRiderimage(edit?.imageUrl);
      setIdfront(edit?.idCardFrontImageUrl);
      setIdback(edit?.idCardBackImageUrl);
      setLicfront(edit?.licFrontImageUrl);
      setLicback(edit?.licBackImageUrl);
    }
  }, [edit]);

  // rider image
  const [riderimage, setRiderimage] = useState(null);
  const [riderimageUp, setRiderimageUp] = useState(null);

  const onImageChange = (event) => {
    setRiderimageUp(event.target.files[0]);
    setRiderimage(event.target.files[0]);
  };
  // ID front
  const [idfront, setIdfront] = useState(null);
  const [idfrontUp, setIdfrontUp] = useState(null);

  const onImageChange2 = (event) => {
    setIdfrontUp(event.target.files[0]);
    setIdfront(event.target.files[0]);
  };
  // ID back
  const [idback, setIdback] = useState(null);
  const [idbackUp, setIdbackUp] = useState(null);

  const onImageChange3 = (event) => {
    setIdback(event.target.files[0]);
    setIdbackUp(event.target.files[0]);
  };
  // Lic front
  const [licfront, setLicfront] = useState(null);
  const [licfrontUp, setLicfrontUp] = useState(null);

  const onImageChange4 = (event) => {
    setLicfront(event.target.files[0]);
    setLicfrontUp(event.target.files[0]);
  };
  // Lic back
  const [licback, setLicback] = useState(null);
  const [licbackUp, setLicbackUp] = useState(null);

  const onImageChange5 = (event) => {
    setLicback(event.target.files[0]);
    setLicbackUp(event.target.files[0]);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  // update rider
  const Updaterider = async () => {
    if (!riderimage) {
      toast.error("Upload Image");
    } else if (!idfront) {
      toast.error("Upload Cnic Front");
    } else if (!idback) {
      toast.error("Upload Cnic Back");
    } else if (!licfront) {
      toast.error("Upload License Front");
    } else if (!licback) {
      toast.error("Upload License Back");
    } else if (name.length === 0) {
      toast.error("Enter Name");
    } else if (cnic.length === 0) {
      toast.error("Enter Cnic");
    } else if (email.length === 0) {
      toast.error("Enter Email");
    } else if (!isValidEmail(email)) {
      toast.error("Email is invalid");
    } else if (contact.length === 0) {
      toast.error("Enter Contact no");
    } else if (date.length === 0) {
      toast.error("Enter Date");
    } else {
      const formData = new FormData();
      if (name) {
        formData.append("name", name);
      }
      if (cnic) {
        formData.append("cnicNumber", cnic);
      }
      if (date) {
        formData.append("joiningDate", date);
      }
      if (contact) {
        formData.append("phoneNumber", contact);
      }
      if (email) {
        formData.append("email", email);
      }
      if (riderimageUp) {
        formData.append("riderImage", riderimageUp);
      }
      if (idfrontUp) {
        formData.append("riderCnicFront", idfrontUp);
      }
      if (idbackUp) {
        formData.append("riderCnicBack", idbackUp);
      }
      if (licfrontUp) {
        formData.append("riderLicFront", licfrontUp);
      }
      if (licbackUp) {
        formData.append("riderLicBack", licbackUp);
      }
      var config = {
        method: "patch",
        url: `${Environment.base_url3}/riders/${update}`,
        data: formData,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      console.log(edit, "dddd");
      await axios(config)
        .then(function (response) {
          // console.log("tokeen: ", response?.data);
          getRiders();
          toast.success(response?.data?.message);

          setLoader(false);
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);

          setLoader(false);
        });
    }
    console.log(cnic);
  };

  return (
    <>
      <div className="content">
        <section className="assingrider">
          <Offcanvas
            show={editrider}
            onHide={handleClose}
            className="update_staff"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Update Staff</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="bannerss">
                <h4>Rider Image*</h4>
                <div className="item-upload">
                  <ul className="list-inline text-center">
                    <li className="list-inline-item">
                      <input
                        id="upload1"
                        type="file"
                        className="d-none"
                        onChange={onImageChange}
                      />
                      <label for="upload1" className="">
                        {riderimage ? (
                          <img
                            src={
                              typeof riderimage === "string"
                                ? riderimage
                                : URL.createObjectURL(riderimage)
                            }
                            alt="img"
                            className="img-fluid upload-img"
                          />
                        ) : (
                          <>
                            <div>
                              <img
                                src="\assestsss\gallery-add.svg"
                                className="img-fluid"
                              />
                              <h4 className="">Drag your image here</h4>
                              <p>
                                (Only *.jpeg and *.png images will be accepted)
                              </p>
                            </div>
                          </>
                        )}
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="mainidcard">
                  <div className="idcard mr-3">
                    <h4>ID Card Image Front*</h4>
                    <div className="item-upload">
                      <ul className="list-inline ">
                        <li className="list-inline-item">
                          <input
                            id="upload2"
                            type="file"
                            className="d-none"
                            onChange={onImageChange2}
                          />
                          <label for="upload2" className="">
                            {idfront ? (
                              <img
                                src={
                                  typeof idfront === "string"
                                    ? idfront
                                    : URL.createObjectURL(idfront)
                                }
                                alt="img"
                                className="img-fluid upload-img"
                              />
                            ) : (
                              <>
                                <div>
                                  <img
                                    src="\assestsss\gallery-add.svg"
                                    className="img-fluid"
                                  />
                                  <h4 className="">Drag your image here</h4>
                                  <p>
                                    (Only *.jpeg and *.png images will be
                                    accepted)
                                  </p>
                                </div>
                              </>
                            )}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="idcard">
                    <h4>ID Card Image Back*</h4>
                    <div className="item-upload">
                      <ul className="list-inline ">
                        <li className="list-inline-item">
                          <input
                            id="upload3"
                            type="file"
                            className="d-none"
                            onChange={onImageChange3}
                          />
                          <label for="upload3" className="">
                            {idback ? (
                              <img
                                src={
                                  typeof idback === "string"
                                    ? idback
                                    : URL.createObjectURL(idback)
                                }
                                alt="img"
                                className="img-fluid upload-img"
                              />
                            ) : (
                              <>
                                <div>
                                  <img
                                    src="\assestsss\gallery-add.svg"
                                    className="img-fluid"
                                  />
                                  <h4 className="">Drag your image here</h4>
                                  <p>
                                    (Only *.jpeg and *.png images will be
                                    accepted)
                                  </p>
                                </div>
                              </>
                            )}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mainidcard">
                  <div className="idcard mr-3">
                    <h4>Licence Image Front*</h4>
                    <div className="item-upload">
                      <ul className="list-inline ">
                        <li className="list-inline-item">
                          <input
                            id="upload4"
                            type="file"
                            className="d-none"
                            onChange={onImageChange4}
                          />
                          <label for="upload4" className="">
                            {licfront ? (
                              <img
                                src={
                                  typeof licfront === "string"
                                    ? licfront
                                    : URL.createObjectURL(licfront)
                                }
                                alt="img"
                                className="img-fluid upload-img"
                              />
                            ) : (
                              <>
                                <div>
                                  <img
                                    src="\assestsss\gallery-add.svg"
                                    className="img-fluid"
                                  />
                                  <h4 className="">Drag your image here</h4>
                                  <p>
                                    (Only *.jpeg and *.png images will be
                                    accepted)
                                  </p>
                                </div>
                              </>
                            )}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="idcard">
                    <h4>Licence Image Back*</h4>
                    <div className="item-upload">
                      <ul className="list-inline ">
                        <li className="list-inline-item">
                          <input
                            id="upload5"
                            type="file"
                            className="d-none"
                            onChange={onImageChange5}
                          />
                          <label for="upload5" className="">
                            {licback ? (
                              <img
                                src={
                                  typeof licback === "string"
                                    ? licback
                                    : URL.createObjectURL(licback)
                                }
                                alt="img"
                                className="img-fluid upload-img"
                              />
                            ) : (
                              <>
                                <div>
                                  <img
                                    src="\assestsss\gallery-add.svg"
                                    className="img-fluid"
                                  />
                                  <h4 className="">Drag your image here</h4>
                                  <p>
                                    (Only *.jpeg and *.png images will be
                                    accepted)
                                  </p>
                                </div>
                              </>
                            )}
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="filedinput">
                  <p>Rider Name*</p>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <p>CNIC No.*</p>
                  <input
                    type="text"
                    value={cnic}
                    onChange={(e) => setCnic(e.target.value)}
                  />
                  <p>Email</p>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <p>Contact Number*</p>
                  <input
                    type="text"
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                  />
                  <p>Joining Date</p>
                  <input
                    type="text"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>

                <div className="buttonss">
                  <button className="">Cancel</button>
                  <button
                    onClick={() => {
                      Updaterider();
                      handleClose();
                    }}
                    className="ml-2"
                  >
                    Update Rider{" "}
                  </button>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};
export default Editrider;
