import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./ratelist.scss";
import Delete from "views/Delete_Modal/Delete";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { LineStyle } from "@material-ui/icons";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Ratelistsidebar from "./Ratelistsidebar";
import AddProduct from "./AddProduct";
const Ratelist = () => {
  const [show22, setShow22] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  // const [show, setShow] = useState(false);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [productsData, setProductsData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const subUrl = "/store/products/";

  const getProducts = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setProductsData([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/store/products/admin-products?manualProduct=true&limit=" +
        limit +
        "&offset=" +
        offset ,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setProductsData(resData?.products);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const publishUnpublishProduct = async (id) => {
    // setLoader(true);
    const config = {
      method: "patch",
      url: api_url + "/store/products/" + id + "/is-publish",
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getProducts();
    }
  }, [offset, searchQuery]);

  return (
    <>
      <div className="content">
        <section className="product-ratelist">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 col-12 p-0">
                {/* <div className="parent-headings">
                  <h4>Rate list</h4>
                  <div className="mainhead">
                    <div className="searching">
                    <img
                      src="\nav-assets\search-normal.svg"
                      alt="img"
                      className="main-search-image"
                    />
                    <input
                      type="search"
                      placeholder="Serach"
                      className="main-search-input"
                    />
                    </div>
                    <a 
                    onClick={() => setShow(true)}
                    >
                    <button>Add product</button>
                    </a>
                  </div>
                </div> */}
                <div className="parent-heading">
                  <h4>Products</h4>
                  <div className="itemsssss">
                    <div className="search-field">
                      <img
                        src="\nav-assets\search-normal.svg"
                        alt="img"
                        className="search-icon"
                      />
                      {/* <input
                        type="text"
                        placeholder="Search"
                        className="parent-input"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      /> */}
                    </div>
                    <div class="dropdown">
                      <button
                        class=""
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() => setShow(true)}
                      >

                        Add Product
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  {/* <div className="main-tabsstyles">
                    <nav>
                      <div
                        className="nav nav-tabs "
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link active tabbuttons mx-2"
                          id="nav-Fruits-tab"
                          data-toggle="tab"
                          data-target="#nav-Fruits"
                          type="button"
                          role="tab"
                          aria-controls="nav-Fruits"
                          aria-selected="true"
                        >
                          Fruits
                        </button>
                        <button
                          className="nav-link tabbuttons mx-2"
                          id="nav-Vegetables-tab"
                          data-toggle="tab"
                          data-target="#nav-Vegetables"
                          type="button"
                          role="tab"
                          aria-controls="nav-Vegetables"
                          aria-selected="true"
                        >
                          Vegetables
                        </button>
                        <button
                          className="nav-link tabbuttons mx-2"
                          id="nav-contact-tab"
                          data-toggle="tab"
                          data-target="#nav-contact"
                          type="button"
                          role="tab"
                          aria-controls="nav-contact"
                          aria-selected="true"
                        >
                          Meat
                        </button>
                      </div>
                    </nav>
                  </div> */}
                  <div className="tab-content  " id="nav-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="nav-Fruits"
                      role="tabpanel"
                      aria-labelledby="nav-Fruits-tab"
                    >
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">PRODUCT </th>
                              <th scope="col">PRICE</th>
                              <th scope="col">STOCK </th>
                              <th scope="col">PUBLISH</th>
                              <th scope="col">ACTIONS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productsData.length > 0 ? (
                              productsData.map((item, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td className="id">
                                        {item?.sourceProductId}
                                      </td>
                                      <td className="">
                                        <div className="tableappleimg">
                                          <img
                                            src={item?.imageUrl}
                                            alt="img"
                                            className="img-fluid  tabletabsimgg mr-2"
                                          />
                                          {item?.name}
                                        </div>
                                      </td>
                                      <td>Rs. {item?.price}</td>
                                      <td>{item?.productCap?.maxCap - item?.productCap?.todaySold}</td>
                                      <td>
                                        <div className="switch-input">
                                          <input
                                            class="tgl tgl-ios"
                                            id={(index + offset).toString()}
                                            type="checkbox"
                                            defaultChecked={item?.isPublish}
                                            onChange={() =>
                                              publishUnpublishProduct(item?.id)
                                            }
                                          />
                                          <label
                                            class="tgl-btn"
                                            for={(index + offset).toString()}
                                          ></label>
                                        </div>
                                      </td>
                                      <td>
                                        <a
                                          onClick={() => {
                                            setShow22(true);
                                            setOpType("edit");
                                            setEditId(item?.id);
                                          }}
                                        >
                                          <img
                                            src="\users-assets\edit-icon.svg"
                                            alt="img"
                                            className="img-fluid mr-2 "
                                          />
                                        </a>

                                        <a
                                          onClick={() => {
                                            setShow1(true);
                                            setDeleteId(item?.id);
                                          }}
                                        >
                                          <img
                                            src="\users-assets\trash-icon.svg"
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })
                            ) : loader ? (
                              <tr>
                                <td colSpan="8" className="text-center">
                                  <div className="text-center">
                                    {
                                      <Spinner
                                        animation="border"
                                        variant="info"
                                      />
                                    }
                                    {/* <h4>No Categories</h4> */}
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <p>No Records</p>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="mainfoot">
                        <div className="left">
                          {count > 0 && count <= limit ? (
                            <>
                              <p>
                                SHOWING 1-{count} OF {count}
                              </p>
                            </>
                          ) : count > limit ? (
                            <>
                              <p>
                                SHOWING{" "}
                                {offset > 1 ? (offset - 1) * limit + 1 : offset}{" "}
                                -
                                {limit * offset > count
                                  ? count
                                  : limit * offset}{" "}
                                OF {count}
                              </p>
                            </>
                          ) : (
                            <>
                              <p>SHOWING 0 OF 0</p>
                            </>
                          )}
                        </div>
                        <div className="right">
                          <nav aria-label="Page navigation example">
                            <ul class="pagination">
                              <Stack spacing={2}>
                                <Pagination
                                  count={page}
                                  page={offset}
                                  onChange={(e, p) => setOffset(p)}
                                  color="primary"
                                />
                              </Stack>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-Vegetables"
                      role="tabpanel"
                      aria-labelledby="nav-Vegetables-tab"
                    >
                      second
                    </div>
                    <div
                      class="tab-pane fade"
                      id="nav-contact"
                      role="tabpanel"
                      aria-labelledby="nav-contact-tab"
                    >
                      third
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Delete
          setShow1={setShow1}
          show1={show1}
          id={deleteId}
          updateList={getProducts}
          subUrl={subUrl}
        />
      </div>
      <Ratelistsidebar show22={show22} setShow22={setShow22} updateList={getProducts}
        editId={editId}
        setEditId={setEditId} />

      <AddProduct
        show={show}
        setShow={setShow}
        updateList={getProducts}
      />
    </>
  );
};

export default Ratelist;
