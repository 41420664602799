import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BankSidebar = ({
  assignshow,
  setassignShow,
  opType,
  editId,
  updateList,
  setOpType,
}) => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url3;
  const history = useHistory();

  const [bankName, setBankName] = useState("");
  const [accountName, setAccountName] = useState();
  const [accountNumber, setAccountNumber] = useState("");
  const [branchName, setBranchName] = useState("");

  const handleClose = () => {
    setassignShow(false);
    setBankName("");
    setAccountName("");
    setAccountNumber("");
    setBranchName("");
    setOpType("");
  };

  const addBank = async () => {
    // validateUserName();
    // if (!errors) {

    const config = {
      method: "post",
      url: api_url + "/bank",
      data: {
        name: bankName,
        accountName: accountName,
        ...(branchName && { branchName: branchName }),
        accountNumber: accountNumber,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    const configEdit = {
      method: "patch",
      url: api_url + "/bank/" + editId,
      data: {
        name: bankName,
        accountName: accountName,
        ...(branchName && { branchName: branchName }),
        accountNumber: accountNumber,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    let req = {};
    if (opType == "add") {
      req = config;
    } else if (opType == "edit") {
      req = configEdit;
    }
    await axios(req)
      .then((res) => {
        handleClose();
        updateList();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        if (err?.response?.status == 500) {
          toast.error("Error!", {
            position: "bottom-left",
            autoClose: 2000,
          });
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };

  const getBankDetails = async () => {
    const config = {
      method: "get",
      url: api_url + "/bank/" + editId,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setBankName(resData?.name);
        setAccountName(resData?.accountName);
        setAccountNumber(resData?.accountNumber);
        setBranchName(resData?.branchName);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");

          // FetchRefreshToken();
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      });
    // }
  };

  useEffect(() => {
    if (!val) {
      localStorage.removeItem("accessToken");
      history.push("/");
      toast.error("Session Expired", {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      if (opType == "edit") {
        getBankDetails();
      }
    }
  }, [opType]);

  return (
    <>
      <div className="content">
        <section className="sidebar-banktransferproduct">
          <Offcanvas
            show={assignshow}
            onHide={handleClose}
            className="sidebar-promoproduct"
          >
            <Offcanvas.Header closeButton>
              {opType == "add" ? (
                <Offcanvas.Title>Add Bank</Offcanvas.Title>
              ) : opType == "edit" ? (
                <Offcanvas.Title>Edit Bank</Offcanvas.Title>
              ) : null}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Tabs
                defaultActiveKey="bank"
                id="uncontrolled-tab-example"
                className="settabs"
                onSelect={() => {
                  setBankName("");
                  setAccountName("");
                  setAccountNumber("");
                  setBranchName("");
                }}
              >
                <Tab eventKey="bank" title="Bank">
                  <div className="filedinput">
                    <p>Bank*</p>
                    <input
                      type="text"
                      placeholder="e.g. Meezan Bank"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <p>Account Name*</p>
                    <input
                      type="text"
                      placeholder="Account name"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                    <p>Branch*</p>
                    <div className="catagoryfield">
                      <input
                        type="text"
                        placeholder="Branch"
                        value={branchName}
                        onChange={(e) => setBranchName(e.target.value)}
                      />
                    </div>
                    <p>Account Number*</p>
                    <div className="catagoryfield">
                      <input
                        type="number"
                        placeholder="Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="other" title="Other">
                  <div className="filedinput">
                    <p>Name*</p>
                    <input
                      type="text"
                      placeholder="e.g. Jazz Cash"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                    <p>Account Name*</p>
                    <input
                      type="text"
                      placeholder="Account name"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                    <p>Account Number*</p>
                    <div className="catagoryfield">
                      <input
                        type="number"
                        placeholder="Account Number"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>

              <div className="buttonss">
                <button className="" onClick={handleClose}>
                  Cancel
                </button>
                {opType == "add" ? (
                  <button className="ml-2" onClick={addBank}>
                    Add{" "}
                  </button>
                ) : opType == "edit" ? (
                  <button className="ml-2" onClick={addBank}>
                    Save{" "}
                  </button>
                ) : null}
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};

export default BankSidebar;
