import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./DiscountCSV.scss";
// import CreateDiscountModal from "./CreateDiscountModal";
import UploadCSVModal from "./UploadCSVModal";
import DiscountDetailModal from "./DiscountDetailModal";
import Delete from "views/Delete_Modal/Delete";
import dropdownDownArrow from "../../../assets/img/dropdown-down-arrow.svg";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

const DiscountCSV = () => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [csvData, setCSVData] = useState([]);

  const [deleteId, setDeleteId] = useState("");
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const url = api_url + "/discounts/csv/";

  const [searchQuery, setSearchQuery] = useState("");
  const [publishedFilter, setPublishedFilter] = useState(false);
  const [unpublishedFilter, setUnpublishedFilter] = useState(false);

  const [assignshow, setassignShow] = useState(false);
  const [assignshow1, setassignShow1] = useState(false);
  const [assignshow2, setassignShow2] = useState(false);

  const handlePublishedFilter = (e) => {
    if (e.target.checked) {
      setPublishedFilter(true);
      setUnpublishedFilter(false);
    } else if (!e.target.checked) {
      setPublishedFilter(false);
    }
  };

  const handleUnpublishedFilter = (e) => {
    if (e.target.checked) {
      setUnpublishedFilter(true);
      setPublishedFilter(false);
    } else if (!e.target.checked) {
      setUnpublishedFilter(false);
    }
  };

  const getDiscountCSV = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setCSVData([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/discounts/csv?limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=fileName=" +
        searchQuery +
        "&" +
        `${
          !publishedFilter && !unpublishedFilter
            ? ""
            : publishedFilter || unpublishedFilter
            ? "filter=isPublish=eq:" + publishedFilter
            : ""
        }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setCSVData(resData?.files);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const publishUnpublishDiscountCSV = async (id) => {
    // setLoader(true);
    const config = {
      method: "patch",
      url: api_url + "/discounts/csv/" + id + "/is-publish",
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        // setLoader(false);
        getDiscountCSV();
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getDiscountCSV();
    }
  }, [offset, publishedFilter, unpublishedFilter, searchQuery]);

  return (
    <div className="content">
      <section className="Discount">
        <div className="content">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 p-0">
                <div className="customerdetailheading">
                  <div className="parent-heading">
                    <h3>Discount CSV</h3>
                    <div
                      className="itemsssss backbutton"
                      style={{ maxWidth: "718px", width: "100%" }}
                    >
                      <div className="search-field w-100">
                        <img
                          src="\nav-assets\search-normal.svg"
                          alt="img"
                          className="search-icon"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="parent-input"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        {/* <div class="dropdown dropdown-text-parent discountCSV-dropdown">
                          <h6
                            className="dropdown-text"
                            type="button"
                            id="dropdownMenuButton"
                            dataToggle="dropdown"
                            ariaHaspopup="true"
                            ariaExpanded="false"
                          >
                            By name
                            <img
                              src="\assestsss\arrow1.svg"
                              alt="img"
                              className="img-fluid ml-1"
                            />
                          </h6>
                          <div
                            class="dropdown-menu drop__input-field"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                // checked={categoryFilter}
                                // onChange={(e) => handleCategoryFilter(e)}
                              />
                              <label>By Category</label>
                            </div>
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                // checked={promoFilter}
                                // onChange={(e) => handlePromoFilter(e)}
                              />
                              <label>By Promo Code</label>
                            </div>
                          </div>
                        </div> */}
                        <div class="dropdown dropdown-text-parent discount-search">
                          <span
                            class="dropdown-text"
                            type="button"
                            id="dropdownMenuButtonww"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            by Name
                            {/* <img
                              src={dropdownDownArrow}
                              alt="img"
                              className="img-fluid ml-2"
                            /> */}
                          </span>

                          {/* <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButtonww"
                          >
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="published"
                                //   checked={publishedFilter}
                                //   onChange={(e) => handlePublishedFilter(e)}
                              />
                              <label>Published</label>
                            </div>
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="unpublished"
                                //   checked={unpublishedFilter}
                                //   onChange={(e) => handleUnpublishedFilter(e)}
                              />
                              <label>Unpublished</label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* Filters */}
                      <div class="dropdown">
                        <button
                          class="filter-dropdown-button"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="\assestsss\filter_111.svg"
                            alt="img"
                            className="img-fluid mr-2"
                          />
                          Filter by Status
                        </button>

                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="published"
                              checked={publishedFilter}
                              onChange={(e) => handlePublishedFilter(e)}
                            />
                            <label>Published</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="unpublished"
                              checked={unpublishedFilter}
                              onChange={(e) => handleUnpublishedFilter(e)}
                            />
                            <label>Unpublished</label>
                          </div>
                        </div>
                      </div>
                      {/* Filters */}
                      <button
                        className="createbtn Upload-CSV-btn"
                        onClick={() => {
                          setassignShow(true);
                          setOpType("add");
                        }}
                      >
                        <img src="" className="img-fluid" alt="" />
                        <img
                          src="\assestsss\add.svg"
                          className="img-fluid"
                          alt=""
                        />{" "}
                        Upload CSV
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table-customer">
                  <div className="table-customer">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">File Name</th>
                          <th scope="col">Upload Date</th>
                          <th scope="col">PUBLISH</th>
                          <th scope="col">status</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {csvData?.length > 0 ? (
                          csvData?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{item?.fileName}</td>
                                  <td>
                                    {moment(item?.createdAt).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <div className="switch-input">
                                      <input
                                        class="tgl tgl-ios"
                                        type="checkbox"
                                        id={(index + offset).toString()}
                                        defaultChecked={item?.isPublish}
                                        onChange={() =>
                                          publishUnpublishDiscountCSV(item?.id)
                                        }
                                      />
                                      <label
                                        for={(index + offset).toString()}
                                        class="tgl-btn"
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="detailbutton">
                                      {item?.isPublish ? (
                                        <span className="blue-span">
                                          Published
                                        </span>
                                      ) : (
                                        <span className="red-span">
                                          Unublished
                                        </span>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="images">
                                      {/* <button onClick={() => setassignShow1(true)}>
                                Details
                              </button> */}
                                      <img
                                        src="\assestsss\trash.svg"
                                        className="img-fluid"
                                        alt=""
                                        onClick={() => {
                                          setassignShow2(true);
                                          setDeleteId(item?.id);
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : loader ? (
                          <tr>
                            <td colSpan="8" className="text-center">
                              <div className="text-center">
                                {<Spinner animation="border" variant="info" />}
                                {/* <h4>No Categories</h4> */}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <p>No Records</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mainfoot">
                <div className="left">
                  {count > 0 && count <= limit ? (
                    <>
                      <p>
                        SHOWING 1-{count} OF {count}
                      </p>
                    </>
                  ) : count > limit ? (
                    <>
                      <p>
                        SHOWING{" "}
                        {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                        {limit * offset > count ? count : limit * offset} OF{" "}
                        {count}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>SHOWING 0 OF 0</p>
                    </>
                  )}
                </div>
                <div className="right">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <Stack spacing={2}>
                        <Pagination
                          count={page}
                          page={offset}
                          onChange={(e, p) => setOffset(p)}
                          color="primary"
                        />
                      </Stack>
                    </ul>
                  </nav>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UploadCSVModal assignshow={assignshow} setassignShow={setassignShow} />
      <DiscountDetailModal
        assignshow1={assignshow1}
        setassignShow1={setassignShow1}
      />
      <Delete
        show1={assignshow2}
        setShow1={setassignShow2}
        id={deleteId}
        updateList={getDiscountCSV}
        url={url}
      />
    </div>
  );
};

export default DiscountCSV;
