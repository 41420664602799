let Environment = {
  // test net
  // djtAddress: "0xBeDA2DcEDb2D542df346BD9ce5f85737B59d12cf",
  // dispersertestnet: "0xE7ac4Cc1da49A78875a30215BB43d3638ce316C8",
// // local
  // disperser: "0xFCBEc5f112a3b730beB1659b22fD812165B6FdbE",
  // base_url: "http://192.168.20.26:5001/products",
  // base_url1: "https://prod-api.quick.shop/riders",
  // base_url2: "http://192.168.20.25:8082/auth",
  // base_url3: "https://prod-api.quick.shop/admins",
  // base_url4: "http://192.168.20.25:4000",

// // main
  // disperser: "0xFCBEc5f112a3b730beB1659b22fD812165B6FdbE",
  base_url: "https://prod-api.quick.shop/products",
  base_url1: "https://prod-api.quick.shop/riders",
  base_url2: "https://prod-api.quick.shop/auth",
  base_url3: "https://prod-api.quick.shop/admins",
  base_url4: "https://prod-api.quick.shop",
};

export default Environment;
