import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import AssignOrder from "./AssignOrder";
import Offcanvas from 'react-bootstrap/Offcanvas';

const OderDetaits = (props) => {
  const id = props.location.state.id;
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [orderDetail, setOrderDetail] = useState([]);
  const [address, setAddress] = useState({});
  const [orderLines, setOrderLines] = useState([]);
  const [user, setUser] = useState({});
  const [productCount, setProductCount] = useState("");
  const [status, setStatus] = useState("");
  const [reciept, SetReciept] = useState([]);
  const [prescription,setPrescription] = useState([]);
  const [total, setTotal] = useState("");
  const [fee, setFee] = useState("");
  const [dis, setDis] = useState("");
  const [assignshow, setassignShow] = useState(false);

  const [showbank, setShowBank] = useState(false);
  const [showPress,setShowPress] = useState(false);

  const handleCloseBank = () => setShowBank(false);
  const handleShowBank = () => setShowBank(true);

  const handleClosePress = () => setShowPress(false);
  const handleShowPress = () => setShowPress(true);

  const getOrderDetails = async () => {
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/orders/" + id,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data;
        setOrderLines(resData?.data?.orderLines);
        setAddress(resData?.data?.address);
        setProductCount(resData?.data?.productsCount);
        setStatus(resData?.data?.status);
        setUser(resData?.data?.user);
        SetReciept(resData?.data?.transactionReceiptImageUrl);
        setPrescription(resData?.data?.prescription)
        setTotal(resData?.data?.total);
        setFee(resData?.data?.deliveryFee);
        setDis(resData?.data?.discount?.discountAmount);
        console.log("details: ", resData);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getOrderDetails();
    }
  }, []);

  const handlePrint = () => {
    window.print();
  };
  return (
    <>
      <div className="content">
        <section className="main-dashboard">
          <div className="row">
            <div className="col-sm-12 p-0">
              <div className="Overview_odersec">
                <div className="twice-item">
                  <h4 className="ooddeerr">Orders Details</h4>
                  <div>
                    <button className="view-receipt" onClick={handleShowBank}>View Receipt</button>
                    <button className="view-receipt ml-2" onClick={handleShowPress}>View Prescription</button>
                    <button className="view-receipt ml-2" onClick={handlePrint}>Print</button>
                  </div>
                </div>
                <div class="dropdown">
                  {/* <button
                  class=""
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src="\assestsss\filter_111.svg"
                    alt="img"
                    className="img-fluid mr-2"
                  />
                  Filter
                </button> */}
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div className="input-field">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label>All</label>
                    </div>
                    <div className="input-field">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label>Unpublished</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table bashbord_table">
                  <thead className="bashbord_table_head">
                    <tr className="tablerow">
                      <th scope="col">ID</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Phone No.</th>
                      <th scope="col">Address</th>
                      <th scope="col">Total Items</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="fstrowtable">
                      <td>
                        <p className="fstrownum">{user?.id}</p>{" "}
                      </td>
                      <td className="avtimgandname">
                        <p className="fstrownumsec ">
                          {" "}
                          {user?.imageUrl ? (
                            <img
                              src={user.imageUrl}
                              alt="img"
                              className="avatartable pr-2 img-fluid"
                              style={{ width: "30px", height: "30px" }}
                            />
                          ) : (
                            <img
                              src="\assestsss\Frame 40.png" // Replace with the actual path to your default image
                              alt="Default"
                              className="avatartable pr-2 img-fluid"
                              style={{ width: "30px", height: "30px" }}
                            />
                          )}
                          {user?.name}
                        </p>
                      </td>
                      <td>
                        <p className="fstrownumthrd">{address?.contactNo}</p>
                      </td>
                      <td>
                        <p className="fstrownumfour">{address?.address}</p>
                      </td>
                      <td>
                        <p className="fstrownumfive">{productCount}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="table-responsive  odertablelast mt-4 displaynone">
                <table class="table bashbord_table ">
                  <thead className="bashbord_table_head">
                    <tr className="tablerow">
                      <th scope="col">ID</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Phone No.</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="fstrowtable">
                      <td>
                        <p className="fstrownum">9AF3DF</p>{" "}
                      </td>
                      <td className="avtimgandname">
                        <p className="fstrownumsec ">
                          {" "}
                          <img
                            src="\dashbord\avtpic.svg"
                            alt="img"
                            className="avtartable  pr-2 img-fluid"
                          />
                          Erick Santiago
                        </p>
                      </td>
                      <td>
                        <p className="fstrownumthrd">+92 312 345 6789</p>
                      </td>
                      <td>
                        <p className="progressblue">progress</p>
                      </td>
                      <td>
                        <button className="change-btn">Change</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="table-responsive fsttabeleflex">
                <div className="">
                  <p className="ridername">Rider Name</p>
                </div>
                <div className="assign_rider">
                  <p className="assigned">No rider assigned yet</p>
                  <button
                    className="btn_aassignrider"
                    onClick={() => setassignShow(true)}
                  >
                    Assign Rider
                  </button>
                </div>
              </div>

              <div class="table-responsive odertablelast">
                <table class="table bashbord_table">
                  <thead className="bashbord_table_head">
                    <tr className="tablerow">
                      <th>ID</th>
                      <th>PRODUCT NAME</th>
                      <th>QUANTITY</th>
                      <th>CATEGORY</th>
                      <th>PRICE</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(orderLines).length > 0
                      ? Object.entries(orderLines).map(([key, val], item) => {
                        return (
                          <>
                            <tr className="fstrowtable">
                              <td>
                                <p className="fstrownum">{val?.id}</p>{" "}
                              </td>
                              <td className="avtimgandname">
                                <p className="fstrownumsec ">
                                  {" "}
                                  <img
                                    src={val?.product?.imageUrl}
                                    alt="img"
                                    className="avtartable  pr-2 img-fluid"
                                    style={{ width: '40px' }}
                                  />
                                  {val?.product?.name}
                                </p>
                              </td>
                              <td>
                                <p className="fstrownumthrd">
                                  {val?.quantity}
                                </p>
                              </td>
                              <td>
                                <p className="fstrownumthrd">
                                  {val?.product?.category?.name}
                                </p>
                              </td>
                              <td>
                                <p className="fstrownumfour">
                                  Rs. {val?.price}
                                </p>
                              </td>
                              <td>
                                {" "}
                                <span className="progressselling">
                                  {status}
                                </span>
                              </td>
                            </tr>
                          </>
                        );
                      })
                      : null}

                    <tr className="fstrowtable">
                      <td>
                        <p className="fstrownum"></p>{" "}
                      </td>
                      <td className="avtimgandname">
                        <p className="fstrownumsec ">Total Amount</p>
                      </td>
                      <td>
                        <p className="fstrownumthrd"></p>
                      </td>
                      <td>
                        <p className="fstrownumfourPrimary">Rs.{Math.round(dis ? total + fee - dis : total + fee)}</p>
                      </td>
                      <td>
                        {" "}
                        <span className=""></span>
                      </td>
                    </tr>

                    <tr className="fstrowtable">
                      {/* <td >    <h4 className="lastpager">SHOWING 1-5 OF 5</h4>
                      </td> */}
                      <td className="avtimgandname">
                        {/* <p className="fstrownumsec ">  
                        <img src="\dashbord\avtpic.svg" alt="img" className="avtartable img-fluid" />
                        Erick Santiago</p> */}
                      </td>
                      <td>
                        {/* <p className="fstrownumthrd">@G-11/2 St#21, H#9</p> */}
                      </td>
                      <td>{/* <p className="fstrownumfour">12</p> */}</td>
                      <td>{/* <p className="fstrownumfive">$319.00</p> */}</td>
                      <td>
                        {/* <span className="greenprocesss">Processing</span> */}
                      </td>
                      <td>
                        {/* <div class="dropdown">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Status
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a class="dropdown-item" href="#">
                            Coming Soon...
                          </a>
                          <a class="dropdown-item" href="#">
                            Coming Soon...
                          </a>
                        </div>
                      </div> */}
                      </td>
                      {/* <td>
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item">
                              <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                              </a>
                            </li>
                            <li className="page-item numbersss"><a className="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">
                              <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </td> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <AssignOrder assignshow={assignshow} setassignShow={setassignShow} />
      </div>
      <Offcanvas className="banktransfer-sidebar" show={showbank} onHide={handleCloseBank}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Bank Transfer</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
           {reciept ? ( <div className="reciept-img">
            <img src={reciept} alt="img" className="img-fluid" />
          </div>) : ( <div className="reciept-img">
          <p style={{ color: 'gray', textAlign: 'center', fontSize: '16px' }}>No Image Uploaded</p>
          </div>)}
          <button className="btn-reject " onClick={handlePrint}>Print</button>
        </Offcanvas.Body>
      </Offcanvas>


      <Offcanvas className="banktransfer-sidebar" show={showPress} onHide={handleClosePress}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Prescription</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
           {prescription ? ( <div className="reciept-img">
          
              <img src={prescription[0]} alt="img" className="img-fluid" />
      
          </div>) : ( <div className="reciept-img">
          <p style={{ color: 'gray', textAlign: 'center', fontSize: '16px' }}>No Image Uploaded</p>
          </div>)}
          <button className="btn-reject " onClick={handlePrint}>Print</button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OderDetaits;
