import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";

import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isIfStatement } from "typescript";

const AddProduct = ({
  show,
  setShow,
  opType,
  updateList,
}) => {
  const handleClose = () => {
    setShow(false);

    setChildCategoryList([]);
    setChildCategory(null);
    setSecondChildCategory(null);
    setSecondChildCategoryList([]);
    setName("");
    setDetail("");
    setCategory();
    setCategoryId("");
    setCategoryList([]);
    setUnit("");
    setUnitQuantity("");
    setOutOfStockThreshold("");
    setPrice("");
    setQuantity("");
    setBrandId("");
    setBrand("");
    setUnitId("");
  };

  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState("");
  const [baseImage, setBaseImage] = useState("");
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [category, setCategory] = useState(null);
  const [categoryId, setCategoryId] = useState([]);
  console.log(categoryId,"categoryId");
  const [categoryList, setCategoryList] = useState([]);
  
  const [childCategory, setChildCategory] = useState(null);
  const [childCategoryList, setChildCategoryList] = useState([]);

  const [secondChildCategory, setSecondChildCategory] = useState(null);
  const [secondChildCategoryList, setSecondChildCategoryList] = useState([]);

  const [unit, setUnit] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [unitId, setUnitId] = useState("");
  const [unitQuantity, setUnitQuantity] = useState("");

  const [brand, setBrand] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [brandId, setBrandId] = useState("");
  const [searchQuery, setSearchQuery] = useState();

  const [id, setId] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [tags, setTags] = useState([]);
  const [outOfStockThreshold, setOutOfStockThreshold] = useState("");

  const [productimage, setProductImage] = useState(null);
  const onImageChange = (event) => {
    setProductImage(event.target.files[0]);
  };

  const AddProducts = async (e) => {
    if (!productimage) {
      toast.error("Upload Image");
    } else if (name.length === 0) {
      toast.error("Enter Product Name");
      // console.log(toast.error, 'toast error');
    } else if (unitQuantity.length === 0) {
      toast.error("Enter unit Quantity");
    } else if (quantity.length === 0) {
      toast.error("Enter quantity");
    } else if (price.length === 0) {
      toast.error("Enter price");
    } else if (outOfStockThreshold.length === 0) {
      toast.error("Enter threshold");
    } else {
      const formData = new FormData();
      if (name) {
        formData.append("name", name);
      }
      if (detail) {
        formData.append("description", detail);
      }
      if (unitId) {
        formData.append("unitId", unitId);
      }
      if (unitQuantity) {
        formData.append("unitQuantity", unitQuantity);
      }
      if (quantity) {
        formData.append("quantity", quantity);
      }
      if (outOfStockThreshold) {
        formData.append("outOfStockThreshold", outOfStockThreshold);
      }
      if (price) {
        formData.append("price", price);
      }
      if (categoryId) {
        categoryId.forEach(id => {
          formData.append("productCategoryId", id);
          formData.append("productCategoryId", 1); 
        });
      }
      if (productimage) {
        formData.append("productImage", productimage);
      }
      var config = {
        method: "post",
        url: api_url + "/store/products",
        data: formData,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then(function (response) {
          updateList();
          toast.success(response?.data?.message);
          // console.log("res: ", response?.data);

          // console.log("tokeen: ", response?.data);
          setLoader(false);
          handleClose();
        })
        .catch(function (error) {
          setLoader(false);
          toast.error(error?.response?.data?.message);
        });
    }

  };




  const getCategoryList = async () => {
    // validateUserName();
    // if (!errors) {
    console.log("hittinng: ");

    const config = {
      method: "get",
      url:
        api_url +
        "/store/categories?parentOnly=true&afterElement=0&limit=50&allCategories=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setCategoryList(resData);
        console.log("child cats: ", resData);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const getChildCategory = async (id) => {
    // validateUserName();
    // if (!errors) {

    const config = {
      method: "get",
      url: api_url + "/store/categories/" + id + "/childs",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setChildCategoryList(resData?.childCategories);

        console.log("child cats: ", resData?.childCategories);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const getSecondChildCategory = async (id) => {
    // validateUserName();
    // if (!errors) {

    const config = {
      method: "get",
      url: api_url + "/store/categories/" + id + "/childs",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setSecondChildCategoryList(resData?.childCategories);
        console.log("child cats: ", resData?.childCategories);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };


  //     // validateUserName();
  //     // if (!errors) {
  //     setLoader(true);

  //     const formData = new FormData();
  //     if (name) {
  //       formData.append("name", name);
  //     }
  //     if (detail) {
  //       formData.append("description", detail);
  //     }
  //     if (unitId) {
  //       formData.append("unitId", unitId);
  //     }
  //     if (unitQuantity) {
  //       formData.append("unitQuantity", unitQuantity);
  //     }
  //     if (brandId) {
  //       formData.append("brandId", brandId);
  //     }

  //     if (outOfStockThreshold) {
  //       formData.append("outOfStockThreshold", outOfStockThreshold);
  //     }
  //     if (price) {
  //       formData.append("price", price);
  //     }
  //     if (categoryId) {
  //       formData.append("productCategoryId", categoryId);
  //     }
  //     if (tags) {
  //       formData.append("tags[]", JSON.stringify(tags));
  //     }
  //     if (image) {
  //       formData.append("productImage", image);
  //     }
  //     const config = {
  //       method: "patch",
  //       url: api_url + "/store/products/" + editId,
  //       data: formData,
  //       headers: {
  //         Authorization: "Bearer " + val,
  //       },
  //     };

  //     await axios(config)
  //       .then((res) => {
  //         handleClose();
  //         toast.success(res?.data?.message);
  //         setBaseImage();
  //         setImage();
  //         updateList();
  //         // if (image) {
  //         //   localStorage.setItem("profilePicture", baseImage);
  //         //   setAccountImage(baseImage);
  //         // }
  //         setLoader(false);
  //         // handleClose();
  //       })
  //       .catch((err) => {
  //         if (err?.response?.status == 501) {
  //           history.push("/");
  //         } else if (err?.response?.status == 401) {
  //           history.push("/");
  //         } else {
  //           console.log("error meessage: ", err?.response?.data?.message);
  //           toast.error(err?.response?.data?.message, {
  //             autoClose: 2000,
  //           });
  //         }
  //         setLoader(false);
  //       });
  //     // }
  //   };
  const getUnitList = async () => {
    // validateUserName();
    // if (!errors) {

    const config = {
      method: "get",
      url: api_url + "/units?limit=20&minimal=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setUnitList(resData);
        console.log("units: ", resData);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  //   const getBrandList = async () => {
  //     // validateUserName();
  //     // if (!errors) {

  //     const config = {
  //       method: "get",
  //       url: api_url + "/brands?limit=200&minimal=true",
  //       headers: {
  //         Authorization: "Bearer " + val,
  //       },
  //     };
  //     await axios(config)
  //       .then((res) => {
  //         const resData = res?.data?.data;
  //         setBrandList(resData);
  //         console.log("Brands: ", resData);
  //       })
  //       .catch((err) => {
  //         if (err?.response?.status == 501) {
  //           history.push("/");
  //         } else if (err?.response?.status == 401) {
  //           localStorage.removeItem("accessToken");
  //           console.log("refresh token: ", err?.response);
  //         }
  //         console.log("error meessage: ", err?.response?.data?.message);
  //         toast.error(err?.response?.data?.message, {
  //           position: "top-right",
  //           autoClose: 2000,
  //         });
  //         setLoader(false);
  //       });
  //     // }
  //   };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      {
        getCategoryList();
        getUnitList();
        // getBrandList();
      }
    }
  }, []);
  // const handleShow = () => setShow(true);


  const handleSelect = (itemId) => {
    if (categoryId.includes(itemId)) {
      setCategoryId(categoryId.filter((selectedItemId) => selectedItemId !== itemId));
    } else {
      setCategoryId((prev) => [...prev, itemId]);
    }
  };



  return (
    <>
      <div className="content">
        <section className="sidebar-editproduct">
          <Offcanvas
            show={show}
            onHide={handleClose}
            className="sidebar-editproduct"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Add Product</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="bannerss">
                <h4>Product Image</h4>
                <div className="item-upload">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <input
                        id="upload"
                        type="file"
                        className="d-none"
                        onChange={onImageChange}
                      />
                      <label for="upload" className="">
                        {productimage ? (
                          <img
                            src={URL.createObjectURL(productimage)}
                            alt="img"
                            className="img-fluid upload-img"
                          />
                        ) : (
                          <>
                            <div>
                              <img
                                src="\assestsss\gallery-add.svg"
                                className="img-fluid"
                              />
                              <h4 className="">Drag your image here</h4>
                              <p>
                                (Only *.jpeg and *.png images will be accepted)
                              </p>
                            </div>

                            {/* <h6>Upload user</h6> */}
                          </>
                        )}
                        {/* <img src="\assestsss\Layer.svg" className="img-fluid" /> */}
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="filedinput">
                  {/* <p>ID*</p>
                  <input
                    type="text"
                    placeholder="Product Id"
                    value={id}
                    disabled
                  /> */}
                  <p>Product Name*</p>
                  <input
                    type="text"
                    placeholder="Freshmaker Wipes"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      //   handleChangee();
                    }}
                  />
                  <p>Product Detail</p>
                  <textarea
                    className="productarea"
                    placeholder="Product Detail"
                    value={detail}
                    onChange={(e) => setDetail(e.target.value)}
                  />
                  {/* <p>Category</p> */}

                  <div className="dropdownbtn">
                    <p className="inputpara">Category</p>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Select Categories
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {categoryList.map((item) => (
                          <Dropdown.Item
                            key={item}
                            onClick={() => handleSelect(item?.id)}
                            active={categoryId.includes(item?.id)}
                          >
                            {item?.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>

                      {/* Display selected items */}
                      {categoryId.length > 0 && (
                        <div style={{ marginTop: '10px' }}>
                          <strong>Selected Items:</strong> {categoryId.join(', ')}
                        </div>
                      )}
                    </Dropdown>
                    {/* <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {category != null ? <> {category?.name}</> : null}
                        <img src="\assestsss\arrow 1.svg" alt="arrow" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {categoryList.length > 0
                          ? categoryList.map((item, index) => {
                            return (
                              <Dropdown.Item
                                eventKey={item?.id}
                                onClick={(e) => {
                                  // setRoleId(item?.id);
                                  setCategoryId(item?.id);

                                  setCategory(item);
                                  getChildCategory(item?.id);
                                }}
                              >
                                {item?.name}
                              </Dropdown.Item>
                            );
                          })
                          : null}
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </div>
                  {/* <div class="dropdown">
                    <button
                      class="btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Select Parent Category
                      <img src="\assestsss\arrow1.svg" alt="img" className="" />
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                    </div>
                  </div> */}

                  {childCategoryList?.length > 0 ? (
                    <div className="dropdownbtn">
                      <p className="inputpara"> Child Category</p>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {childCategory != null ? (
                            <> {childCategory?.name}</>
                          ) : null}
                          <img src="\assestsss\arrow 1.svg" alt="arrow" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {childCategoryList.length > 0
                            ? childCategoryList.map((item, index) => {
                              console.log("child items: ", item);
                              return (
                                <Dropdown.Item
                                  eventKey={item?.id}
                                  onClick={(e) => {
                                    // setRoleId(item?.id);
                                    setCategoryId(item?.id);
                                    setChildCategory(item);
                                    getSecondChildCategory(item?.id);
                                  }}
                                >
                                  {item?.name}
                                </Dropdown.Item>
                              );
                            })
                            : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : null}

                  {/* <p>Child Category</p>
                  <div class="dropdown">
                    <button
                      class="btn"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Select Parent Category
                      <img src="\assestsss\arrow1.svg" alt="img" className="" />
                    </button>
                    <div
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                      <a class="dropdown-item" href="#">
                        coming soon.....
                      </a>
                    </div>
                  </div> */}

                  {secondChildCategoryList.length > 0 ? (
                    <div className="dropdownbtn">
                      <p className="inputpara"> Child Category</p>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {secondChildCategory != null ? (
                            <> {secondChildCategory?.name}</>
                          ) : null}
                          <img src="\assestsss\arrow 1.svg" alt="arrow" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {secondChildCategoryList.length > 0
                            ? secondChildCategoryList.map((item, index) => {
                              console.log("child items: ", item);
                              return (
                                <Dropdown.Item
                                  eventKey={item?.id}
                                  onClick={(e) => {
                                    // setRoleId(item?.id);
                                    setCategoryId(item?.id);
                                    setSecondChildCategory(item);
                                  }}
                                >
                                  {item?.name}
                                </Dropdown.Item>
                              );
                            })
                            : null}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : null}
                  {/* <div className="dropdownbtn">
                    <p className="inputpara">Product Brand</p>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {brand != null ? <> {brand?.name}</> : null}
                        <img src="\assestsss\arrow 1.svg" alt="arrow" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="main-parent">
                          <input
                            className="maininput"
                            placeholder="Brand Name"
                            type="text"
                            value={searchQuery}
                            onChange={(e) => handleSearchQuery(e.target.value)}
                          />
                          <img
                            src="\assestsss\search-normal.svg"
                            className="img-fluid"
                          />
                        </div>
                        {brandList.length > 0
                          ? brandList.map((item, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={item?.id}
                                  onClick={(e) => {
                                    // setRoleId(item?.id);
                                    setBrandId(item?.id);

                                    setBrand(item);
                                  }}
                                >
                                  {item?.name}
                                </Dropdown.Item>
                              );
                            })
                          : null}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                  <div className="dropdownbtn">
                    <p className="inputpara">Unit (kg, pc, lb, ml, g...etc)</p>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {unit != null ? <> {unit?.name}</> : null}
                        <img src="\assestsss\arrow 1.svg" alt="arrow" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {unitList.length > 0
                          ? unitList.map((item, index) => {
                            return (
                              <Dropdown.Item
                                eventKey={item?.id}
                                onClick={(e) => {
                                  // setRoleId(item?.id);
                                  setUnitId(item?.id);

                                  setUnit(item);
                                }}
                              >
                                {item?.name}
                              </Dropdown.Item>
                            );
                          })
                          : null}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <p>Unit Quantity</p>
                  <input
                    type="text"
                    placeholder="Unit Quantity*"
                    value={unitQuantity}
                    onChange={(e) => setUnitQuantity(e.target.value)}
                  />
                  <p>Product Quantity</p>
                  <input
                    type="text"
                    placeholder="Product Name*"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <p>Product Price</p>
                  <input
                    type="text"
                    placeholder="Product Name*"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                  {/* <p>Sale Price</p>
                  <input
                    type="text"
                    placeholder="Product Name*"
                    value={salePrice}
                    onChange={(e) => setSalePrice(e.target.value)}
                  /> */}
                  <p>Out of Stock Threshold</p>
                  <input
                    type="text"
                    placeholder="Out of Stock Threshold*"
                    value={outOfStockThreshold}
                    onChange={(e) => setOutOfStockThreshold(e.target.value)}
                  />
                  {/* <p className="">Product Tag (Write then press enter)</p> */}
                  {/* <div className="producttag"> */}
                  {/* <div className="tags-input-container">
                      {tags
                        ? tags?.map((tag, index) => (
                            <div className="tag-item" key={index}>
                              <div className="babycare ml-2">
                                <p className="baby">
                                  <span className="text">{tag}</span>
                                  <span
                                    className="close"
                                    onClick={() => removeTag(index)}
                                  >
                                    <img
                                      src="\assestsss\Group119.svg"
                                      className="img-fluid"
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                          ))
                        : null}
                      <input
                        onKeyDown={handleKeyDown}
                        type="text"
                        className="tags-input"
                        placeholder="Enter Tag Name"
                      />
                    </div> */}

                  {/* <div className="babycare ml-2">
                      <p className="baby">
                        Baby Accessories{" "}
                        <img
                          src="\assestsss\Group119.svg"
                          className="img-fluid"
                        />
                      </p>
                    </div> */}
                  {/* </div> */}
                </div>
                <div className="buttonss">
                  <button className="" onClick={handleClose}>
                    Cancel
                  </button>
                  <button className="ml-2" onClick={() =>  AddProducts() }>
                    Add Product{" "}
                  </button>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};
export default AddProduct;
