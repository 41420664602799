import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./ourstaff.scss";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Offcanvas from "react-bootstrap/Offcanvas";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UpdateStaff = ({
  show,
  setShow,
  setOpType,
  opType,
  editId,
  updateList,
}) => {
  const handleClose = () => {
    setShow(false);
    setOpType("");
  };
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url3;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState("");
  const [baseImage, setBaseImage] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [roleId, setRoleId] = useState("");
  //   const [roleName, setRoleName] = useState("");

  const [staffRole, setStaffRole] = useState("");
  const [rolesData, setRolesData] = useState([]);

  // const handleShow = () => setShow(true);

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };
  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const addStaff = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (email) {
      formData.append("email", email);
    }
    if (joiningDate) {
      formData.append("joiningDate", joiningDate);
    }
    if (phoneNumber) {
      formData.append("phoneNumber", phoneNumber);
    }
    if (roleId) {
      formData.append("roleId", roleId);
    }
    if (image) {
      formData.append("staffImage", image);
    }
    const config = {
      method: "post",
      url: api_url + "/staffs",
      data: formData,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        handleClose();
        updateList();
        toast.success(res?.data?.message);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        setLoader(false);
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };

  const editStaff = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (email) {
      formData.append("email", email);
    }
    if (joiningDate) {
      formData.append("joiningDate", joiningDate);
    }
    if (phoneNumber) {
      formData.append("phoneNumber", phoneNumber);
    }
    if (roleId) {
      formData.append("roleId", roleId);
    }
    if (image) {
      formData.append("staffImage", image);
    }
    const config = {
      method: "patch",
      url: api_url + "/staffs/" + editId,
      data: formData,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        handleClose();
        toast.success(res?.data?.message);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        setLoader(false);
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };

  const getStaffDetail = async () => {
    setLoader(true);

    const config = {
      method: "get",
      url: api_url + "/staffs/" + editId,

      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const responseData = res?.data?.data;
        setName(responseData?.name);
        setEmail(responseData?.email);
        setBaseImage(responseData?.imageUrl);
        setJoiningDate(responseData?.joiningDate);
        setPhoneNumber(responseData?.phoneNumber);
        setRoleId(responseData?.roleId);

        console.log("data: ", res?.data?.data);
        // setCategoryDetail(res?.data?.data);

        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  const getRoles = async () => {
    // validateUserName();
    // if (!errors) {
    setRolesData([]);
    const config = {
      method: "get",
      url: api_url + "/roles?minimal=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setRolesData(resData);
        console.log("rroles: ", resData);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  useEffect(() => {
    console.log("type: ", opType);
    if (!val) {
      history.push("/");
    } else {
      getRoles();
      if (opType == "edit") {
        getStaffDetail();
      }
    }
  }, [opType]);

  return (
    <>
      <div className="content">
        <section className="updatestaff">
          <Offcanvas show={show} onHide={handleClose} className="updatestaff">
            <Offcanvas.Header closeButton>
              {opType == "add" ? (
                <Offcanvas.Title>Add Staff</Offcanvas.Title>
              ) : opType == "edit" ? (
                <Offcanvas.Title>Update Staff</Offcanvas.Title>
              ) : (
                <Offcanvas.Title>Staff</Offcanvas.Title>
              )}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="main-form">
                <div className="bannerss">
                  <h4>
                    Category Image<span>*</span>
                  </h4>
                  <div className="item-upload">
                    <ul className="list-inline text-center">
                      <li className="list-inline-item ">
                        <input
                          id="upload"
                          type="file"
                          className="d-none"
                          onChange={uploadImages}
                        />
                        <label for="upload" className="">
                          {baseImage ? (
                            <img
                              src={baseImage}
                              alt="img"
                              className="img-fluid upload-img"
                            />
                          ) : (
                            <>
                              <div>
                                <img
                                  src="\assestsss\gallery-add.svg"
                                  className="img-fluid"
                                />
                                <h4 className="">Drag your image here</h4>
                                <p>
                                  (Only *.jpeg and *.png images will be
                                  accepted)
                                </p>
                              </div>

                              {/* <h6>Upload user</h6> */}
                            </>
                          )}

                          {/* <img
                            src="\assestsss\gallery-add.svg"
                            className="img-fluid"
                          /> */}
                        </label>
                        {/* <h4 className="">Drag your image here</h4>
                        <p>(Only *.jpeg and *.png images will be accepted)</p> */}
                        {/* <img src="\assestsss\employe.svg" alt="img" className="employeimg"/> */}
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <p className="head">
                    Name<span>*</span>
                  </p>
                  <input
                    type="name"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <p className="head">Email</p>
                  <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <p className="head">
                    Contact Number<span>*</span>
                  </p>
                  <input
                    type="number"
                    placeholder="Contact Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div>
                  <p className="head">Joining Date</p>
                  <input
                    type="date"
                    placeholder="DD/MM/YY"
                    value={joiningDate}
                    onChange={(e) => setJoiningDate(e.target.value)}
                  />
                </div>
                <div>
                  <div className="dropdownbtn">
                    <p className="head">
                      Staff Role<span>*</span>
                    </p>

                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {staffRole}
                        <img src="\assestsss\arrow 1.svg" alt="arrow" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {rolesData?.length > 0
                          ? rolesData?.map((item) => {
                              return (
                                <>
                                  <Dropdown.Item
                                    eventKey={item?.id}
                                    onClick={(e) => {
                                      setRoleId(item?.id);
                                      setStaffRole(item?.name);
                                    }}
                                  >
                                    {item?.name}
                                  </Dropdown.Item>
                                </>
                              );
                            })
                          : null}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <p className="head">Access</p>
                <div className="checked-main">
                  <div class="form-group">
                    <input type="checkbox" id="html1" />
                    <label for="html1">All</label>
                  </div>
                  <div class="form-group ml-2">
                    <input type="checkbox" id="html2" />
                    <label for="html2">Dashboard</label>
                  </div>
                  <div class="form-group ml-2">
                    <input type="checkbox" id="html3" />
                    <label for="html3">Products</label>
                  </div>
                  <div class="form-group ml-2">
                    <input type="checkbox" id="html4" />
                    <label for="html4">Category</label>
                  </div>
                  <div class="form-group">
                    <input type="checkbox" id="html5" />
                    <label for="html5">Customers</label>
                  </div>
                  <div class="form-group ml-2">
                    <input type="checkbox" id="html6" />
                    <label for="html6">Our Staff</label>
                  </div>
                  <div class="form-group ml-2">
                    <input type="checkbox" id="html7" />
                    <label for="html7">Riders</label>
                  </div>
                  <div class="form-group ml-2">
                    <input type="checkbox" id="html8" />
                    <label for="html8">Support</label>
                  </div>
                </div>
                <div className="box-buttons">
                  <button>Cancel</button>
                  {/* <button className="ml-2">Add Category</button> */}
                  {opType == "add" ? (
                    <button className="ml-2" onClick={addStaff}>
                      Add Staff
                    </button>
                  ) : opType == "edit" ? (
                    <button className="ml-2" onClick={editStaff}>
                      Save Staff
                    </button>
                  ) : null}
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};
export default UpdateStaff;
