import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import Table from "react-bootstrap/Table";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { Search } from "@material-ui/icons";

const PromoSidebar = ({ assignshow, setassignShow }) => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [categorySearchQuery, setCategorySearchQuery] = useState();
  const [productSearchQuery, setProductSearchQuery] = useState();

  const [categorySearchResults, setCategorySearchResults] = useState([]);
  const [productSearchResults, setProductSearchResults] = useState([]);

  const [promocode, setPromocode] = useState("");
  const [discount, setDiscount] = useState("");
  const [startdate, setStartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const [productname, setProductname] = useState("");
  const [product, setProduct] = useState([]);
  const [category, setCategory] = useState([]);
  const [lengthError, setLengthError] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [productsList, setProductsList] = useState([]);

  //scroll

  const [data, setData] = useState([]);
  const [categoryScrollState, setCategoryScrollState] = useState(false);
  const [categoryPage, setCategoryPage] = useState(1);
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);

  const [productScrollState, setProductScrollState] = useState(false);
  const [productPage, setProductPage] = useState(1);
  const [isProductLoading, setIsProductLoading] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [selectedCategoriesObject, setSelectedCategoriesObject] = useState([]);
  const [selectedProductsObject, setSelectedProductsObject] = useState([]);

  const handleCategoryScroll = () => {
    console.log("hitting handle Scroll ");

    const element = document.querySelector(".scrollable");
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight - 5 &&
      !isCategoryLoading
    ) {
      setIsCategoryLoading(true);
    }
  };

  const handleProductScroll = () => {
    console.log("hitting handle Scroll ");

    const element = document.querySelector(".scrollableProd");
    if (
      element.scrollTop + element.clientHeight >= element.scrollHeight - 5 &&
      !isCategoryLoading
    ) {
      setIsProductLoading(true);
    }
  };

  useEffect(() => {
    if (isCategoryLoading) {
      fetchCategoriesData();
    }
    const scrollableElement = document.querySelector(".scrollable");

    console.log("scrollable Element: ", scrollableElement);

    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleCategoryScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleCategoryScroll);
      }
    };
  }, [isCategoryLoading, categoryScrollState]);

  useEffect(() => {
    if (isProductLoading) {
      fetchProductData();
    }
    const scrollableElement = document.querySelector(".scrollableProd");

    console.log("scrollable Element: ", scrollableElement);

    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleProductScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleProductScroll);
      }
    };
  }, [isProductLoading, productScrollState]);

  const fetchCategoriesData = async () => {
    console.log("hitting! ");
    try {
      setLoader(true);
      const config = {
        method: "get",
        url: `${api_url}/store/categories?limit=5&offset=${categoryPage}&minimal=true`,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          setCategoriesList((prevData) => [...prevData, ...resData]);
          setCategoryPage((prevPage) => prevPage + 1);
          console.log("searrch result: ", resData);
          // setSearchResults(resData?.categories);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else if (err?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            // FetchRefreshToken();
            console.log("refresh token: ", err?.response);
          }
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
          setLoader(false);
        });

      // setData(prevData => [...prevData, ...newData]);
      // setPage(prevPage => prevPage + 1);
      setIsCategoryLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchProductData = async () => {
    console.log("hitting! ");
    try {
      setLoader(true);
      const config = {
        method: "get",
        url: `${api_url}/store/products?limit=5&offset=${productPage}&minimal=true`,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          setProductsList((prevData) => [...prevData, ...resData]);
          setProductPage((prevPage) => prevPage + 1);
          console.log("searrch result: ", resData);
          // setSearchResults(resData?.categories);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else if (err?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            // FetchRefreshToken();
            console.log("refresh token: ", err?.response);
          }
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
          setLoader(false);
        });

      // setData(prevData => [...prevData, ...newData]);
      // setPage(prevPage => prevPage + 1);
      setIsProductLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setassignShow(false);
    setCategoryScrollState(false);
    setProductScrollState(false);
  };

  const addPromo = async () => {
    setLoader(true);
    if (category.length > 0 || product.length > 0) {
      const config = {
        method: "post",
        url: api_url + "/promos",
        data: {
          discount: discount,
          startTime: startdate,
          endTime: enddate,
          ...(category.length > 0 && { productCategoryIds: category }),
          ...(product.length > 0 && { productIds: product }),
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          handleClose();
          toast.success(res?.data?.message);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            console.log("error meessage: ", err?.response?.data?.message);
            toast.error(err?.response?.data?.message, {
              position: "bottom-left",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    } else {
      setLengthError("Please select Category or Product!");
    }
  };

  const handleCategoriesList = async () => {
    // setSearchQuery(e);
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/store/categories?limit=5&offset=1&minimal=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setCategoriesList(resData);
        console.log("searrch result: ", resData);
        // setSearchResults(resData?.categories);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });

    // }
  };

  const handleProductsList = async () => {
    // setSearchQuery(e);
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/store/products?limit=5&offset=1&minimal=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setProductsList(resData);
        console.log("searrch result: ", resData);
        // setSearchResults(resData?.categories);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });

    // }
  };

  const handleCategorySearchQuery = async (e) => {
    setCategorySearchQuery(e);
    setLoader(true);
    if (e) {
      const config = {
        method: "get",
        url: api_url + "/store/categories?minimal=true&search=name=" + e,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          console.log("searrch result: ", resData);
          setCategoriesList(resData);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else if (err?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            // FetchRefreshToken();
            console.log("refresh token: ", err?.response);
          }
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
          setLoader(false);
        });
    } else {
      setLoader(false);
      setCategoriesList([]);
    }
    // }
  };

  const handleProductSearchQuery = async (e) => {
    setProductSearchQuery(e);
    setLoader(true);
    if (e) {
      const config = {
        method: "get",
        url: api_url + "/store/products?minimal=true&search=name=" + e,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          console.log("searrch result: ", resData);
          setProductsList(resData);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else if (err?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            // FetchRefreshToken();
            console.log("refresh token: ", err?.response);
          }
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
          setLoader(false);
        });
    } else {
      setLoader(false);
      setProductsList([]);
    }

    // }
  };

  useEffect(() => {
    if (assignshow) {
      handleCategoriesList();
      handleProductsList();
    }
  }, [assignshow]);

  const handleSelectedCategoryId = (e, i) => {
    let tempIds = selectedCategories;
    let newTempIds = [];
    console.log("item id: ", i);
    if (e.target.checked) {
      console.log("checked: ", i);
      tempIds.push(i?.id);
      setSelectedCategories(tempIds);
    } else if (!e.target.checked) {
      for (let ind = 0; ind < selectedCategories.length; ind++) {
        if (tempIds[ind] != i?.id) {
          newTempIds.push(tempIds[ind]);
        }
      }
      setSelectedCategories(newTempIds);
    }
    console.log("list: ", tempIds, newTempIds);
  };
  const handleSelectedProductId = (e, i) => {
    let tempIds = selectedProducts;
    let newTempIds = [];
    console.log("item id: ", i);
    if (e.target.checked) {
      console.log("checked: ", i);
      tempIds.push(i?.id);
      setSelectedProducts(tempIds);
    } else if (!e.target.checked) {
      for (let ind = 0; ind < selectedProducts.length; ind++) {
        if (tempIds[ind] != i?.id) {
          newTempIds.push(tempIds[ind]);
        }
      }
      setSelectedProducts(newTempIds);
    }
    console.log("list: ", tempIds, newTempIds);
  };

  return (
    <>
      <div className="content">
        <section className="sidebar-promoproduct">
          <Offcanvas
            show={assignshow}
            onHide={handleClose}
            className="sidebar-promoproduct"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Create Promo</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="canvas-body">
              <div className="filedinput">
                <p>Promo Code</p>
                <input
                  value={promocode}
                  onChange={(e) => setPromocode(e.target.value)}
                  type="text"
                  placeholder="Promo Code"
                />
                <p>Discount %*</p>
                <input
                  type="text"
                  placeholder="Discount %"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                />
                <p>Start Time*</p>
                <div className="catagoryfield">
                  <input
                    type="text"
                    placeholder="DD/MM/YY"
                    value={startdate}
                    onChange={(e) => setStartdate(e.target.value)}
                  />
                </div>
                <p>End Time*</p>
                <div className="catagoryfield">
                  <input
                    type="text"
                    placeholder="DD/MM/YY"
                    value={enddate}
                    onChange={(e) => setEnddate(e.target.value)}
                  />
                </div>

                <div class="dropdown">
                  <div className="dropdownbtn">
                    <p className="inputpara">
                      Category<span className="red">*</span>
                    </p>
                    <Dropdown onClick={() => setCategoryScrollState(true)}>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {/* {category.length > 0 ? (
                          <>{category.length} items selected</>
                        ) : category.length === 0 ? (
                          <>Select by searching</>
                        ) : null} */}
                        <img src="\assestsss\arrow 1.svg" alt="arrow" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="main-parent">
                          <input
                            className="maininput"
                            placeholder={productname}
                            type="text"
                            value={categorySearchQuery}
                            onChange={(e) =>
                              handleCategorySearchQuery(e.target.value)
                            }
                          />
                          <img
                            src="\assestsss\search-normal.svg"
                            className="img-fluid"
                          />
                        </div>
                        <div className="scrollable list-body">
                          {categoriesList.length > 0
                            ? categoriesList.map((item, index) => {
                                // console.log("keys: ", key, index);
                                // Object.entries(val).map((item) => {
                                //   console.log("key val array: ", item);
                                // });
                                return (
                                  <>
                                    <div class="form-group ">
                                      <input
                                        type="checkbox"
                                        id={index + item}
                                        onChange={(e) =>
                                          handleSelectedCategoryId(e, item)
                                        }
                                      />
                                      <label for={index + item}>
                                        <span>
                                          <img
                                            src={item?.imageUrl}
                                            alt="img"
                                            className="koi-bhi mr-2"
                                          />
                                        </span>
                                        {item?.name}
                                      </label>
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </div>

                        <div className="AddSelected">
                          <button>Add Selected</button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <div className="list-body-category">
                      <>
                        <Table className="table-category">
                          <thead className="thead-categories">
                            <tr>
                              <th className="th-id" >ID </th>
                              <th>PRODUCT NAME</th>
                            </tr>
                          </thead>

                          <tbody className="tbody-categories" >
                            <tr>
                              <td className="item-id">{item?.id}</td>
                              <td className="item-name"> <label className="mb-0" for={index + item}>
                                <span>
                                  <img
                                    src={item?.imageUrl}
                                    alt="img"
                                    className="image-categories mr-2"
                                  />
                                </span>
                                {item?.name}
                              </label></td>
                              <td className="delete-img">
                                <a>
                                  <img
                                    src="\assestsss\trash.svg"
                                    className="img-fluid "
                                  />
                                </a>
                              </td>
                            </tr>

                          </tbody>


                        </Table>

                      </>

                    </div> */}
                  </div>
                </div>

                <div className="catagoryfield">
                  <div class="dropdown">
                    <div className="dropdownbtn">
                      <p className="inputpara">
                        Product Name<span className="red">*</span>
                      </p>
                      <Dropdown onClick={() => setProductScrollState(true)}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {/* {category.length > 0 ? (
                            <>{category.length} items selected</>
                          ) : category.length == 0 ? (
                            <>Select by searching</>
                          ) : null} */}
                          <img src="\assestsss\arrow 1.svg" alt="arrow" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="main-parent">
                            <input
                              className="maininput"
                              placeholder="Product Name"
                              type="text"
                              value={productSearchQuery}
                              onChange={(e) =>
                                handleProductSearchQuery(e.target.value)
                              }
                            />
                            <img
                              src="\assestsss\search-normal.svg"
                              className="img-fluid"
                            />
                          </div>

                          <div className="scrollableProd list-body-prod">
                            {productsList.length > 0
                              ? productsList.map((item, index) => {
                                  // console.log("keys: ", key, index);
                                  // Object.entries(val).map((item) => {
                                  //   console.log("key val array: ", item);
                                  // });
                                  return (
                                    <>
                                      <div class="form-group ytxfxxtyxhtctcty ">
                                        <input
                                          type="checkbox"
                                          id={index + item + index * 2}
                                          onChange={(e) =>
                                            handleSelectedProductId(e, item)
                                          }
                                        />
                                        <label for={index + item + index * 2}>
                                          <span>
                                            <img
                                              src={item?.imageUrl}
                                              alt="img"
                                              className="koi-bhi mr-2"
                                            />
                                          </span>
                                          {item?.name}
                                        </label>
                                      </div>
                                    </>
                                  );
                                })
                              : null}
                          </div>
                          <div className="AddSelected">
                            <button>Add Selected</button>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* <div className="list-body-category">
                        <>
                          <Table className="table-category">
                            <thead className="thead-categories">
                              <tr>
                                <th className="th-id" >ID </th>
                                <th>PRODUCT NAME</th>
                              </tr>
                            </thead>

                            <tbody className="tbody-categories" key={index}>
                              <tr>
                                <td className="item-id">{item?.id}</td>
                                <td className="item-name"> <label className="mb-0" for={index + item}>
                                  <span>
                                    <img
                                      src={item?.imageUrl}
                                      alt="img"
                                      className="image-categories mr-2"
                                    />
                                  </span>
                                  {item?.name}
                                </label></td>
                                <td className="delete-img">
                                  <a>
                                    <img
                                      src="\assestsss\trash.svg"
                                      className="img-fluid "
                                    />
                                  </a>
                                </td>
                              </tr>

                            </tbody>


                          </Table>

                        </>

                      </div> */}
                    </div>
                  </div>
                  {/* <input
                    type="text"
                    placeholder="Product Name*"
                    value={product}
                    onChange={(e) => {
                      setProduct(e.target.value);
                      setLengthError("");
                    }}
                  /> */}
                </div>
              </div>
              <p className="text-danger pt-4">{lengthError}</p>
              <div className="buttonss">
                <button className="" onClick={handleClose}>
                  Cancel
                </button>
                <button className="ml-2" onClick={addPromo}>
                  Create Promo{" "}
                </button>
                ``{" "}
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};

export default PromoSidebar;
