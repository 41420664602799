import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../Promo/promo.scss";
import "./brandmanagement.scss";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EditAttributesRounded } from "@material-ui/icons";

const AddBrand = ({
  assignshow,
  setassignShow,
  updateList,
  opType,
  editId,
  setOpType,
}) => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState("");
  const [baseImage, setBaseImage] = useState("");
  const [brandName, setBrandName] = useState("");
  const [error, setError] = useState("");
  let errorBool = false;
  const handleClose = () => {
    setassignShow(false);
    setBrandName("");
    setBaseImage();
    setImage();
    setOpType("");
    setError("");
  };

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };
  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const addBrand = async () => {
    setError("");
    errorBool = false;
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const formData = new FormData();
    if (brandName) {
      formData.append("name", brandName);
    } else {
      setError("Please Enter Brand's Name!");
      errorBool = true;
    }

    if (image) {
      formData.append("brandImage", image);
    } else {
      setError("Please Upload Brand's Image!");
      errorBool = true;
    }
    const config = {
      method: "post",
      url: api_url + "/brands",
      data: formData,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    if (!errorBool) {
      await axios(config)
        .then((res) => {
          handleClose();
          updateList();
          toast.success(res?.data?.message);
          setBaseImage();
          setImage();
          // if (image) {
          //   localStorage.setItem("profilePicture", baseImage);
          //   setAccountImage(baseImage);
          // }
          setLoader(false);
          // handleClose();
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            console.log("error meessage: ", err?.response?.data?.message);

            toast.error(err?.response?.data?.message, {
              position: "bottom-left",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    }

    // }
  };

  const editBrand = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const formData = new FormData();
    if (brandName) {
      formData.append("name", brandName);
    }

    if (image) {
      formData.append("brandImage", image);
    }
    const config = {
      method: "patch",
      url: api_url + "/brands/" + editId,
      data: formData,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        updateList();
        handleClose();
        toast.success(res?.data?.message);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        setLoader(false);
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };

  const getBrandDetail = async () => {
    setLoader(true);

    const config = {
      method: "get",
      url: api_url + "/brands/" + editId,

      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const responseData = res?.data?.data;
        setBrandName(responseData?.name);

        setBaseImage(responseData?.imageUrl);

        console.log("data: ", res?.data?.data);
        // setCategoryDetail(res?.data?.data);

        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    console.log("type: ", opType);
    if (!val) {
      history.push("/");
    } else {
      if (opType == "edit") {
        getBrandDetail();
      }
    }
  }, [opType]);

  return (
    <>
      <div className="content">
        <section className="sidebar-promoproduct brandmanagement">
          <Offcanvas
            show={assignshow}
            onHide={handleClose}
            className="sidebar-promoproduct"
          >
            <Offcanvas.Header closeButton>
              {opType == "add" ? (
                <Offcanvas.Title>Add Brand</Offcanvas.Title>
              ) : opType == "edit" ? (
                <Offcanvas.Title>Edit Brand</Offcanvas.Title>
              ) : (
                <Offcanvas.Title>Brand</Offcanvas.Title>
              )}
              {/* <Offcanvas.Title>Add Brand</Offcanvas.Title> */}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="filedinput">
                <div className="upload-img">
                  <p>Brand Image*</p>
                  <div className="upload">
                    {/* <div className="before-upload">
                      <label htmlFor="upload">
                        <img
                          src="\assestsss\upload.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </label>
                      <h6>Drag your image here</h6>
                      <span>
                        (Only *.jpeg and *.png images will be accepted)
                      </span>
                    </div> */}
                    {/* <div className="after-upload">
                                        <img src="herefileurl" alt="img" className='img-fluid' />
                                    </div> */}
                    <input
                      type="file"
                      className="d-none"
                      id="upload"
                      onChange={(e) => {
                        uploadImages(e);
                        setError("");
                      }}
                    />
                    <label htmlFor="upload">
                      {baseImage ? (
                        <img
                          src={baseImage}
                          alt="img"
                          className="img-fluid upload-img"
                        />
                      ) : (
                        <div className="before-upload">
                          <img
                            src="\assestsss\gallery-add.svg"
                            className="img-fluid"
                          />
                          <h6>Drag your image here</h6>
                          <span>
                            (Only *.jpeg and *.png images will be accepted)
                          </span>
                        </div>
                      )}
                    </label>
                  </div>
                </div>
                <div className="option-field">
                  <p>Brand Name*</p>
                  <input
                    type="text"
                    placeholder="Name"
                    value={brandName}
                    onChange={(e) => {
                      setBrandName(e.target.value);
                      setError("");
                    }}
                  />
                </div>
              </div>
              <div>
                <p className="text-danger">{error}</p>
              </div>
              <div className="buttonss">
                <button className="">Cancel</button>
                {opType == "add" ? (
                  <button className="ml-2" onClick={addBrand}>
                    Add Brand{" "}
                  </button>
                ) : opType == "edit" ? (
                  <button className="ml-2" onClick={editBrand}>
                    Save Brand
                  </button>
                ) : null}
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};

export default AddBrand;
