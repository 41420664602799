import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./customerdetail.scss";
import ProductList from "./ProductList";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import "./customer.scss";
import moment from "moment";
import { RestaurantRounded } from "@material-ui/icons";
import defaultAvatar from "../../assets/img/default-avatar.png";

const CustomerDetail = (props) => {
  const propsData = props.location.state;
  const id = propsData?.id;
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url2;
  const api_url1 = Environment.base_url;

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [customerOrders, setCustomerOrders] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [customerId, setCustomerId] = useState("");
  const getCustomerDetails = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setCustomerDetails([]);
    const config = {
      method: "get",
      url: api_url + "/users/" + id,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        // console.log("data addrress: ", resData?.addresses[0]?.address);
        setCustomerDetails(resData);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });

    // }
  };

  const getCustomerOrders = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setCustomerOrders([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/users/" +
        id +
        "/orders?limit=" +
        limit +
        "&offset=" +
        offset,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        console.log("orders list: ", resData);
        setCustomerOrders(resData?.userOrders);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const changeOrderStatus = async (orderId, status) => {
    const config = {
      method: "patch",
      url: api_url1 + "/orders/" + orderId + "/status",
      data: { status: status },
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        console.log("order status: ", res);
        toast.success(res?.data?.message);
        getCustomerOrders();
      })
      .catch((error) => {
        if (error?.response?.status == 501) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (error?.response?.status == 401) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          localStorage.removeItem("accessToken");
          history.push("/");
        } else {
          toast.error(error?.response?.data?.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getCustomerDetails();
    }
  }, []);
  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getCustomerOrders();
    }
  }, [offset]);
  const [assignshow, setassignShow] = useState(false);
  return (
    <>
      <div className="content p-0">
        <section className="customerdetail">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12">
                <div className="customerdetailheading">
                  <h3>Customer Details</h3>
                  <div className="backbutton">
                    <button>Block Customer</button>
                  </div>
                </div>
                <div className="maincardcustomer">
                  <div className="imgcard">
                    <img
                      src={
                        customerDetails?.imageUrl
                          ? customerDetails?.imageUrl
                          : defaultAvatar
                      }
                      className="img-fluid"
                      alt="thumbnail"
                    />
                  </div>
                  <div className="lefttextdetail">
                    <p>Customer Name</p>
                    <h6>{customerDetails?.name}</h6>
                    <p>Customer ID</p>
                    <h6>{customerDetails?.id}</h6>
                  </div>
                  <div className="centertextdetail">
                    <p>Email Address</p>
                    <h6>{customerDetails?.email}</h6>
                    <p>Phone No.</p>
                    <h6>{customerDetails?.contactNo}</h6>
                  </div>
                  <div className="righttextdetail">
                    <p>Joining Date</p>
                    <h6>
                      {moment(customerDetails?.createdAt).format("DD-MMM-YYYY")}
                    </h6>
                    <p>Address</p>

                    {Object.keys(customerDetails).length > 0 ? (
                      <h6>{customerDetails?.addresses[0]?.address}</h6>
                    ) : null}
                  </div>
                </div>
                <div className="table-customer">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Date / Time</th>
                        <th scope="col">shipping address</th>
                        <th scope="col">method</th>
                        <th scope="col">amount</th>
                        <th scope="col">status</th>
                        <th scope="col">status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerOrders.length > 0
                        ? customerOrders?.map((item) => {
                            return (
                              <>
                                <tr>
                                  <td className="idaff">{item?.id}</td>
                                  <td>
                                    {moment(item?.createdAt).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </td>
                                  <td>{item?.address?.address}</td>
                                  <td>{item?.paymentType?.value}</td>
                                  <td>{item?.total}</td>
                                  <td>
                                    <span className="button-pro">
                                      {item?.status == "pending" ? (
                                        <span className="greenprocesssyellow">
                                          {item?.status}
                                        </span>
                                      ) : item?.status == "delivered" ? (
                                        <span className="greenprocesssgreen">
                                          {item?.status}
                                        </span>
                                      ) : item?.status == "processing" ? (
                                        <span className="greenprocesss">
                                          {item?.status}
                                        </span>
                                      ) : item?.status == "cancelled" ? (
                                        <span className="greenprocesssred">
                                          {item?.status}
                                        </span>
                                      ) : item?.status == "refund" ? (
                                        <span className="greenprocesssred">
                                          {item?.status}
                                        </span>
                                      ) : null}
                                    </span>
                                  </td>
                                  <td>
                                    <div className="buttonproduct">
                                      <div className="dropdown">
                                        <div className="">
                                          <div className="d-flex justify-content-center align-content-center px-2">
                                            <select
                                              // value={e.target.name}
                                              className="selector-size selector-options-text btndrop"
                                              onChange={(e) => {
                                                changeOrderStatus(
                                                  item?.id,
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              <option selected hidden>
                                                Status
                                              </option>
                                              <option
                                                name="pending"
                                                value="pending"
                                                className="selector-options-text dropdown-item"
                                              >
                                                Pending
                                              </option>
                                              <option
                                                name="processing"
                                                value="processing"
                                                className="selector-options-text dropdown-item"
                                              >
                                                Processing
                                              </option>
                                              <option
                                                name="delivered"
                                                value="delivered"
                                                className="selector-options-text dropdown-item"
                                              >
                                                Delivered
                                              </option>
                                              <option
                                                name="refund"
                                                value="refund"
                                                className="selector-options-text dropdown-item"
                                              >
                                                Refund
                                              </option>
                                              <option
                                                name="cancelled"
                                                value="cancelled"
                                                className="selector-options-text dropdown-item"
                                              >
                                                Cancelled
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="productbutton">
                                      <button
                                        onClick={() => {
                                          setassignShow(true);
                                          setCustomerId(item?.id);
                                        }}
                                      >
                                        Product List
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    {count > 0 && count <= limit ? (
                      <>
                        <p>
                          SHOWING 1-{count} OF {count}
                        </p>
                      </>
                    ) : count > limit ? (
                      <>
                        <p>
                          SHOWING{" "}
                          {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                          {limit * offset > count ? count : limit * offset} OF{" "}
                          {count}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>SHOWING 0 OF 0</p>
                      </>
                    )}
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li class="page-item">
                          {offset > 1 ? (
                            <>
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Previous"
                                onClick={() => setOffset((prev) => prev - 1)}
                              >
                                <span aria-hidden="true">
                                  <i class="fas fa-angle-left"></i>
                                </span>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                        {pageCount.length > 1 ? (
                          <>
                            {pageCount.map((item) => {
                              return (
                                <>
                                  <li className="page-item">
                                    <a
                                      className={
                                        item + 1 == offset
                                          ? "page-link active"
                                          : "page-link"
                                      }
                                      href="#"
                                      onClick={() => setOffset(item + 1)}
                                    >
                                      {item + 1}
                                    </a>
                                  </li>
                                </>
                              );
                            })}
                          </>
                        ) : null}
                        <li class="page-item">
                          {offset < page ? (
                            <>
                              <a
                                class="page-link"
                                href="#"
                                aria-label="Next"
                                onClick={() => setOffset((prev) => prev + 1)}
                              >
                                <span aria-hidden="true">
                                  <i class="fas fa-angle-right"></i>
                                </span>
                              </a>
                            </>
                          ) : (
                            <></>
                          )}
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductList
          assignshow={assignshow}
          setassignShow={setassignShow}
          id={id}
        />
      </div>
    </>
  );
};

export default CustomerDetail;
