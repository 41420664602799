import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Delete = ({ setShow1, show1, id, updateList, url }) => {
  const val = localStorage.getItem("accessToken");
  // const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const deleteRecord = async () => {
    setLoader(true);
    const config = {
      method: "delete",
      url: `${url}${id}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        setLoader(false);
        setShow1(false);
        updateList();
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  return (
    <>
      <Modal
        className="modal-password"
        show={show1}
        onHide={() => setShow1(false)}
        centered
      >
        <Modal.Body>
          <div className="del-img">
            <img src="\assestsss\Frame306.svg" className="img-fluid" />
          </div>
          <div className="del-heading">
            <h5>Are You Sure! Want to Delete This Record?</h5>
            <p>
              Do you really want to delete these records? You can't view this in
              your list anymore if you delete!
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="del-footer">
            <button onClick={() => setShow1(false)}>No, Keep it</button>
            <button className="ml-2" onClick={deleteRecord}>
              Yes, Delete It
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Delete;
