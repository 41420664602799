import React from "react";
import { useState, useEffect } from "react";
import AddBanner from "./AddBanner";
import axios from "axios";
import Environment from "utils/environment";
import { useHistory } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const BannerManagement = () => {
  const val = localStorage.getItem("accessToken");
  const [assignshowbanner, setassignShowbanner] = useState(false);
  const history = useHistory();
  const api_url = Environment.base_url2;
  const [post, setPost] = useState([]);
  const [publishedFilter, setPublishedFilter] = useState(false);
  const [unpublishedFilter, setUnpublishedFilter] = useState(false);
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleSearchQuery = (e) => {};

  const handlePublishedFilter = (e) => {
    if (e.target.checked) {
      setPublishedFilter(true);
      setUnpublishedFilter(false);
    } else if (!e.target.checked) {
      setPublishedFilter(false);
    }
  };

  const handleUnpublishedFilter = (e) => {
    if (e.target.checked) {
      setUnpublishedFilter(true);
      setPublishedFilter(false);
    } else if (!e.target.checked) {
      setUnpublishedFilter(false);
    }
  };

  const getBanner = async () => {
    setPost([]);
    console.log('innnnnnnnnnnnnnnnnnnnnnn');
    const config = {
      method: "get",
          url: api_url + "/banners?limit=" + limit + "&offset=" + offset +
            "&" +
            `${
              !publishedFilter && !unpublishedFilter
                ? ""
                : "filter=isPublish=eq:" + publishedFilter
            }`,
          
            headers: {
              Authorization: "Bearer " + val,
            },
          
      }
      await axios (config)
      .then((res) => {

        const resData = res?.data?.data;
        setPost(resData?.banners);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getBanner();
    }
  }, [publishedFilter, unpublishedFilter,offset]);

  const publishBanner = async (id) => {
    var config = {
      method: "patch",
      url: `${Environment.base_url2}/banners/${id}/is-publish`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then(function (response) {})
      .catch(function (error) {});
  };

  return (
    <>
      <div className="content">
        <section className="promo brandmanagement">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 p-0">
                <div className="customerdetailheading ytxyrtdrdyrdyrdytstsy">
                  <h3>Banner Management</h3>
                  <div className="backbutton">
                    <div className="btnsdrop">
                      <div class="dropdown">
                        <button
                          class=""
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="\assestsss\filter_111.svg"
                            alt="img"
                            className="img-fluid mr-2"
                          />
                          Filter
                        </button>

                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="published"
                              checked={publishedFilter}
                              onChange={(e) => handlePublishedFilter(e)}
                            />
                            <label>Published</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="unpublished"
                              checked={unpublishedFilter}
                              onChange={(e) => handleUnpublishedFilter(e)}
                            />
                            <label>Unpublished</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      className="createbtn"
                      onClick={() => {
                        setassignShowbanner(true);
                      }}
                    >
                      <img src="" className="img-fluid" />
                      <img src="\assestsss\add.svg" className="img-fluid" /> Add
                      Banner
                    </button>
                  </div>
                </div>
                <div className="table-customer">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Banner Image</th>
                        <th scope="col">Banner link</th>
                        <th scope="col">Banner Type</th>
                        <th scope="col">PUBLISH</th>
                      </tr>
                    </thead>
                    <tbody>
                      {post?.map((data, index) => {
                        return (
                          <tr>
                            <td className="Banner__Management__Img">
                              <img src={data?.imageUrl}></img>
                            </td>
                            <td>{data?.bannerLink}</td>
                            <td>{data?.type}</td>
                            <td>
                              <div className="switch-input">
                                <input
                                  class="tgl tgl-ios"
                                  id={index}
                                  type="checkbox"
                                  defaultChecked={data?.isPublish}
                                  onChange={() => publishBanner(data?.id)}
                                />
                                <label class="tgl-btn" for={index}></label>
                              </div>
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                      ;
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    {count > 0 && count <= limit ? (
                      <>
                        <p>
                          SHOWING 1-{count} OF {count}
                        </p>
                      </>
                    ) : count > limit ? (
                      <>
                        <p>
                          SHOWING{" "}
                          {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                          {limit * offset > count ? count : limit * offset} OF{" "}
                          {count}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>SHOWING 0 OF 0</p>
                      </>
                    )}
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={page}
                            page={offset}
                            onChange={(e, p) => setOffset(p)}
                            color="primary"
                          />
                        </Stack>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AddBanner
          assignshowbanner={assignshowbanner}
          setassignShowbanner={setassignShowbanner}
          getBanner={getBanner}
        />
      </div>
    </>
  );
};

export default BannerManagement;
