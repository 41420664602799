import React from 'react';
import "./oder.scss";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';

const AssignOrder = ({ assignshow, setassignShow }) => {
  const handleClose = () => setassignShow(false);
  return (
    <>
      <div className="content">
        <section className="assignorder">
          <Offcanvas show={assignshow} onHide={handleClose} className="assignorder">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Rider</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div class="table-responsive  ">
                <table class="table bashbord_table">
                  <thead className="bashbord_table_headdd">
                    <tr className="tableroww">
                      <th scope="col">Rider NAME</th>
                      <th scope="col">Status</th>
                      <th scope="col">Assign</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocessss">Processing</span></td>
                      <td><button className="tble_detailss">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocesss_red">Unavailable</span></td>
                      <td><button className="tble_detailss_Unavailable">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocessss">Processing</span></td>
                      <td><button className="tble_detailss">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocesss_red">Unavailable</span></td>
                      <td><button className="tble_detailss_Unavailable">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocessss">Processing</span></td>
                      <td><button className="tble_detailss">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocesss_red">Unavailable</span></td>
                      <td><button className="tble_detailss_Unavailable">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocessss">Processing</span></td>
                      <td><button className="tble_detailss">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocesss_red">Unavailable</span></td>
                      <td><button className="tble_detailss_Unavailable">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocessss">Processing</span></td>
                      <td><button className="tble_detailss">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocesss_red">Unavailable</span></td>
                      <td><button className="tble_detailss_Unavailable">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocessss">Processing</span></td>
                      <td><button className="tble_detailss">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocesss_red">Unavailable</span></td>
                      <td><button className="tble_detailss_Unavailable">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocessss">Processing</span></td>
                      <td><button className="tble_detailss">Assign</button></td> 

                    </tr>
                    <tr className="fstrowtable">
                      <td className=""><p className="fstrownumsecss ">    <img src="\dashbord\avtpic.svg" alt="img" className="avtartable  pr-2 img-fluid" />
                        Erick Santiago</p></td>
                      <td> <span className="greenprocesss_red">Unavailable</span></td>
                      <td><button className="tble_detailss_Unavailable">Assign</button></td> 

                    </tr>
          

                  </tbody>
                </table>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  )
}

export default AssignOrder;