import React from "react";
import { useState, useEffect } from "react";
import AddUnit from "./AddUnit";
import { useHistory } from "react-router-dom";
import EditUnit from "./EditUnit";
import Environment from "utils/environment";
import Delete from "views/Delete_Modal/Delete";
import axios from "axios";
import "./unit.scss";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
const Unit = () => {
  const val = localStorage.getItem("accessToken");
  const [assignshow, setassignShow] = useState(false);
  const [assignshowtwo, setassignShowtwo] = useState(false);
  const [show1, setShow1] = useState(null);
  const [update, setUpdate] = useState(null);
  const url = Environment.base_url + "/units/";
  const [deleteId, setDeleteId] = useState(null);
  const history = useHistory();
  const [post, setPost] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterName, setFilterName] = useState("");
  const [nameFilter, setNameFilter] = useState(false);
  const [abbreviationFilter, setAbbreviationFilter] = useState(false);
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleNameFilter = (e) => {
    if (e.target.checked) {
      setFilterName("name");
      setNameFilter(true);
      setAbbreviationFilter(false);
    } else if (!e.target.checked) {
      setNameFilter(false);
      setFilterName("name");
    }
  };

  const handleAbbreviationFilter = (e) => {
    if (e.target.checked) {
      setFilterName("abbreviation");
      setAbbreviationFilter(true);
      setNameFilter(false);
    } else if (!e.target.checked) {
      setAbbreviationFilter(false);
      setFilterName("name");
    }
  };

  const getUnit = async () => {
    setPost([]);
    await axios
      .get(
        `${Environment.base_url}/units?limit=${limit}&offset=
        ${offset}&${
          filterName ? "search=" + filterName + "=" + searchQuery : ""
        }`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        const resData = res?.data?.data;
        console.log(res.data.data);

        setPost(res?.data?.data?.units);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getUnit();
    }
  }, [filterName, searchQuery]);
  return (
    <>
      <div className="content">
        <section className="promo unit">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 p-0">
                <div className="customerdetailheading">
                  <div className="parent-heading">
                    <h3>Unit</h3>
                    <div
                      className="itemsssss backbutton"
                      style={{ maxWidth: "573px", width: "100%" }}
                    >
                      <div className="search-field w-100">
                        <img
                          src="\nav-assets\search-normal.svg"
                          alt="img"
                          className="search-icon"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="parent-input"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div class="dropdown dropdown-text-parent">
                          <h6
                            className="dropdown-text"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            By {filterName}
                            <img
                              src="\assestsss\arrow1.svg"
                              alt="img"
                              className="img-fluid ml-1"
                            />
                          </h6>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={nameFilter}
                                onChange={(e) => handleNameFilter(e)}
                              />
                              <label>By Name</label>
                            </div>
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={abbreviationFilter}
                                onChange={(e) => handleAbbreviationFilter(e)}
                              />
                              <label>By Abbreviation</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="createbtn"
                        onClick={() => setassignShow(true)}
                      >
                        <img src="" className="img-fluid" />
                        <img
                          src="\assestsss\add.svg"
                          className="img-fluid"
                        />{" "}
                        Add Unit
                      </button>
                    </div>
                  </div>

                  {/* <div className="backbutton">
                    <div className="btnsdrop">
                      <button
                        className="dropdownbtn"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="\assestsss\filter_111.svg"
                          className="img-fluid"
                        />{" "}
                        Filter
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                        <a class="dropdown-item" href="#">
                          Another action
                        </a>
                        <a class="dropdown-item" href="#">
                          Something else here
                        </a>
                      </div>
                    </div>

                    <button
                      className="createbtn"
                      onClick={() => setassignShow(true)}
                    >
                      <img src="" className="img-fluid" />
                      <img src="\assestsss\add.svg" className="img-fluid" /> Add
                      Unit
                    </button>
                  </div> */}
                </div>
                <div className="table-customer">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Abbreviation</th>
                        <th scope="col">ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {post?.map((data, index) => {
                        return (
                          <>
                            <tr>
                              <td>
                                {""}
                                {data?.name}
                              </td>
                              <td>{data?.abbreviation}</td>

                              <td>
                                <div className="images">
                                  <img
                                    src="\assestsss\edit-2.png"
                                    className="img-fluid"
                                    onClick={() => {
                                      setassignShowtwo(true);
                                      setUpdate(data?.id);
                                    }}
                                  />
                                  <a
                                    onClick={() => {
                                      setShow1(true);
                                      setDeleteId(data?.id);
                                    }}
                                  >
                                    <img
                                      src="\assestsss\trash.svg"
                                      className="img-fluid"
                                    />
                                  </a>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    {count > 0 && count <= limit ? (
                      <>
                        <p>
                          SHOWING 1-{count} OF {count}
                        </p>
                      </>
                    ) : count > limit ? (
                      <>
                        <p>
                          SHOWING{" "}
                          {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                          {limit * offset > count ? count : limit * offset} OF{" "}
                          {count}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>SHOWING 0 OF 0</p>
                      </>
                    )}
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={page}
                            page={offset}
                            onChange={(e, p) => setOffset(p)}
                            color="primary"
                          />
                        </Stack>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Delete
          show1={show1}
          setShow1={setShow1}
          id={deleteId}
          url={url}
          updateList={getUnit}
        />

        <AddUnit assignshow={assignshow} setassignShow={setassignShow} />
        <EditUnit
          assignshowtwo={assignshowtwo}
          setassignShowtwo={setassignShowtwo}
          update={update}
          getUnit={getUnit}
        />
      </div>
    </>
  );
};

export default Unit;
