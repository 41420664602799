import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import "./role.scss";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { Search } from "@material-ui/icons";
import _ from "lodash";

const EditRole = ({ show, setShow, setOpType, opType, editId, updateList }) => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url3;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [roleName, setRoleName] = useState("");
  const [description, setDescription] = useState("");
  const [searchQuery, setSearchQuery] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [permissionObject, setPermissionObject] = useState([]);
  const [permissionObjectSelected, setPermissionObjectSelected] = useState([]);

  const [permissionObjectLength, setPermissionObjectLength] = useState(0);
  const [permissionIds, setPermissionIds] = useState([]);
  const [removedPermissionIds, setRemovedPermissionIds] = useState([]);

  const [roleDetail, setRoleDetail] = useState([]);

  const [showResult, setShowResult] = useState([]);

  const handleClose = () => {
    setShow(false);
    setPermissionList([]);
    setPermissionIds([]);
    setRemovedPermissionIds([]);
    setPermissionObjectSelected([]);
    setPermissionObject([]);
    setPermissionObjectLength(0);
    setDescription("");
    setRoleName("");
    setOpType("");
  };
  // const handleShow = () => setShow(true);

  const getPermissionsList = async () => {
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/permissions?limit=" + 10 + "&offset=" + 1,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        console.log("permissions data: ", resData?.permissions);
        setPermissionList(resData?.permissions);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const addRole = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const config = {
      method: "post",
      url: api_url + "/roles",
      data: {
        name: roleName,
        description: description,
        permissionIds: permissionIds,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        handleClose();
        toast.success(res?.data?.message);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };

  const editRole = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const config = {
      method: "patch",
      url: api_url + "/roles/" + editId,
      data: {
        name: roleName,
        description: description,
        permissions: {
          ...(permissionIds.length > 0 && { addPermissionIds: permissionIds }),
          ...(removedPermissionIds.length > 0 && {
            removePermissionIds: removedPermissionIds,
          }),
        },
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        handleClose();
        toast.success(res?.data?.message);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };

  const getRoleDetails = async () => {
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/roles/" + editId,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        console.log("role detail data: ", resData);
        setDescription(resData?.roleDetails?.description);
        setRoleName(resData?.roleDetails?.name);
        const permissions = _.groupBy(resData?.rolePermissions, (item) => {
          return item.accessFor;
        });
        setPermissionList(permissions);
        console.log("role detail data: ", permissions);

        setRoleDetail(resData);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
      toast.error("Session Expired", {
        position: "top-right",
        autoClose: 2000,
      });
    } else {
      if (opType == "edit") {
        getRoleDetails();
      } else {
        getPermissionsList();
      }
    }
  }, [opType]);

  // useEffect(() => {
  //   if (!val) {
  //     history.push("/");
  //     toast.error("Session Expired", {
  //       position: "top-right",
  //       autoClose: 2000,
  //     });
  //   } else {
  //     if (searchQuery) {
  //       handleSearchQuery();
  //     } else {
  //       setShowResult([]);
  //       setSearchResults([]);
  //     }
  //   }
  // }, []);

  const handleSearchQuery = async (e) => {
    setSearchQuery(e);
    setLoader(true);
    if (e) {
      const config = {
        method: "get",
        url: api_url + "/permissions?search=accessFor=" + e,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          console.log("searrch result: ", resData);
          setSearchResults(resData);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else if (err?.response?.status == 401) {
            localStorage.removeItem("accessToken");
            // FetchRefreshToken();
            console.log("refresh token: ", err?.response);
          }
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
          setLoader(false);
        });
    } else {
      setLoader(false);
      setSearchResults([]);
    }

    // }
  };

  const handlePermissionObjectChange = (e, key, val) => {
    const tempObj = permissionObject;
    let newTempObj = [];
    if (e.target.checked) {
      let tempChecked = searchResults[key];
      tempObj.push(tempChecked);
      setPermissionObject(tempObj);
      setPermissionObjectLength(tempObj.length);
    } else if (!e.target.checked) {
      for (let i = 0; i < permissionObject.length; i++) {
        if (tempObj[i][0].accessFor != key) {
          newTempObj.push(tempObj[i]);
        }
      }
      setPermissionObject(newTempObj);
      setPermissionObjectLength(newTempObj.length);

      // permissionObject.map((item) => {
      //   console.log("uunccheck obj: ", item);
      //   if(item[i][0].accessFor == key){

      //   }
      // });
    }
  };

  const handleSelected = () => {
    setPermissionIds([]);
    setPermissionObjectSelected(permissionObject);
  };

  const handlePermissionIds = (e, item) => {
    let tempIds = permissionIds;
    let newTempIds = [];
    let removeIdsTemp = removedPermissionIds;
    if (opType == "edit") {
      if (e.target.checked) {
        tempIds.push(item?.permissionId);
        setPermissionIds(tempIds);
      } else if (!e.target.checked) {
        for (let i = 0; i < permissionIds.length; i++) {
          if (tempIds[i] != item?.permissionId) {
            newTempIds.push(tempIds[i]);
          }
        }

        removeIdsTemp.push(item?.permissionId);
        setRemovedPermissionIds(removeIdsTemp);
        setPermissionIds(newTempIds);
      }
    } else if (opType == "add") {
      if (e.target.checked) {
        tempIds.push(item?.id);
        setPermissionIds(tempIds);
      } else if (!e.target.checked) {
        for (let i = 0; i < permissionIds.length; i++) {
          if (tempIds[i] != item?.id) {
            newTempIds.push(tempIds[i]);
          }
        }

        setPermissionIds(newTempIds);
      }
    }
  };

  const handleSelectedPermissionId = (e, i) => {
    let tempIds = permissionIds;
    let newTempIds = [];
    let removeIdsTemp = removedPermissionIds;
    console.log("item id: ");
    if (e.target.checked) {
      console.log("checked: ", i);

      tempIds.push(i?.id);
      setPermissionIds(tempIds);
    } else if (!e.target.checked) {
      for (let ind = 0; ind < permissionIds.length; ind++) {
        if (tempIds[ind] != i?.id) {
          newTempIds.push(tempIds[ind]);
        }
      }

      removeIdsTemp.push(i?.id);
      console.log("rremove idssss: ", removeIdsTemp);
      setRemovedPermissionIds(removeIdsTemp);
      setPermissionIds(newTempIds);
    }
  };

  return (
    <>
      <div className="content">
        <section className="sidebar-editrole">
          <Offcanvas
            show={show}
            onHide={handleClose}
            className="sidebar-editrole"
          >
            <Offcanvas.Header closeButton>
              {opType == "add" ? (
                <Offcanvas.Title>Add Role</Offcanvas.Title>
              ) : opType == "edit" ? (
                <Offcanvas.Title>Update Role</Offcanvas.Title>
              ) : (
                <Offcanvas.Title>Role</Offcanvas.Title>
              )}
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="main">
                <p className="inputpara mt-0">
                  Role Name<span className="red">*</span>
                </p>
                <input
                  className="maininput"
                  placeholder="Role Name"
                  type="text"
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                />
              </div>
              <div className="desprt">
                <p className="inputpara">
                  Description*<span className="red">*</span>
                </p>
                <textarea
                  className="maininput"
                  placeholder="Description*"
                  type="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="dropdownbtn">
                <p className="inputpara">
                  Permission<span className="red">*</span>
                </p>
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {permissionObjectLength > 0 ? (
                      <>{permissionObjectLength} items selected</>
                    ) : permissionObjectLength == 0 ? (
                      <>Select by searching</>
                    ) : null}
                    <img src="\assestsss\arrow 1.svg" alt="arrow" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="main-parent">
                      <input
                        className="maininput"
                        placeholder="Role Name"
                        type="text"
                        value={searchQuery}
                        onChange={(e) => handleSearchQuery(e.target.value)}
                      />
                      <img
                        src="\assestsss\search-normal.svg"
                        className="img-fluid"
                      />
                    </div>
                    {/* {showResult?.length > 0
                      ? showResult.map((item, index) => {
                          console.log("search Array: ", searchResults);

                          return (
                            <>
                              <div class="form-group">
                                <input type="checkbox" id={index} />
                                <label for={index}>{item}</label>
                              </div>
                            </>
                          );
                        })
                      : null} */}
                    {Object.keys(searchResults).length > 0 && searchQuery
                      ? Object.entries(searchResults).map(
                          ([key, val], index) => {
                            // console.log("keys: ", key, index);
                            // Object.entries(val).map((item) => {
                            //   console.log("key val array: ", item);
                            // });
                            return (
                              <>
                                <div class="form-group">
                                  <input
                                    type="checkbox"
                                    id={index + key}
                                    onChange={(e) =>
                                      handlePermissionObjectChange(e, key, val)
                                    }
                                  />
                                  <label for={index + key}>{key}</label>
                                </div>
                              </>
                            );
                          }
                        )
                      : null}
                    <div className="AddSelected">
                      <button onClick={handleSelected}>Add Selected</button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {permissionObjectSelected.length > 0
                ? permissionObjectSelected.map((item, index) => {
                    return (
                      <>
                        <p className="inputpara">{item[0]?.accessFor}</p>
                        <div className="checked-main">
                          {item?.map((i, j) => {
                            return (
                              <>
                                <div class="form-group pr-4">
                                  <input
                                    type="checkbox"
                                    id={index + i + j}
                                    onChange={(e) =>
                                      handleSelectedPermissionId(e, i)
                                    }
                                  />
                                  <label for={index + i + j}>{i?.name}</label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    );
                  })
                : Object.keys(permissionList).length > 0
                ? Object.entries(permissionList).map(([key, val]) => {
                    return (
                      <>
                        <p className="inputpara">{key}</p>
                        <div className="checked-main">
                          {val?.map((item, index) => {
                            return (
                              <>
                                <div class="form-group pr-4">
                                  <input
                                    type="checkbox"
                                    id={index + key}
                                    defaultChecked={item?.roleGranted}
                                    // onChange={(e) =>
                                    //   handlePermissionIds(e, item)
                                    // }
                                    onClick={(e) =>
                                      handlePermissionIds(e, item)
                                    }
                                  />
                                  <label for={index + key}>{item?.name}</label>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </>
                    );
                  })
                : null}

              <div className="endbutton">
                <button className="cancel">Cancel</button>
                {opType == "add" ? (
                  <button className="addrole ml-2" onClick={addRole}>
                    Add Role
                  </button>
                ) : opType == "edit" ? (
                  <button className="addrole ml-2" onClick={editRole}>
                    Save Role
                  </button>
                ) : null}
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};
export default EditRole;
