import React from 'react'
import { useState } from 'react';
import Environment from 'utils/environment';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import "../Promo/promo.scss"
import "./unit.scss"

const AddUnit = ({ assignshow, setassignShow }) => {
    const val = localStorage.getItem("accessToken");
    const history = useHistory();
    const handleClose = () => setassignShow(false);
    const [loader, setLoader] = useState(false);
    const [unitname, setUnitName] = useState("");
    const [abbrev, setAbbrev] = useState("");

    const Addunit = async (e) => {
        if (unitname.length === 0) {
            toast.error("Enter Name")
        } else if (abbrev.length === 0) {
            toast.error("Enter Abbreviation")
        } else {
            const config = {
                method: "post",
                url: `${Environment.base_url}/units`,
                data: {
                    name: unitname,
                    abbreviation: abbrev,
                },
                headers: {
                    Authorization: "Bearer " + val,
                },
            };
            await axios(config)
                .then((res) => {
                    handleClose();
                    toast.success(res?.data?.message);
                    setLoader(false);
                })
                .catch((err) => {
                    if (err?.response?.statusCode == 501) {
                        history.push("/");
                    } else {
                        console.log("error meessage: ", err?.response?.data?.message);
                        toast.error(err?.response?.data?.message, {
                            position: "bottom-left",
                            autoClose: 2000,
                        });
                    }
                    setLoader(false);
                });
        }
    }


    return (
        <>
            <div className="content">
                <section className="sidebar-promoproduct brandmanagement">
                    <Offcanvas show={assignshow} onHide={handleClose} className="sidebar-promoproduct">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                Add Unit
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>


                            <div className='filedinput'>
                                <div className="option-field">
                                    <p>Unit Name*</p>
                                    <input type="text" placeholder="Unit Name" value={unitname} onChange={(e) => { setUnitName(e.target.value) }} />
                                </div>
                                <div className="option-field">
                                    <p>Abbreviation*</p>
                                    <input type="text" placeholder="Abbreviation" value={abbrev} onChange={(e) => { setAbbrev(e.target.value) }} />
                                </div>


                            </div>
                            <div className='buttonss'>
                                <button className=''>Cancel</button>
                                <button className='ml-2' onClick={Addunit}>Add Unit </button>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </section>
            </div>
        </>
    )
}

export default AddUnit;