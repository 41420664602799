import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./promo.scss";
import PromoSidebar from "./PromoSidebar";
import Createcodesidebar from "./Createcodesidebar";
import Delete from "views/Delete_Modal/Delete";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

const Promo = () => {
  const subUrl = "/promos/";

  const [show1, setShow1] = useState(null);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [assignshow, setassignShow] = useState(false);
  const [assignshowtwo, setassignShowtwo] = useState(false);
  const [update, setUpdate] = useState(null);
  const [promoDetail, setPromoDetail] = useState({});
  const [getpromos, setGetPromos] = useState([]);
  const [promos, setPromos] = useState([]);
  const [find, setFind] = useState("");
  const [publish, setPublish] = useState(null);

  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterName, setFilterName] = useState("");

  const [categoryFilter, setCategoryFilter] = useState(false);
  const [promoFilter, setPromoFilter] = useState(false);

  const handleCategoryFilter = (e) => {
    if (e.target.checked) {
      setFilterName("name");

      setCategoryFilter(true);
      setPromoFilter(false);
    } else if (!e.target.checked) {
      setCategoryFilter(false);
      setFilterName("name");
    }
  };

  const handlePromoFilter = (e) => {
    if (e.target.checked) {
      setFilterName("promoCode");

      setPromoFilter(true);
      setCategoryFilter(false);
    } else if (!e.target.checked) {
      setPromoFilter(false);
      setFilterName("name");
    }
  };

  const FetchRefreshToken = async () => {
    localStorage.removeItem("accessToken");
    let refreshVal = localStorage.getItem("refreshToken");
    var config = {
      method: "post",
      url: api_url + `/auth/admins/refresh-token`,
      headers: {
        "Content-Type": "application/json",
      },
      data: { refreshToken: refreshVal },
    };
    await axios(config)
      .then(function (response) {
        localStorage.removeItem("refreshToken");
        console.log(response.data.data);
        localStorage.setItem("accessToken", response.data.data.accessToken);
        localStorage.setItem("refreshToken", response.data.data.refreshToken);
        history.push("/admin/dashboard");
      })
      .catch(function (error) {
        console.log(error);
        // history.push('/login');
        if (error.response.data.status === 400) {
          // toast.error('Validation Failed', {
          //   position: 'top-right',
          //   autoClose: 3000,
          // });
          console.log(error);
        } else if (error.response.data.status === 401) {
          toast.error("Jwt expired/invalid", {
            position: "top-right",
            autoClose: 3000,
          });
          history.push("/");
        } else if (error.response.data.status === 403) {
          toast.error("403", {
            position: "top-right",
            autoClose: 3000,
          });
        } else if (error.response.data.status === 404) {
          toast.error("Whitelisted user not found", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      });
  };

  const getPromos = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setPromos([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/promos?limit=" +
        limit +
        "&offset=" +
        offset +
        `&${
          filterName == "name"
            ? "searchByCategory=true&search=" + filterName + "=" + searchQuery
            : filterName == "promoCode"
            ? "search=" + filterName + "=" + searchQuery
            : ""
        }
        `,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setPromos(resData?.promos);

        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);

        setPage(resData?.pages);
        // for (let i = 1; i <= responseData?.pages; i++) {
        //   pageLength.push(i);
        // }
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const filter = async () => {
    await axios
      .get(
        `${Environment.base_url}/promos?offset=1&limit=10&search=promoCode=${find}&filter=isPublish=eq:${publish}`,
        {
          headers: {
            Authorization: "Bearer " + val,
          },
        }
      )
      .then((res) => {
        console.log(res.data.data);
        setPromos(res?.data?.data?.promos);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getPromos();
    }
  }, [offset, searchQuery]);

  const publishPromo = async (id) => {
    var config = {
      method: "patch",
      url: `${Environment.base_url}/promos/${id}/toggle-promo`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then(function (response) {})
      .catch(function (error) {});
  };

  return (
    <>
      <div className="content">
        <section className="promo">
          <div className="content p-0">
            <section className="promo">
              <div className="container-fluid p-0">
                <div className="row">
                  <div className="col-xl-12 p-0">
                    <div className="customerdetailheading">
                      <div className="parent-heading">
                        <h3>Promos</h3>
                        <div
                          className="itemsssss backbutton"
                          style={{ maxWidth: "573px", width: "100%" }}
                        >
                          <div className="search-field w-100">
                            <img
                              src="\nav-assets\search-normal.svg"
                              alt="img"
                              className="search-icon"
                            />
                            <input
                              type="text"
                              placeholder="Search"
                              className="parent-input"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div class="dropdown dropdown-text-parent">
                              <h6
                                className="dropdown-text"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                By {filterName}
                                <img
                                  src="\assestsss\arrow1.svg"
                                  alt="img"
                                  className="img-fluid ml-1"
                                />
                              </h6>
                              <div
                                class="dropdown-menu drop__input-field"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div className="input-field">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                    checked={categoryFilter}
                                    onChange={(e) => handleCategoryFilter(e)}
                                  />
                                  <label>By Category</label>
                                </div>
                                <div className="input-field">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="defaultCheck1"
                                    checked={promoFilter}
                                    onChange={(e) => handlePromoFilter(e)}
                                  />
                                  <label>By Promo Code</label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <button
                            className="createbtn"
                            onClick={() => setassignShow(true)}
                          >
                            <img src="" className="img-fluid" />
                            <img
                              src="\assestsss\add.svg"
                              className="img-fluid"
                            />{" "}
                            Create Code
                          </button>
                        </div>
                      </div>

                      {/* <div className="backbutton">
                        <div className="btnsdrop">
                          <button
                            className="dropdownbtn"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              src="\assestsss\filter_111.svg"
                              className="img-fluid"
                            />{" "}
                            Filter
                          </button>
                          <div class="dropdown-menu">
                            <a class="dropdown-item" href="#">
                              Action
                            </a>
                            <a class="dropdown-item" href="#">
                              Another action
                            </a>
                            <a class="dropdown-item" href="#">
                              Something else here
                            </a>
                          </div>
                        </div>

                        <button
                          className="createbtn"
                          onClick={() => setassignShow(true)}
                        >
                          <img src="" className="img-fluid" />
                          <img
                            src="\assestsss\add.svg"
                            className="img-fluid"
                          />{" "}
                          Create Code
                        </button>
                      </div> */}
                    </div>
                    <div className="table-customer">
                      <table class="table">
                        <thead>
                          <tr>
                            <th scope="col">Promo Code</th>
                            <th scope="col">Start Time</th>
                            <th scope="col">END Time</th>
                            <th scope="col">Discount %</th>
                            <th scope="col">Category</th>
                            <th scope="col">PUBLISH</th>
                            <th scope="col">status</th>
                            <th scope="col">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {promos?.map((data, index) => {
                            return (
                              <>
                                {console.log(data?.id, "asdasdasd")}
                                <tr>
                                  <td>{data?.promoCode}</td>
                                  <td>
                                    {moment(data?.startTime).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {moment(data?.endTime).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </td>
                                  <td>{data?.discount}</td>
                                  <td>
                                    {data?.promotionApplies[0]?.product?.name}
                                  </td>
                                  <td>
                                    <div className="switch-input">
                                      <input
                                        class="tgl tgl-ios"
                                        id={index}
                                        type="checkbox"
                                        defaultChecked={data?.isActive}
                                        onChange={() => publishPromo(data?.id)}
                                      />
                                      <label
                                        class="tgl-btn"
                                        for={index}
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="detailbutton">
                                      <span className="">Published</span>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="images">
                                      <img
                                        src="\assestsss\edit-2.png"
                                        className="img-fluid"
                                        onClick={() => {
                                          setassignShowtwo(true);
                                          setUpdate(data?.id);
                                        }}
                                      />
                                      <a
                                        onClick={() => {
                                          setShow1(true);
                                          setDeleteId(data?.id);
                                        }}
                                      >
                                        <img
                                          src="\assestsss\trash.svg"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="mainfoot">
                      <div className="left">
                        {count > 0 && count <= limit ? (
                          <>
                            <p>
                              SHOWING 1-{count} OF {count}
                            </p>
                          </>
                        ) : count > limit ? (
                          <>
                            <p>
                              SHOWING{" "}
                              {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                              {limit * offset > count ? count : limit * offset}{" "}
                              OF {count}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>SHOWING 0 OF 0</p>
                          </>
                        )}
                      </div>
                      <div className="right">
                        <nav aria-label="Page navigation example">
                          <ul class="pagination">
                            <li class="page-item">
                              {offset > 1 ? (
                                <>
                                  <a
                                    class="page-link"
                                    href="#"
                                    aria-label="Previous"
                                    onClick={() =>
                                      setOffset((prev) => prev - 1)
                                    }
                                  >
                                    <span aria-hidden="true">
                                      <i class="fas fa-angle-left"></i>
                                    </span>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                            </li>
                            {pageCount.length > 1 ? (
                              <>
                                {pageCount.map((item) => {
                                  return (
                                    <>
                                      <li className="page-item">
                                        <a
                                          className={
                                            item + 1 == offset
                                              ? "page-link active"
                                              : "page-link"
                                          }
                                          href="#"
                                          onClick={() => setOffset(item + 1)}
                                        >
                                          {item + 1}
                                        </a>
                                      </li>
                                    </>
                                  );
                                })}
                              </>
                            ) : null}

                            <li class="page-item">
                              {offset < page ? (
                                <>
                                  <a
                                    class="page-link"
                                    href="#"
                                    aria-label="Next"
                                    onClick={() =>
                                      setOffset((prev) => prev + 1)
                                    }
                                  >
                                    <span aria-hidden="true">
                                      <i class="fas fa-angle-right"></i>
                                    </span>
                                  </a>
                                </>
                              ) : (
                                <></>
                              )}
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
        <Delete
          show1={show1}
          setShow1={setShow1}
          id={deleteId}
          subUrl={subUrl}
          getpromos={getpromos}
        />
        <PromoSidebar
          assignshow={assignshow}
          setassignShow={setassignShow}
          getpromos={getpromos}
        />
          <Createcodesidebar
          assignshowtwo={assignshowtwo}
          setassignShowtwo={setassignShowtwo}
          getPromos={getPromos}
          update={update}
        />
      </div>
    </>
  );
};

export default Promo;
