import React from "react";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Environment from "utils/environment";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const Assingrider = ({ assignshow, setassignShowr, getRiders }) => {
  const handleClose = () => setassignShowr(false);
  const [name, setName] = useState("");
  const [cnic, setCnic] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [date, setDate] = useState("");
  const [loader, setLoader] = useState(false);
  const val = localStorage.getItem("accessToken");

  // rider image
  const [riderimage, setRiderimage] = useState(null);
  const onImageChange = (event) => {
    setRiderimage(event.target.files[0]);
  };

  // ID front
  const [idfront, setIdfront] = useState(null);
  const onImageChange2 = (event) => {
    setIdfront(event.target.files[0]);
  };

  // ID back
  const [idback, setIdback] = useState(null);
  const onImageChange3 = (event) => {
    setIdback(event.target.files[0]);
  };

  // Lic front
  const [licfront, setLicfront] = useState(null);
  const onImageChange4 = (event) => {
    setLicfront(event.target.files[0]);
  };
  // Lic back
  const [licback, setLicback] = useState(null);
  const onImageChange5 = (event) => {
    setLicback(event.target.files[0]);
  };
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // add rider
  const Addrider = async (e) => {
    if (!riderimage) {
      toast.error("Upload Image");
    } else if (!idfront) {
      toast.error("Upload Cnic Front");
    } else if (!idback) {
      toast.error("Upload Cnic Back");
    } else if (!licfront) {
      toast.error("Upload License Front");
    } else if (!licback) {
      toast.error("Upload License Back");
    } else if (name.length === 0) {
      toast.error("Enter Name");
      // console.log(toast.error, 'toast error');
    } else if (cnic.length === 0) {
      toast.error("Enter Cnic");
    } else if (email.length === 0) {
      toast.error("Enter Email");
    } else if (!isValidEmail(email)) {
      toast.error("Email is invalid");
    } else if (contact.length === 0) {
      toast.error("Enter Contact no");
    } else if (date.length === 0) {
      toast.error("Enter Date");
    } else {
      const formData = new FormData();
      if (name) {
        formData.append("name", name);
      }
      if (cnic) {
        formData.append("cnicNumber", cnic);
      }
      if (date) {
        formData.append("joiningDate", date);
      }
      if (contact) {
        formData.append("phoneNumber", contact);
      }
      if (email) {
        formData.append("email", email);
      }
      if (riderimage) {
        formData.append("riderImage", riderimage);
      }
      if (idfront) {
        formData.append("riderCnicFront", idfront);
      }
      if (idback) {
        formData.append("riderCnicBack", idback);
      }
      if (licfront) {
        formData.append("riderLicFront", licfront);
      }
      if (licback) {
        formData.append("riderLicBack", licback);
      }
      var config = {
        method: "post",
        url: `${Environment.base_url3}/riders`,
        data: formData,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then(function (response) {
          getRiders();
          toast.success(response?.data?.message);
          // console.log("res: ", response?.data);

          // console.log("tokeen: ", response?.data);
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          toast.error(error?.response?.data?.message);
        });
    }
    console.log(cnic);
  };

  // refresh
  const [message, setMessage] = useState("");
  const handleChangee = (event) => {
    setMessage(event);
  };
  const handleClick = () => {
    setMessage("");
    setRiderimage(null);
    setIdfront(null);
    setIdback(null);
    setLicfront(null);
    setLicback(null);
  };

  return (
    <>
      <div className="content">
        <section className="assingrider">
          <Offcanvas
            show={assignshow}
            onHide={handleClose}
            className="update_staff"
          >
            <Offcanvas.Header closeButton onClick={handleClick}>
              <Offcanvas.Title>Add Staff</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="bannerss">
                <h4>Rider Image*</h4>
                <div className="item-upload">
                  <ul className="list-inline text-center">
                    <li className="list-inline-item">
                      <input
                        id="upload"
                        type="file"
                        className="d-none"
                        onChange={onImageChange}
                      />
                      <label for="upload" className="">
                        {riderimage ? (
                          <img
                            src={URL.createObjectURL(riderimage)}
                            alt="img"
                            className="img-fluid upload-img"
                          />
                        ) : (
                          <>
                            <div>
                              <img
                                src="\assestsss\gallery-add.svg"
                                className="img-fluid"
                              />
                              <h4 className="">Drag your image here</h4>
                              <p>
                                (Only *.jpeg and *.png images will be accepted)
                              </p>
                            </div>
                          </>
                        )}
                      </label>
                    </li>
                  </ul>
                </div>
                <div className="mainidcard">
                  <div className="idcard mr-3">
                    <h4>ID Card Image Front*</h4>
                    <div className="item-upload">
                      <ul className="list-inline ">
                        <li className="list-inline-item">
                          <input
                            id="uploadid"
                            type="file"
                            className="d-none"
                            onChange={onImageChange2}
                          />
                          <label for="uploadid" className="">
                            {idfront ? (
                              <img
                                src={URL.createObjectURL(idfront)}
                                alt="img"
                                className="img-fluid upload-img"
                              />
                            ) : (
                              <img
                                src="\assestsss\gallery-add.svg"
                                className="img-fluid"
                              />
                            )}
                          </label>
                          <h4 className="">Drag your image here</h4>
                          <p>(Only *.jpeg and *.png images will be accepted)</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="idcard">
                    <h4>ID Card Image Back*</h4>
                    <div className="item-upload">
                      <ul className="list-inline ">
                        <li className="list-inline-item">
                          <input
                            id="uploadid2"
                            type="file"
                            className="d-none"
                            onChange={onImageChange3}
                          />
                          <label for="uploadid2" className="">
                            {idback ? (
                              <img
                                src={URL.createObjectURL(idback)}
                                alt="img"
                                className="img-fluid upload-img"
                              />
                            ) : (
                              <img
                                src="\assestsss\gallery-add.svg"
                                className="img-fluid"
                              />
                            )}
                          </label>
                          <h4 className="">Drag your image here</h4>
                          <p>(Only *.jpeg and *.png images will be accepted)</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mainidcard">
                  <div className="idcard mr-3">
                    <h4>Licence Image Front*</h4>
                    <div className="item-upload">
                      <ul className="list-inline ">
                        <li className="list-inline-item">
                          <input
                            id="uploadlic"
                            type="file"
                            className="d-none"
                            onChange={onImageChange4}
                          />
                          <label for="uploadlic" className="">
                            {licfront ? (
                              <img
                                src={URL.createObjectURL(licfront)}
                                alt="img"
                                className="img-fluid upload-img"
                              />
                            ) : (
                              <img
                                src="\assestsss\gallery-add.svg"
                                className="img-fluid"
                              />
                            )}
                          </label>
                          <h4 className="">Drag your image here</h4>
                          <p>(Only *.jpeg and *.png images will be accepted)</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="idcard">
                    <h4>Licence Image Back*</h4>
                    <div className="item-upload">
                      <ul className="list-inline ">
                        <li className="list-inline-item">
                          <input
                            id="uploadlic2"
                            type="file"
                            className="d-none"
                            onChange={onImageChange5}
                          />
                          <label for="uploadlic2" className="">
                            {licback ? (
                              <img
                                src={URL.createObjectURL(licback)}
                                alt="img"
                                className="img-fluid upload-img"
                              />
                            ) : (
                              <img
                                src="\assestsss\gallery-add.svg"
                                className="img-fluid"
                              />
                            )}
                          </label>
                          <h4 className="">Drag your image here</h4>
                          <p>(Only *.jpeg and *.png images will be accepted)</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="filedinput">
                  <p>Rider Name*</p>
                  <input
                    value={name.message}
                    onChange={(e) => {
                      setName(e.target.value);
                      handleChangee();
                    }}
                    type="text"
                    id="message"
                    placeholder="Name"
                  />
                  <p>CNIC No.*</p>
                  <input
                    value={cnic.message}
                    onChange={(e) => setCnic(e.target.value)}
                    type="text"
                    placeholder="CNIC Number"
                  />
                  <p>Email</p>
                  <input
                    id="message"
                    value={email.message}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Email"
                  />
                  <p>Contact Number*</p>
                  <input
                    value={contact.message}
                    onChange={(e) => setContact(e.target.value)}
                    type="text"
                    placeholder="Phone Numbers"
                  />
                  <p>Joining Date</p>
                  <input
                    value={date.message}
                    onChange={(e) => {
                      setDate(e.target.value);
                      console.log("date: ", e.target.value);
                    }}
                    type="date"
                    placeholder="DD/MM/YY"
                  />
                </div>
                <div className="buttonss">
                  <button className="">Cancel</button>
                  <button
                    onClick={(e) => {
                      Addrider(e.target.value);
                      handleClose();
                    }}
                    className="ml-2"
                  >
                    Add Rider{" "}
                  </button>
                </div>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};

export default Assingrider;
