import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
// import "./category.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import Environment from "utils/environment";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import { SettingsInputCompositeSharp } from "@material-ui/icons";
const Ratelistsidebar = ({
  updateList,
  opType,
  editId,
  setEditId,
  setOpType,
  show22,
  setShow22,
}) => {
  // const [showss, setShowss] = useState(false);

  const handleClose22 = () => {
    setShow22(false);
    setEditId("");
    setChildCategoryList([]);
    setChildCategory(null);
    setSecondChildCategory(null);
    setSecondChildCategoryList([]);
    setName("");
    setDetail("");
    setCategory();
    setCategoryId("");
    setCategoryList([]);
    setUnit("");
    setUnitQuantity("");
    setOutOfStockThreshold("");
    setPrice("");
    setQuantity("");
    setBrandId("");
    setBrand("");
    setUnitId("");
  };

  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState("");
  const [baseImage, setBaseImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [parentCategoryName, setParentCategoryName] = useState("");

  const [parentCategory, setParentCategory] = useState("");
  const [description, setDescription] = useState("");
  const [accountImage, setAccountImage] = useState();
  const [categoryDetail, setCategoryDetail] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [type,setType]=useState("");
  const [parentCategoryId, setParentCategoryId] = useState("");
  // const [image, setImage] = useState("");
  // const [baseImage, setBaseImage] = useState("");
  const [name, setName] = useState("");
  const [detail, setDetail] = useState("");
  const [category, setCategory] = useState(null);
  const [getPharmacy, setGetPharmacy] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [allow, setAllow] = useState(false);
  const [childCategory, setChildCategory] = useState(null);
  const [childCategoryList, setChildCategoryList] = useState([]);
  const [secondChildCategory, setSecondChildCategory] = useState(null);
  const [secondChildCategoryList, setSecondChildCategoryList] = useState([]);
  const [unit, setUnit] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [unitId, setUnitId] = useState("");
  const [unitQuantity, setUnitQuantity] = useState("");
  const [brand, setBrand] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [brandId, setBrandId] = useState("");
  const [searchQuery, setSearchQuery] = useState();
  const [id, setId] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [tags, setTags] = useState([]);
  const [outOfStockThreshold, setOutOfStockThreshold] = useState("");
  const [maxCap, setMaxCap] = useState("");




  const getChildCategory = async (id) => {
    // validateUserName();
    // if (!errors) {

    const config = {
      method: "get",
      url: api_url + "/store/categories/" + id + "/childs",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setChildCategoryList(resData?.childCategories);

        console.log("child cats: ", resData?.childCategories);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const getSecondChildCategory = async (id) => {
    // validateUserName();
    // if (!errors) {

    const config = {
      method: "get",
      url: api_url + "/store/categories/" + id + "/childs",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setSecondChildCategoryList(resData?.childCategories);
        console.log("child cats: ", resData?.childCategories);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const getUnitList = async () => {
    // validateUserName();
    // if (!errors) {

    const config = {
      method: "get",
      url: api_url + "/units?limit=20&minimal=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setUnitList(resData);
        console.log("units: ", resData);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const getBrandList = async () => {
    // validateUserName();
    // if (!errors) {

    const config = {
      method: "get",
      url: api_url + "/brands?limit=200&minimal=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setBrandList(resData);
        console.log("Brands: ", resData);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };



  const deleteCategory = async (id) => {

    const data = {
      categoryId: id
    }
    const config = {
      method: "delete",
      url: api_url + "/store/product-categories/" + editId,
      data: data,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        getProductDetails();
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
      });
  }


  const addCategories = async (id) => {

    const data = {
      categoryId: id
    }
    const config = {
      method: "post",
      url: api_url + "/store/product-categories/" + editId,
      data: data,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        getProductDetails();
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
      });
  }

  const getProductDetails = async () => {
    setLoader(true);

    const config = {
      method: "get",
      url: api_url + "/store/products/" + editId,

      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const responseData = res?.data?.data;
        setId(responseData?.sourceProductId);

        setName(responseData?.name);
        setDetail(responseData?.description);
        setBaseImage(responseData?.imageUrl);
        setPrice(responseData?.price);
        setQuantity(responseData?.quantity);
        setUnit(responseData?.unit);
        setUnitId(responseData?.unit?.id);
        setUnitQuantity(responseData?.unitQuantity);

        setBrand(responseData?.brand);
        setType(responseData?.type)
        setBrandId(responseData?.brand?.id);
        setTags(responseData?.tags == null ? [] : responseData?.tags);
        setOutOfStockThreshold(responseData?.outOfStockThreshold);
        setMaxCap(responseData?.productCap?.maxCap)
        console.log("category: ", responseData?.category);

        setGetPharmacy(responseData.category.some(cat => cat?.name == 'Pharmacy'));

        if (responseData?.category?.parentCategory == null) {
          setCategory(responseData?.category);

          setCategoryId(responseData?.category?.id);
        } else if (
          responseData?.category?.parentCategory?.parentCategory == null
        ) {
          setCategoryId(responseData?.category?.parentCategory?.id);

          setCategory(responseData?.category?.parentCategory);
          setChildCategory(responseData?.category);
          getChildCategory(responseData?.category?.parentCategory?.id);
        } else if (
          responseData?.category?.parentCategory?.parentCategory
            ?.parentCategory == null
        ) {
          setCategory(responseData?.category?.parentCategory?.parentCategory);
          setCategoryId(
            responseData?.category?.parentCategory?.parentCategory?.id
          );

          setChildCategory(responseData?.category?.parentCategory);
          setSecondChildCategory(responseData?.category);
          getChildCategory(
            responseData?.category?.parentCategory?.parentCategory?.id
          );
          getSecondChildCategory(responseData?.category?.parentCategory?.id);
        }

        console.log("data: ", responseData);
        // setCategoryDetail(res?.data?.data);

        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  const handleClose = () => {
    // setShow(false);
    setCategoryName("");
    setParentCategory("");
    setDescription("");
    setBaseImage();
    setImage();
    setOpType("");
  };

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };
  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getCategoryList = async () => {
    // validateUserName();
    // if (!errors) {
    console.log("hittinng: ");

    const config = {
      method: "get",
      url:
        api_url +
        "/store/categories?minimal=true&afterElement=0&limit=50&allCategories=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setCategoryList(resData);
        console.log("child cats: ", resData);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const addCategory = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const formData = new FormData();
    if (categoryName) {
      formData.append("name", categoryName);
    }
    if (description) {
      formData.append("description", description);
    }
    if (parentCategoryId) {
      formData.append("parentCategoryId", parentCategoryId);
    }
    if (image) {
      formData.append("categoryImage", image);
    }
    const config = {
      method: "post",
      url: api_url + "/store/categories",
      data: formData,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        updateList();
        handleClose();
        toast.success(res?.data?.message);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        setLoader(false);
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };


  const editProduct = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const formData = new FormData();
    if (name) {
      formData.append("name", name);
    }
    if (detail) {
      formData.append("description", detail);
    }
    if (unitId) {
      formData.append("unitId", unitId);
    }  if (type) {
      formData.append("type", type);
    }
    if (unitQuantity) {
      formData.append("unitQuantity", unitQuantity);
    }
    if (brandId) {
      formData.append("brandId", brandId);
    }

    if (outOfStockThreshold) {
      formData.append("outOfStockThreshold", outOfStockThreshold);
    }
    if (maxCap) {
      formData.append("maxSelling", maxCap);
    }
    if (price) {
      formData.append("price", price);
    }
    if (categoryId) {
      formData.append("productCategoryId", categoryId);
    }
    if (image) {
      formData.append("productImage", image);
    } if (allow) {
      formData.append("isPrescription", allow);

    }if (!allow) {
      formData.append("isPrescription", false);
    }
    const config = {
      method: "patch",
      url: api_url + "/store/products/" + editId,
      data: formData,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        handleClose22();
        toast.success(res?.data?.message);
        setBaseImage();
        setImage();
        updateList();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        setLoader(false);
        // handleClose22();

      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };

  // useEffect(() => {
  //   console.log("type: ", opType);
  //   if (!val) {
  //     history.push("/");
  //   } else {
  //     getCategoryList();
  //     if (opType == "edit") {
  //       getCategoryDetail();
  //     }
  //   }
  // }, [opType]);

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      if (editId) {
        getCategoryList();
        getProductDetails();
        getUnitList();
        getBrandList();
      }
    }
  }, [editId]);

  const handleCheckboxChange = () => {
    setAllow(!allow);
  };

  return (
    <div className="content">
      <section className="addcategory">
        <Offcanvas show={show22} onHide={handleClose22} className="addcategory">
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="main-form">
              <div className="bannerss">
                <h4>Product Image </h4>
                <div className="item-upload appleimmgss">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <input
                        id="upload"
                        type="file"
                        className="d-none"
                        onChange={uploadImages}
                      />
                      <label for="upload" className="">
                        {baseImage ? (
                          <img
                            src={baseImage}
                            alt="img"
                            className="img-fluid upload-img"
                          />
                        ) : (
                          <>
                            <div>
                              <img
                                src="\assestsss\gallery-add.svg"
                                className="img-fluid"
                              />
                              <h4 className="">Drag your image here</h4>
                              <p>
                                (Only *.jpeg and *.png images will be accepted)
                              </p>
                            </div>

                            {/* <h6>Upload user</h6> */}
                          </>
                        )}
                        {/* <img src="\assestsss\Layer.svg" className="img-fluid" /> */}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p className="head">Product Name </p>
                <input
                  type="text"
                  placeholder="Apple"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="dropdownbtn">
                <p className="inputpara">Category</p>
                {category?.map((data, index) => (
                  <div key={index} className="selected">
                    {data?.name} <a onClick={() => deleteCategory(data?.id)}><img src="\assestsss\redcross.svg" alt="red cross"></img></a>
                  </div>
                ))}

                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {category != null ? <> {category?.name}</> : null}
                    <img src="\assestsss\arrow 1.svg" alt="arrow" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {categoryList.length > 0
                      ? categoryList.map((item, index) => {
                        return (
                          <Dropdown.Item
                            eventKey={item?.id}
                            onClick={(e) => {
                              addCategories(item?.id);
                            }}
                          >
                            {item?.name}
                          </Dropdown.Item>
                        );
                      })
                      : null}
                  </Dropdown.Menu>

                  {getPharmacy ? (
                    <div className="pres">
                      <input checked={allow}
                        onChange={handleCheckboxChange} type="checkbox" id="pharmacy" />
                      <label htmlFor="pharmacy"> Prescription Required</label>
                    </div>
                  ) : (
                    <div>

                    </div>
                  )}

                </Dropdown>
              </div>
              {childCategoryList?.length > 0 ? (
                <div className="dropdownbtn">
                  <p className="inputpara"> Child Category</p>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {childCategory != null ? (
                        <> {childCategory?.name}</>
                      ) : null}
                      <img src="\assestsss\arrow 1.svg" alt="arrow" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {childCategoryList.length > 0
                        ? childCategoryList.map((item, index) => {
                          console.log("child items: ", item);
                          return (
                            <Dropdown.Item
                              eventKey={item?.id}
                              onClick={(e) => {
                                // setRoleId(item?.id);
                                setCategoryId(item?.id);
                                setChildCategory(item);
                                getSecondChildCategory(item?.id);
                              }}
                            >
                              {item?.name}
                            </Dropdown.Item>
                          );
                        })
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : null}
              <div>
                <p className="head">Product Detail</p>
                <input
                  type="text"
                  placeholder="Product Detail"
                  className="des-input"
                  value={detail}
                  onChange={(e) => setDetail(e.target.value)}
                />
              </div>
              <div className="dflexss">
                <p className="head">Unit Quantity </p>
                <input
                  type="text"
                  placeholder="Category Name"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                />
                <div className="dropdownbtn">
                  <p className="inputpara">Unit (kg, pc, lb, ml, g...etc)</p>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {unit != null ? <> {unit?.name}</> : null}
                      <img src="\assestsss\arrow 1.svg" alt="arrow" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {unitList.length > 0
                        ? unitList.map((item, index) => {
                          return (
                            <Dropdown.Item
                              eventKey={item?.id}
                              onClick={(e) => {
                                // setRoleId(item?.id);
                                setUnitId(item?.id);

                                setUnit(item);
                              }}
                            >
                              {item?.name}
                            </Dropdown.Item>
                          );
                        })
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="dropdownbtn">
                    <p className="inputpara">Type</p>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                      {type != null ? <> {type}</> : "select type"}
                        <img src="\assestsss\arrow 1.svg" alt="arrow" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>

                        <Dropdown.Item    onClick={(e) => {
                                  setType("grocer");
                                }}
                              >
                          grocer
                        </Dropdown.Item>
                        <Dropdown.Item onClick={(e) => {
                                  setType("PHLP");
                                }}>
                          pharmacy
                        </Dropdown.Item>
                        <Dropdown.Item onClick={(e) => {
                                  setType("manual");
                                }}>
                          manual
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
              </div>
              <div>
                <p className="head">Unit Price </p>
                <input
                  type="text"
                  placeholder="RS 150"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div>
              <div>
                <p className="head">Max selling threshold </p>
                <input
                  type="text"
                  placeholder="50"
                  value={maxCap}
                  onChange={(e) => setMaxCap(e.target.value)}
                />
              </div>
              <div>
                <p className="head">Out of Stock Threshold </p>
                <input
                  type="text"
                  placeholder="50"
                  value={outOfStockThreshold}
                  onChange={(e) => setOutOfStockThreshold(e.target.value)}
                />
              </div>
            </div>
          </Offcanvas.Body>
          <div className="ofcavlastbtn">
            <Button className="offlastbtn" variant="btn" type="submit">
              Cancel
            </Button>
            <Button
              className="offlastupdatebtn"
              variant="btn"
              onClick={editProduct}
            >
              Update
            </Button>
          </div>
        </Offcanvas>
      </section>
    </div>
  );
};

export default Ratelistsidebar;
