import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

const Support = () => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url2;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [supportData, setSupportData] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const [searchQuery, setSearchQuery] = useState("");
  const [filterName, setFilterName] = useState("");

  const [nameFilter, setNameFilter] = useState(false);
  const [ticketIdFilter, setTicketIdFilter] = useState(false);

  const handleNameFilter = (e) => {
    if (e.target.checked) {
      setFilterName("name");

      setNameFilter(true);
      setTicketIdFilter(false);
    } else if (!e.target.checked) {
      setNameFilter(false);
      setFilterName("name");
    }
  };

  const handleTicketFilter = (e) => {
    if (e.target.checked) {
      setFilterName("id");

      setTicketIdFilter(true);
      setNameFilter(false);
    } else if (!e.target.checked) {
      setTicketIdFilter(false);
      setFilterName("name");
    }
  };

  const getSupportData = async () => {
    setLoader(true);
    const config = {
      method: "get",
      url:
        api_url +
        "/supports?limit=" +
        limit +
        "&offset=" +
        offset +
        `&${filterName ? "search=" + filterName + "=" + searchQuery : ""}&${
          filterName == "name" ? "searchByName=true" : "searchByName=false"
        }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setSupportData(resData?.issuesListing);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getSupportData();
    }
  }, [offset, searchQuery]);

  return (
    <>
      <div className="content">
        <section className="main-dashboard">
          <div className="row">
            <div className="col-sm-12 p-0">
              <div className="Overview_odersec">
                <div className="parent-heading">
                  <h4 className="ooddeerr">Support</h4>
                  <div
                    className="itemsssss"
                    style={{ maxWidth: "373px", width: "100%" }}
                  >
                    <div className="search-field w-100">
                      <img
                        src="\nav-assets\search-normal.svg"
                        alt="img"
                        className="search-icon"
                      />
                      <input
                        type="text"
                        placeholder="Search"
                        className="parent-input"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                      <div class="dropdown dropdown-text-parent">
                        <h6
                          className="dropdown-text"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          By {filterName}{" "}
                          <img
                            src="\assestsss\arrow1.svg"
                            alt="img"
                            className="img-fluid ml-1"
                          />
                        </h6>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={nameFilter}
                              onChange={(e) => handleNameFilter(e)}
                            />
                            <label>By Name</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="defaultCheck1"
                              checked={ticketIdFilter}
                              onChange={(e) => handleTicketFilter(e)}
                            />
                            <label>By Ticket Id</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div class="dropdown">
                  <button
                    class=""
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src="\assestsss\filter_111.svg"
                      alt="img"
                      className="img-fluid mr-2"
                    />
                    Filter
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="input-field">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label>All</label>
                    </div>
                    <div className="input-field">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                      <label>Unpublished</label>
                    </div>
                  </div>
                </div> */}
              </div>
              <div class="table-responsive  ">
                <table class="table bashbord_table">
                  <thead className="bashbord_table_head">
                    <tr className="tablerow">
                      <th scope="col">Ticket id</th>
                      <th scope="col">Customer</th>
                      <th scope="col">Subject</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {supportData?.length > 0
                      ? supportData?.map((item) => {
                          return (
                            <>
                              <tr className="fstrowtable">
                                <td>
                                  <p className="fstrownum">{item?.id}</p>{" "}
                                </td>
                                <td className="avtimgandname">
                                  <p className="fstrownumsec ">
                                    {" "}
                                    <img
                                      src={item?.user?.imageUrl}
                                      alt="img"
                                      className="avtartable  pr-2 img-fluid"
                                    />
                                    {item?.user?.name}
                                  </p>
                                </td>
                                <td>
                                  <p className="fstrownumthrd">
                                    {item?.subject}
                                  </p>
                                </td>
                                <td>
                                  <p className="fstrownumfour">
                                    {moment(item?.createdAt).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </p>
                                </td>

                                <td>
                                  {" "}
                                  <span className="greenprocesss">
                                    {item?.status}
                                  </span>
                                </td>

                                <td>
                                  {" "}
                                  <Link
                                    to={{
                                      pathname: "/admin/supportdetail",
                                      state: {
                                        id: item?.id,
                                      },
                                    }}
                                  >
                                    <a className="tble_details">Details</a>
                                  </Link>{" "}
                                </td>
                              </tr>
                            </>
                          );
                        })
                      : null}
                    {/* <tr className="fstrowtable">
                      <td>
                        <p className="fstrownum">9AF3DF</p>{" "}
                      </td>
                      <td className="avtimgandname">
                        <p className="fstrownumsec ">
                          {" "}
                          <img
                            src="\dashbord\avtpic.svg"
                            alt="img"
                            className="avtartable  pr-2 img-fluid"
                          />
                          Erick Santiago
                        </p>
                      </td>
                      <td>
                        <p className="fstrownumthrd">Delivery no received</p>
                      </td>
                      <td>
                        <p className="fstrownumfour">02-Aug-2022</p>
                      </td>
                      <td>
                        <p className="fstrownumfive">Rs 319.00</p>
                      </td>
                      <td>
                        {" "}
                        <span className="greenprocesss">Processing</span>
                      </td>

                      <td>
                        {" "}
                        <Link to="/admin/supportdetail">
                          <a className="tble_details">Details</a>
                        </Link>{" "}
                      </td>
                    </tr>
                    <tr className="fstrowtable">
                      <td>
                        <p className="fstrownum">9AF3DF</p>{" "}
                      </td>
                      <td className="avtimgandname">
                        <p className="fstrownumsec ">
                          {" "}
                          <img
                            src="\dashbord\avtpic.svg"
                            alt="img"
                            className="avtartable  pr-2 img-fluid"
                          />
                          Erick Santiago
                        </p>
                      </td>
                      <td>
                        <p className="fstrownumthrd">Delivery no received</p>
                      </td>
                      <td>
                        <p className="fstrownumfour">02-Aug-2022</p>
                      </td>
                      <td>
                        <p className="fstrownumfive">Rs 319.00</p>
                      </td>
                      <td>
                        {" "}
                        <span className="greenprocesssgreen ">Delivered</span>
                      </td>

                      <td>
                        {" "}
                        <a className="tble_details">Details</a>
                      </td>
                    </tr>
                    <tr className="fstrowtable">
                      <td>
                        <p className="fstrownum">9AF3DF</p>{" "}
                      </td>
                      <td className="avtimgandname">
                        <p className="fstrownumsec ">
                          {" "}
                          <img
                            src="\dashbord\avtpic.svg"
                            alt="img"
                            className="avtartable  pr-2 img-fluid"
                          />
                          Erick Santiago
                        </p>
                      </td>
                      <td>
                        <p className="fstrownumthrd">Delivery no received</p>
                      </td>
                      <td>
                        <p className="fstrownumfour">02-Aug-2022</p>
                      </td>
                      <td>
                        <p className="fstrownumfive">Rs 319.00</p>
                      </td>
                      <td>
                        {" "}
                        <span className="greenprocesssyellow">Pending</span>
                      </td>

                      <td>
                        {" "}
                        <a className="tble_details">Details</a>
                      </td>
                    </tr>
                     */}
                  </tbody>
                </table>
              </div>
              <div className="mainfoot">
                <div className="left">
                  {count > 0 && count <= limit ? (
                    <>
                      <p>
                        SHOWING 1-{count} OF {count}
                      </p>
                    </>
                  ) : count > limit ? (
                    <>
                      <p>
                        SHOWING {offset > 1 ? (offset - 1) * limit + 1 : offset}{" "}
                        -{limit * offset > count ? count : limit * offset} OF{" "}
                        {count}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>SHOWING 0 OF 0</p>
                    </>
                  )}
                </div>
                <div className="right">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        {offset > 1 ? (
                          <>
                            <a
                              class="page-link"
                              href="#"
                              aria-label="Previous"
                              onClick={() => setOffset((prev) => prev - 1)}
                            >
                              <span aria-hidden="true">
                                <i class="fas fa-angle-left"></i>
                              </span>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>
                      {pageCount.length > 1 ? (
                        <>
                          {pageCount.map((item) => {
                            return (
                              <>
                                <li className="page-item">
                                  <a
                                    className={
                                      item + 1 == offset
                                        ? "page-link active"
                                        : "page-link"
                                    }
                                    href="#"
                                    onClick={() => setOffset(item + 1)}
                                  >
                                    {item + 1}
                                  </a>
                                </li>
                              </>
                            );
                          })}
                        </>
                      ) : null}

                      <li class="page-item">
                        {offset < page ? (
                          <>
                            <a
                              class="page-link"
                              href="#"
                              aria-label="Next"
                              onClick={() => setOffset((prev) => prev + 1)}
                            >
                              <span aria-hidden="true">
                                <i class="fas fa-angle-right"></i>
                              </span>
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Support;
