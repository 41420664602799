import React from "react";
import { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";

import { Dropdown, Offcanvas } from "react-bootstrap";
import "./UploadCSVModal.scss";
import csvUpload from "../../../assets/csv-upload.svg";
import itemimg from "../../../assets/img/bg5.jpg";

import Papa from "papaparse";
import moment from "moment";

import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UploadCSVModal = ({ assignshow, setassignShow }) => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();

  const [categoryScrollState, setCategoryScrollState] = useState(false);
  const [productScrollState, setProductScrollState] = useState(false);

  const [data, setData] = useState([]);
  const [dataToUpload, setDataToUpload] = useState([]);

  const [fileName, setFileName] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setFileName(event.target.files[0].name);
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvString = event.target.result;

      const { data } = Papa.parse(csvString, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          // Update column names
          setData(result?.data);
          console.log("result: ", result?.data);
          const updatedData = result.data.map((row) => {
            let date = moment(row.Expire,'DD/MM/YYYY').format("YYYY-MM-DD");
            return {
              sourceProductId: row.BARCODE,
              productName: row?.["ITEM NAME"],
              unit: row.UOM,
              price: row.RS,
              discount: row.Discount,
              endTime: date,
            };
          });
          setDataToUpload(updatedData);
          console.log("data: ", updatedData);
        },
      });
    };
    reader.readAsText(file);
  };

  const addCSV = async () => {
    // validateUserName();
    // if (!errors) {

    const config = {
      method: "post",
      url: api_url + "/discounts/csv",
      data: {
        fileName: fileName,
        items: dataToUpload,
      },
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        handleClose();
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        }
        if (err?.response?.status == 500) {
          toast.error("CSV is not correctlly formatted!", {
            position: "bottom-left",
            autoClose: 2000,
          });
        } else {
          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
      });
    // }
  };

  const handleClose = () => {
    setassignShow(false);
    setCategoryScrollState(false);
    setProductScrollState(false);
    setData([]);
    setFileName("");
  };

  return (
    <>
      <div className="content">
        <section className="sidebar-promoproduct UploadCSVModal-section">
          <Offcanvas
            show={assignshow}
            onHide={handleClose}
            className="sidebar-promoproduct"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Create Discount</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="canvas-body">
              <div className="upload-csv-wrapper">
                <h5>Upload CSV</h5>
                {fileName ? (
                  <div className="upload-csv-box">
                    <div className="upload-csv-box-data">
                      <input
                        id="upload"
                        type="file"
                        className="d-none"
                        accept=".csv"
                        onChange={handleFileUpload}
                      />
                      <label for="upload" className="upload-csv-box-data">
                        {/* <img src={csvFile} alt="csv" /> */}
                        <h5>{fileName}</h5>
                      </label>
                    </div>
                  </div>
                ) : (
                  <div className="upload-csv-box">
                    <div className="upload-csv-box-data">
                      <input
                        id="upload"
                        type="file"
                        className="d-none"
                        accept=".csv"
                        onChange={handleFileUpload}
                      />
                      <label for="upload" className="upload-csv-box-data">
                        <img src={csvUpload} alt="csvUpload" />
                        <h5>Drag your file here</h5>
                      </label>
                    </div>
                  </div>
                )}
              </div>

              <div className="table-customer mt-4">
                <div className="table-customer discount-Detail-table-inner-wrapper">
                  <table class="table table-striped discount-Detail-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Item Name</th>
                        <th>Discount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Start */}
                      {data?.length > 0
                        ? data?.map((item) => {
                            return (
                              <>
                                <tr>
                                  <td>{item?.BARCODE}</td>
                                  <td className="item-detail-column">
                                    <div className="images">
                                      <img
                                        src={itemimg}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <p>{item?.["ITEM NAME"]}</p>
                                  </td>
                                  <td>
                                    <div className="detailbutton">
                                      <span className="">
                                        {item?.Discount}%
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                                <tr className="item-detail-row">
                                  <td>
                                    <div className="item-detail-row-inner">
                                      <p className="item-detail-row-data-dark">
                                        Unit:
                                      </p>
                                      <p className="item-detail-row-data-light">
                                        {item?.UOM}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <p className="item-detail-row-data-dark">
                                        Expiry Date:
                                      </p>
                                      <p className="item-detail-row-data-light">
                                        {item?.Expire}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <p className="item-detail-row-data-dark">
                                        Price:
                                      </p>
                                      <p className="item-detail-row-data-light">
                                        Rs. {item?.RS}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                  {/* <table class="table table-striped discount-Detail-table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Discount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>h237</td>
                        <td className="item-detail-column">
                          <div className="images">
                            <img src={itemimg} className="img-fluid" alt="" />
                          </div>
                          <p>Freshmaker Wipes</p>
                        </td>
                        <td>
                          <div className="detailbutton">
                            <span className="">10%</span>
                          </div>
                        </td>
                      </tr>
                      <tr className="item-detail-row">
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Unit:</p>
                            <p className="item-detail-row-data-light">PSC</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">
                              Expiry Date:
                            </p>
                            <p className="item-detail-row-data-light">
                              07 Apr, 2024
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Price:</p>
                            <p className="item-detail-row-data-light">
                              Rs. 150
                            </p>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>h237</td>
                        <td className="item-detail-column">
                          <div className="images">
                            <img src={itemimg} className="img-fluid" alt="" />
                          </div>
                          <p>Freshmaker Wipes</p>
                        </td>
                        <td>
                          <div className="detailbutton">
                            <span className="">10%</span>
                          </div>
                        </td>
                      </tr>
                      <tr className="item-detail-row">
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Unit:</p>
                            <p className="item-detail-row-data-light">PSC</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">
                              Expiry Date:
                            </p>
                            <p className="item-detail-row-data-light">
                              07 Apr, 2024
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Price:</p>
                            <p className="item-detail-row-data-light">
                              Rs. 150
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>

              <div className="buttonss Upload-CSV-Modal-buttons">
                <button className="" onClick={handleClose}>
                  Cancel
                </button>
                <button className="ml-2" onClick={addCSV}>
                  Upload & Save
                </button>
                ``{" "}
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </section>
      </div>
    </>
  );
};

export default UploadCSVModal;
