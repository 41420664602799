import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import "./category.scss";
import Offcanvas from "react-bootstrap/Offcanvas";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddCategory = ({
  show,
  setShow,
  updateList,
  opType,
  editId,
  setOpType,
}) => {
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState("");
  const [baseImage, setBaseImage] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [parentCategoryName, setParentCategoryName] = useState("");

  const [parentCategory, setParentCategory] = useState("");
  const [description, setDescription] = useState("");
  const [accountImage, setAccountImage] = useState();
  const [categoryDetail, setCategoryDetail] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [parentCategoryId, setParentCategoryId] = useState("");

  const handleClose = () => {
    setShow(false);
    setCategoryName("");
    setParentCategory("");
    setDescription("");
    setBaseImage();
    setImage();
    setOpType("");
  };

  const uploadImages = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase645(file);
    setBaseImage(base64);
    setImage(file);
  };
  const convertBase645 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const getCategoryList = async () => {
    // validateUserName();
    // if (!errors) {
    console.log("hittinng: ");

    const config = {
      method: "get",
      url:
        api_url +
        "/store/categories?minimal=true&afterElement=0&limit=50&allCategories=true",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setCategoryList(resData);
        console.log("child cats: ", resData);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const addCategory = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const formData = new FormData();
    if (categoryName) {
      formData.append("name", categoryName);
    }
    if (description) {
      formData.append("description", description);
    }
    if (parentCategoryId) {
      formData.append("parentCategoryId", parentCategoryId);
    }
    if (image) {
      formData.append("categoryImage", image);
    }
    const config = {
      method: "post",
      url: api_url + "/store/categories",
      data: formData,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        updateList();
        handleClose();
        toast.success(res?.data?.message);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        setLoader(false);
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);

          toast.error(err?.response?.data?.message, {
            position: "bottom-left",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };

  const editCategory = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);

    const formData = new FormData();
    if (categoryName) {
      formData.append("name", categoryName);
    }
    if (description) {
      formData.append("description", description);
    }
    if (parentCategory) {
      formData.append("parentCategoryId", parentCategory);
    }
    if (image) {
      formData.append("categoryImage", image);
    }
    const config = {
      method: "patch",
      url: api_url + "/store/categories/" + editId,
      data: formData,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        updateList();
        handleClose();
        toast.success(res?.data?.message);
        setBaseImage();
        setImage();
        // if (image) {
        //   localStorage.setItem("profilePicture", baseImage);
        //   setAccountImage(baseImage);
        // }
        setLoader(false);
        // handleClose();
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
    // }
  };

  const getCategoryDetail = async () => {
    setLoader(true);

    const config = {
      method: "get",
      url: api_url + "/store/categories/" + editId,

      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const responseData = res?.data?.data;
        setCategoryName(responseData?.name);
        setDescription(responseData?.description);
        setBaseImage(responseData?.imageUrl);
        setParentCategory(responseData?.parentCategory);
        console.log("data: ", res?.data?.data);
        // setCategoryDetail(res?.data?.data);

        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    console.log("type: ", opType);
    if (!val) {
      history.push("/");
    } else {
      getCategoryList();
      if (opType == "edit") {
        getCategoryDetail();
      }
    }
  }, [opType]);

  return (
    <div className="content">
      <section className="addcategory">
        <Offcanvas show={show} onHide={handleClose} className="addcategory">
          <Offcanvas.Header closeButton>
            {opType == "add" ? (
              <Offcanvas.Title>Add Category</Offcanvas.Title>
            ) : opType == "edit" ? (
              <Offcanvas.Title>Edit Category</Offcanvas.Title>
            ) : (
              <Offcanvas.Title>Category</Offcanvas.Title>
            )}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="main-form">
              <div className="bannerss">
                <h4>
                  Category Image<span>*</span>
                </h4>
                <div className="item-upload">
                  <ul className="list-inline ">
                    <li className="list-inline-item">
                      <input
                        id="upload"
                        type="file"
                        className="d-none"
                        onChange={uploadImages}
                      />
                      <label for="upload" className="">
                        {baseImage ? (
                          <img
                            src={baseImage}
                            alt="img"
                            className="img-fluid upload-img"
                          />
                        ) : (
                          <>
                            {accountImage ? (
                              <img
                                src={accountImage}
                                alt="img"
                                className="img-fluid brdr-img"
                              />
                            ) : (
                              <div>
                                <img
                                  src="\assestsss\gallery-add.svg"
                                  className="img-fluid"
                                />
                                <h4 className="">Drag your image here</h4>
                                <p>
                                  (Only *.jpeg and *.png images will be
                                  accepted)
                                </p>
                              </div>
                            )}

                            {/* <h6>Upload user</h6> */}
                          </>
                        )}
                      </label>
                      {/* <h4 className="">Drag your image here</h4>
                      <p>(Only *.jpeg and *.png images will be accepted)</p> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p className="head">
                  Category Name<span>*</span>
                </p>
                <input
                  type="text"
                  value={categoryName}
                  placeholder="Category Name"
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </div>
              <div>
                <p className="head">Parent Category</p>
                <div class="dropdown">
                  <button
                    class="btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onSelect={(e) => {
                      console.log("val: ", e, e.target, e.target.value);
                    }}
                  >
                    {parentCategoryName
                      ? parentCategoryName
                      : "Select Parent Category"}
                    <img src="\assestsss\arrow1.svg" alt="img" className="" />
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    {categoryList.length > 0 ? (
                      categoryList.map((item, index) => {
                        return (
                          <a
                            className="dropdown-item"
                            onClick={(e) => {
                              setParentCategoryName(item?.name);
                              setParentCategoryId(item?.id);
                              setParentCategory(item);
                              // console.log("val: ", item?.name, item?.id, item);
                            }}
                          >
                            {item?.name}
                          </a>
                        );
                      })
                    ) : (
                      <p>No Categories</p>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <p className="head">Discription</p>
                <input
                  type="text"
                  value={description}
                  placeholder="Category Discription"
                  className="des-input"
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="box-buttons">
                <button>Cancel</button>

                {opType == "add" ? (
                  <button className="ml-2" onClick={addCategory}>
                    Add Category
                  </button>
                ) : opType == "edit" ? (
                  <button className="ml-2" onClick={editCategory}>
                    Update Category
                  </button>
                ) : null}
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </section>
    </div>
  );
};

export default AddCategory;
