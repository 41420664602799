import React from 'react'
import "./login.scss"
const Success = () => {
    return (
        <>
            <div className='content'>
                <section className="success">

                    <div className="container-fluid h-100">
                        <div className="row h-100">
                            <div className="col-sm-7 m-auto">
                                <div className='mainsuccesscard'>
                                    <div className='imagecard'>
                                        <img src='\assestsss\success.svg' alt='img' className='img-fluid' />
                                    </div>
                                    <h3>Success!</h3>
                                    <p>Your Password has been successfuly changed</p>
                                    <div className='login'>
                                        <button>Log In</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    )
}

export default Success