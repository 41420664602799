import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import AddBrand from "./AddBrand";
import Delete from "views/Delete_Modal/Delete";

import "./brandmanagement.scss";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const BrandManagement = () => {
  const [assignshow, setassignShow] = useState(false);
  const [assignshow1, setassignShow1] = useState(false);

  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);

  const [deleteId, setDeleteId] = useState("");
  const [opType, setOpType] = useState("");
  const [editId, setEditId] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [publishedFilter, setPublishedFilter] = useState(false);
  const [unpublishedFilter, setUnpublishedFilter] = useState(false);

  const url = api_url + "/brands/";

  const handleSearchQuery = (e) => {};

  const handlePublishedFilter = (e) => {
    if (e.target.checked) {
      setPublishedFilter(true);
      setUnpublishedFilter(false);
    } else if (!e.target.checked) {
      setPublishedFilter(false);
    }
  };

  const handleUnpublishedFilter = (e) => {
    if (e.target.checked) {
      setUnpublishedFilter(true);
      setPublishedFilter(false);
    } else if (!e.target.checked) {
      setUnpublishedFilter(false);
    }
  };
  const getBrandData = async () => {
    setBrandData([]);
    setLoader(true);
    const config = {
      method: "get",
      url:
        api_url +
        "/brands?limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=name=" +
        searchQuery +
        "&" +
        `${
          !publishedFilter && !unpublishedFilter
            ? ""
            : "filter=isPublish=eq:" + publishedFilter
        }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    console.log("url: ", api_url);
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setBrandData(resData?.brands);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  const publishUnpublishBrand = async (id) => {
    setLoader(true);
    const config = {
      method: "patch",
      url: api_url + "/brands/" + id + "/is-publish",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          console.log("error meessage: ", err?.response?.data?.message);
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getBrandData();
    }
  }, [offset, searchQuery, publishedFilter, unpublishedFilter]);

  return (
    <>
      <div className="content">
        <section className="promo brandmanagement">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 p-0">
                <div className="customerdetailheading">
                  <div className="parent-heading">
                    <h3>Brand Management</h3>
                    <div className="itemsssss">
                      <div className="search-field">
                        <img
                          src="\nav-assets\search-normal.svg"
                          alt="img"
                          className="search-icon"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="parent-input"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                      <div class="dropdown">
                        <button
                          class=""
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img
                            src="\assestsss\filter_111.svg"
                            alt="img"
                            className="img-fluid mr-2"
                          />
                          Filter
                        </button>

                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="published"
                              checked={publishedFilter}
                              onChange={(e) => handlePublishedFilter(e)}
                            />
                            <label>Published</label>
                          </div>
                          <div className="input-field">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="unpublished"
                              checked={unpublishedFilter}
                              onChange={(e) => handleUnpublishedFilter(e)}
                            />
                            <label>Unpublished</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="backbutton">
                    {/* <div className="btnsdrop">
                      <button
                        className="dropdownbtn"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src="\assestsss\filter_111.svg"
                          className="img-fluid"
                        />{" "}
                        Filter
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">
                          Action
                        </a>
                        <a class="dropdown-item" href="#">
                          Another action
                        </a>
                        <a class="dropdown-item" href="#">
                          Something else here
                        </a>
                      </div>
                    </div> */}

                    <button
                      className="createbtn"
                      onClick={() => {
                        setassignShow(true);
                        setOpType("add");
                      }}
                    >
                      <img src="" className="img-fluid" />
                      <img src="\assestsss\add.svg" className="img-fluid" /> Add
                      Brand
                    </button>
                  </div>
                </div>
                <div className="table-customer">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Brand</th>
                        <th scope="col">PUBLISH</th>
                        <th className="action" scope="col">
                          ACTIONS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {brandData.length > 0
                        ? brandData?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{item?.id}</td>
                                  <td>
                                    <div className="profile">
                                      <img
                                        src={item?.imageUrl}
                                        alt="img"
                                        className="img-fluid img-profile mr-2"
                                      />
                                      {item?.name}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="switch-input">
                                      <input
                                        class="tgl tgl-ios"
                                        id={(index + offset).toString()}
                                        type="checkbox"
                                        defaultChecked={item?.isPublish}
                                        onChange={() => {
                                          publishUnpublishBrand(item?.id);
                                        }}
                                      />
                                      <label
                                        class="tgl-btn"
                                        for={(index + offset).toString()}
                                      ></label>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="images">
                                      <img
                                        src="\assestsss\edit-2.png"
                                        className="img-fluid"
                                        onClick={() => {
                                          setassignShow(true);
                                          setOpType("edit");
                                          setEditId(item?.id);
                                        }}
                                      />
                                      <img
                                        src="\assestsss\trash.svg"
                                        className="img-fluid"
                                        onClick={() => {
                                          setassignShow1(true);
                                          setDeleteId(item?.id);
                                        }}
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
                <div className="mainfoot">
                  <div className="left">
                    {count > 0 && count <= limit ? (
                      <>
                        <p>
                          SHOWING 1-{count} OF {count}
                        </p>
                      </>
                    ) : count > limit ? (
                      <>
                        <p>
                          SHOWING{" "}
                          {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                          {limit * offset > count ? count : limit * offset} OF{" "}
                          {count}
                        </p>
                      </>
                    ) : (
                      <>
                        <p>SHOWING 0 OF 0</p>
                      </>
                    )}
                  </div>
                  <div className="right">
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <Stack spacing={2}>
                          <Pagination
                            count={page}
                            page={offset}
                            onChange={(e, p) => setOffset(p)}
                            color="primary"
                          />
                        </Stack>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Delete
          show1={assignshow1}
          setShow1={setassignShow1}
          id={deleteId}
          updateList={getBrandData}
          url={url}
        />
        <AddBrand
          assignshow={assignshow}
          setassignShow={setassignShow}
          setOpType={setOpType}
          updateList={getBrandData}
          opType={opType}
          editId={editId}
        />
      </div>
    </>
  );
};

export default BrandManagement;
