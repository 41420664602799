import React from 'react'
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Environment from 'utils/environment';
import {  useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import "../Promo/promo.scss"
import "./unit.scss"

const EditUnit = ({ assignshowtwo, setassignShowtwo, update, getUnit }) => {
    const val = localStorage.getItem("accessToken");
    const history = useHistory();
    const handleClose = () => setassignShowtwo(false);
    const [loader, setLoader] = useState(false);
    const [unitName, setUnitName] = useState("")
    const [abbrev, setAbbrev] = useState("");
    

    function Editunit(id) {
        axios
            .get(`${Environment.base_url}/units/${id}`, {
                headers: {
                    Authorization: "Bearer " + val,
                },
            })
            .then((res) => {
                console.log(res.data.data);
                const responseData = res?.data?.data;
                setUnitName(responseData?.name);
                setAbbrev(responseData?.abbreviation);
            
            })
            .catch((err) => {
                console.log(err);
            });
    }
    // promodetail show
    useEffect(() => {
        if (!val) {
            history.push("/");
        } else {
            if (assignshowtwo) {
                Editunit(update);
            }
        }
    }, [assignshowtwo]);


    const Updateunit = async (e) => {
        if (unitName.length === 0) {
            toast.error("Enter Name")
        }else if (abbrev.length === 0) {
            toast.error("Enter Abbreviation")
        }else{
            const config = {
                method: "patch",
                url: `${Environment.base_url}/units/${update}`,
                data: {
                    name: unitName,
                    abbreviation: abbrev,
                },
                headers: {
                  Authorization: "Bearer " + val,
                },
              };
              await axios(config)
                .then((res) => {
                  getUnit();
                  handleClose();
                  toast.success(res?.data?.message);
                  setLoader(false);
                 
                })
                .catch((err) => {
                  if (err?.response?.statusCode == 501) {
                    history.push("/");
                  } else {
                    console.log("error meessage: ", err?.response?.data?.message);
                    toast.error(err?.response?.data?.message, {
                      position: "bottom-left",
                      autoClose: 2000,
                    });
                  }
                  setLoader(false);
                });
        }
    }

    return (
        <>
            <div className="content">
                <section className="sidebar-promoproduct brandmanagement">
                    <Offcanvas show={assignshowtwo} onHide={handleClose} className="sidebar-promoproduct">
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                            Edit Unit 
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>


                            <div className='filedinput'>
                                <div className="option-field">
                                    <p>Unit Name*</p>
                                    <input type="text" placeholder="Unit Name"  value={unitName} onChange={(e) => {setUnitName(e.target.value)}} />
                                </div>
                                <div className="option-field">
                                    <p>Abbreviation*</p>
                                    <input type="text" placeholder="Abbreviation"  value={abbrev} onChange={(e) => {setAbbrev(e.target.value)}}/>
                                </div>


                            </div>
                            <div className='buttonss'>
                                <button className=''>Cancel</button>
                                <button className='ml-2' onClick={Updateunit}>Update Unit </button>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </section>
            </div>
        </>
    )
}

export default EditUnit;