import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";

const SupportDetail = (props) => {
  const id = props.location.state.id;
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url2;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [supportDetail, setSupportDetail] = useState([]);

  const getSupportDetails = async () => {
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/supports/" + id,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setSupportDetail(resData);
        console.log("details: ", resData);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getSupportDetails();
    }
  }, []);

  return (
    <div className="content">
      <section className="main-dashboard">
        <div className="row">
          <div className="col-sm-12 p-0">
            <div className="  supportdetails_fst">
              <h4 className="heading_support">Support Ticket Details</h4>
              <button className="btn_resolve">
                {" "}
                <img
                  src="\suppord\tick-circle.svg"
                  alt="img"
                  className="  pr-2 img-fluid"
                />
                Resolve
              </button>
            </div>

            <div className="row p-0">
              <div className="col-sm-12 p-0">
                <div className="carddashbord">
                  <div className="carrdmainflexx">
                    <div className="cardpicc">
                      <img
                        src={supportDetail?.user?.imageUrl}
                        alt="img"
                        className="avtartablesupdetl   img-fluid"
                      />
                    </div>
                    <div className="centermaindiv">
                      <div className="cardpichnds">
                        <p className="headdinggfsts">Customer Name</p>
                        <p className="headdinggfstdds">
                          {" "}
                          {supportDetail?.user?.name}
                        </p>
                        <p className="headdinggfsts">Customer ID</p>
                        <p className="headdinggfstdds">
                          {" "}
                          {supportDetail?.user?.id}
                        </p>
                      </div>
                      <div className="cardpichnds">
                        <p className="headdinggfsts">Email Address</p>
                        <p className="headdinggfstdds">
                          {" "}
                          {supportDetail?.user?.email}
                        </p>
                        <p className="headdinggfsts">Phone No.</p>
                        <p className="headdinggfstdds">
                          {" "}
                          {supportDetail?.user?.contactNo}
                        </p>
                      </div>
                      <div className="cardpichnds">
                        <p className="headdinggfsts">Joining Date</p>
                        <p className="headdinggfstdds">
                          {" "}
                          {moment(supportDetail?.user?.createdAt).format(
                            "MMM DD, YYYY"
                          )}
                        </p>
                        <p className="headdinggfsts">Address</p>
                        {Object.keys(supportDetail).length > 0 ? (
                          <p className="headdinggfstdds">
                            {supportDetail?.user?.addresses[0]?.address}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive ptb-20 mt-5">
              <table class="table bashbord_table">
                <thead className="bashbord_table_head">
                  <tr className="tablerow">
                    <th scope="col">Ticket ID</th>
                    <th scope="col">Subject</th>
                    <th scope="col">Item</th>
                    <th scope="col">Price</th>
                    <th scope="col">Refund</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="fstrowtable">
                    <td>
                      {supportDetail.length > 0 ? (
                        <p className="fstrownum">
                          {supportDetail?.supportItems[0]?.supportId}
                        </p>
                      ) : null}
                    </td>
                    <td>
                      {supportDetail.length > 0 ? (
                        <p className="fstrownumthrd">
                          {supportDetail?.subject}
                        </p>
                      ) : null}
                    </td>
                    <td className="avtimgandname">
                      {supportDetail.length > 0 ? (
                        <p className="fstrownumsec ">
                          {" "}
                          <img
                            src={
                              supportDetail?.supportItems[0]?.orderLine?.product
                                ?.imageUrl
                            }
                            alt="img"
                            className="avtartable  pr-2 img-fluid"
                          />
                          {
                            supportDetail?.supportItems[0]?.orderLine?.product
                              ?.name
                          }
                        </p>
                      ) : null}
                    </td>

                    <td>
                      {supportDetail.length > 0 ? (
                        <p className="fstrownumfour">
                          Rs. {supportDetail?.supportItems[0]?.orderLine?.price}
                        </p>
                      ) : null}
                    </td>

                    <td>
                      {" "}
                      <a className="tble_details">Refund</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SupportDetail;
