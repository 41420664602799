import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import useEagerConnect from "./hooks/useEagerConnect";
import AdminLayout from "layouts/Admin.js";
import Login from "components/login/Login";
import OderDetaits from "views/Order/OderDetaits";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Forgetpassword from "components/login/Forgetpassword";
import Checkemail from "components/login/Checkemail";
import Newpassword from "components/login/Newpassword";
import Success from "components/login/Success";

function App() {
  const accessToken = localStorage.getItem("accessToken");

  useEagerConnect();
  return (
    <>
      <ToastContainer
        position="bottom-left"
        theme="dark"
        style={{ fontSize: 20 }}
      />

      <BrowserRouter>
        <Switch>
          <Route path="/adminlogin" render={(props) => <Login {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          <Route exact path="/">
            {!accessToken || accessToken == "" ? (
              <Login />
            ) : accessToken && accessToken != "" ? (
              <Redirect to={"/admin/dashboard"}></Redirect>
            ) : null}
          </Route>
          <Route exact path="/forgetpassword" component={Forgetpassword} />
          <Route exact path="/Checkemail" component={Checkemail} />
          <Route exact path="/newpassword" component={Newpassword} />
          <Route exact path="/success" component={Success} />
          {/* <Redirect to="/admin/dashboard" /> */}
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
