import React from "react";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import "./UploadCSVModal.scss";
import itemimg from "../../../assets/img/bg5.jpg";

const DiscountDetailModal = ({ assignshow1, setassignShow1 }) => {
  const [categoryScrollState, setCategoryScrollState] = useState(false);
  const [productScrollState, setProductScrollState] = useState(false);

  const handleClose = () => {
    setassignShow1(false);
    setCategoryScrollState(false);
    setProductScrollState(false);
  };

  return (
    <div className="content">
      <section className="sidebar-promoproduct">
        <Offcanvas
          show={assignshow1}
          onHide={handleClose}
          className="sidebar-promoproduct DiscountDetailModal"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>CSV Details</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="canvas-body">
            <div className="upload-csv-wrapper Discount-Detail-table-wrapper">
              <h5>Upload CSV</h5>
              <div className="table-customer">
                <div className="table-customer discount-Detail-table-inner-wrapper">
                  <table class="table table-striped discount-Detail-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Item Name</th>
                        <th>Discount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Start */}
                      <tr>
                        <td>h237</td>
                        <td className="item-detail-column">
                          <div className="images">
                            <img src={itemimg} className="img-fluid" alt="" />
                          </div>
                          <p>Freshmaker Wipes</p>
                        </td>
                        <td>
                          <div className="detailbutton">
                            <span className="">10%</span>
                          </div>
                        </td>
                      </tr>
                      <tr className="item-detail-row">
                        <td>
                          <div className="item-detail-row-inner">
                            <p className="item-detail-row-data-dark">Unit:</p>
                            <p className="item-detail-row-data-light">PSC</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">
                              Expiry Date:
                            </p>
                            <p className="item-detail-row-data-light">
                              07 Apr, 2024
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Price:</p>
                            <p className="item-detail-row-data-light">
                              Rs. 150
                            </p>
                          </div>
                        </td>
                      </tr>
                      {/* End */}
                      {/* Start */}
                      <tr>
                        <td>h237</td>
                        <td className="item-detail-column">
                          <div className="images">
                            <img src={itemimg} className="img-fluid" alt="" />
                          </div>
                          <p>Freshmaker Wipes</p>
                        </td>
                        <td>
                          <div className="detailbutton">
                            <span className="">10%</span>
                          </div>
                        </td>
                      </tr>
                      <tr className="item-detail-row">
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Unit:</p>
                            <p className="item-detail-row-data-light">PSC</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">
                              Expiry Date:
                            </p>
                            <p className="item-detail-row-data-light">
                              07 Apr, 2024
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Price:</p>
                            <p className="item-detail-row-data-light">
                              Rs. 150
                            </p>
                          </div>
                        </td>
                      </tr>
                      {/* End */}
                      {/* Start */}
                      <tr>
                        <td>h237</td>
                        <td className="item-detail-column">
                          <div className="images">
                            <img src={itemimg} className="img-fluid" alt="" />
                          </div>
                          <p>Freshmaker Wipes</p>
                        </td>
                        <td>
                          <div className="detailbutton">
                            <span className="">10%</span>
                          </div>
                        </td>
                      </tr>
                      <tr className="item-detail-row">
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Unit:</p>
                            <p className="item-detail-row-data-light">PSC</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">
                              Expiry Date:
                            </p>
                            <p className="item-detail-row-data-light">
                              07 Apr, 2024
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Price:</p>
                            <p className="item-detail-row-data-light">
                              Rs. 150
                            </p>
                          </div>
                        </td>
                      </tr>
                      {/* End */}

                    </tbody>
                  </table>
                  {/* <table class="table table-striped discount-Detail-table">
                    <thead>
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">Discount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>h237</td>
                        <td className="item-detail-column">
                          <div className="images">
                            <img src={itemimg} className="img-fluid" alt="" />
                          </div>
                          <p>Freshmaker Wipes</p>
                        </td>
                        <td>
                          <div className="detailbutton">
                            <span className="">10%</span>
                          </div>
                        </td>
                      </tr>
                      <tr className="item-detail-row">
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Unit:</p>
                            <p className="item-detail-row-data-light">PSC</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">
                              Expiry Date:
                            </p>
                            <p className="item-detail-row-data-light">
                              07 Apr, 2024
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Price:</p>
                            <p className="item-detail-row-data-light">
                              Rs. 150
                            </p>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>h237</td>
                        <td className="item-detail-column">
                          <div className="images">
                            <img src={itemimg} className="img-fluid" alt="" />
                          </div>
                          <p>Freshmaker Wipes</p>
                        </td>
                        <td>
                          <div className="detailbutton">
                            <span className="">10%</span>
                          </div>
                        </td>
                      </tr>
                      <tr className="item-detail-row">
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Unit:</p>
                            <p className="item-detail-row-data-light">PSC</p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">
                              Expiry Date:
                            </p>
                            <p className="item-detail-row-data-light">
                              07 Apr, 2024
                            </p>
                          </div>
                        </td>
                        <td>
                          <div>
                            <p className="item-detail-row-data-dark">Price:</p>
                            <p className="item-detail-row-data-light">
                              Rs. 150
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                </div>
              </div>
            </div>
            <div className="buttonss">
              <button className="" onClick={handleClose}>
                Cancel
              </button>
              <button className="ml-2">Upload & Save</button>
              ``{" "}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </section>
    </div>
  );
};

export default DiscountDetailModal;
