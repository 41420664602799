import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Delete from "views/Delete_Modal/Delete";
import Environment from "utils/environment";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import "./customer.scss";
import moment from "moment";

const Customer = () => {
  const [show1, setShow1] = useState(false);
  const val = localStorage.getItem("accessToken");
  const api_url = Environment.base_url2;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [customersData, setCustomersData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [pageCount, setPageCount] = useState([]);
  const [offset, setOffset] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const url = api_url + "/users/";

  const [searchQuery, setSearchQuery] = useState("");
  const [filterName, setFilterName] = useState("");

  const [nameFilter, setNameFilter] = useState(false);
  const [emailFilter, setEmailFilter] = useState(false);
  const [phoneNumberFilter, setPhoneNumberFilter] = useState(false);

  const handleNameFilter = (e) => {
    if (e.target.checked) {
      setFilterName("name");

      setNameFilter(true);
      setEmailFilter(false);
      setPhoneNumberFilter(false);
    } else if (!e.target.checked) {
      setNameFilter(false);
      setFilterName("name");
    }
  };

  const handleEmailFilter = (e) => {
    if (e.target.checked) {
      setFilterName("email");
      setEmailFilter(true);
      setNameFilter(false);
      setPhoneNumberFilter(false);
    } else if (!e.target.checked) {
      setEmailFilter(false);
      setFilterName("name");
    }
  };

  const handlePhoneNumberFilter = (e) => {
    if (e.target.checked) {
      setFilterName("phoneNumber");
      setPhoneNumberFilter(true);
      setNameFilter(false);
      setEmailFilter(false);
    } else if (!e.target.checked) {
      setPhoneNumberFilter(false);
      setFilterName("name");
    }
  };

  const getCustomers = async () => {
    // validateUserName();
    // if (!errors) {
    setLoader(true);
    setCustomersData([]);
    const config = {
      method: "get",
      url:
        api_url +
        "/users?limit=" +
        limit +
        "&offset=" +
        offset +
        `&${filterName ? "search=" + filterName + "=" + searchQuery : ""}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setCustomersData(resData?.users);
        let arr = Array.from(Array(parseInt(resData.pages)).keys());
        setCount(resData?.count);
        setPage(resData?.pages);
        setPageCount(arr);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getCustomers();
    }
  }, [offset, searchQuery]);

  return (
    <>
      <div className="content">
        <section className="customer">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-xl-12 p-0">
                <div className="customertable">
                  <div className="parent-heading">
                    <h3 className="latest-heading">Customer</h3>
                    <div
                      className="itemsssss"
                      style={{ maxWidth: "373px", width: "100%" }}
                    >
                      <div className="search-field w-100 mr-0">
                        <img
                          src="\nav-assets\search-normal.svg"
                          alt="img"
                          className="search-icon"
                        />
                        <input
                          type="text"
                          placeholder="Search"
                          className="parent-input"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <div class="dropdown dropdown-text-parent">
                          <h6
                            className="dropdown-text"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            By {filterName}
                            <img
                              src="\assestsss\arrow1.svg"
                              alt="img"
                              className="img-fluid ml-1"
                            />
                          </h6>
                          <div
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={nameFilter}
                                onChange={(e) => handleNameFilter(e)}
                              />
                              <label>By name</label>
                            </div>
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={emailFilter}
                                onChange={(e) => handleEmailFilter(e)}
                              />
                              <label>By Email</label>
                            </div>
                            <div className="input-field">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                checked={phoneNumberFilter}
                                onChange={(e) => handlePhoneNumberFilter(e)}
                              />
                              <label>By Phone</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-customer">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">ID</th>
                          <th scope="col">JOINING DATE</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Phone</th>
                          <th scope="col">ACTIONS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customersData.length > 0 ? (
                          customersData.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td className="idaff">{item?.id}</td>
                                  <td>
                                    {moment(item?.createdAt).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </td>
                                  <td>{item?.name}</td>
                                  <td>{item?.email}</td>
                                  <td>{item?.contactNo}</td>
                                  <td>
                                    <div className="detailbutton">
                                      <Link
                                        to={{
                                          pathname: "/admin/customerdetail",
                                          state: {
                                            id: item?.id,
                                          },
                                        }}
                                      >
                                        {" "}
                                        <button className="">Details</button>
                                      </Link>
                                      <a
                                        onClick={() => {
                                          setShow1(true);
                                          setDeleteId(item?.id);
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src="\assestsss\trash.svg"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              </>
                            );
                          })
                        ) : loader ? (
                          <tr>
                            <td colSpan="8" className="text-center">
                              <div className="text-center">
                                {<Spinner animation="border" variant="info" />}
                                {/* <h4>No Categories</h4> */}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <p>No Records</p>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="mainfoot">
                    <div className="left">
                      {count > 0 && count <= limit ? (
                        <>
                          <p>
                            SHOWING 1-{count} OF {count}
                          </p>
                        </>
                      ) : count > limit ? (
                        <>
                          <p>
                            SHOWING{" "}
                            {offset > 1 ? (offset - 1) * limit + 1 : offset} -
                            {limit * offset > count ? count : limit * offset} OF{" "}
                            {count}
                          </p>
                        </>
                      ) : (
                        <>
                          <p>SHOWING 0 OF 0</p>
                        </>
                      )}
                    </div>
                    <div className="right">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <li class="page-item">
                            {offset > 1 ? (
                              <>
                                <a
                                  class="page-link"
                                  href="#"
                                  aria-label="Previous"
                                  onClick={() => setOffset((prev) => prev - 1)}
                                >
                                  <span aria-hidden="true">
                                    <i class="fas fa-angle-left"></i>
                                  </span>
                                </a>
                              </>
                            ) : (
                              <></>
                            )}
                          </li>
                          {pageCount.length > 1 ? (
                            <>
                              {pageCount.map((item) => {
                                return (
                                  <>
                                    <li className="page-item">
                                      <a
                                        className={
                                          item + 1 == offset
                                            ? "page-link active"
                                            : "page-link"
                                        }
                                        href="#"
                                        onClick={() => setOffset(item + 1)}
                                      >
                                        {item + 1}
                                      </a>
                                    </li>
                                  </>
                                );
                              })}
                            </>
                          ) : null}
                          <li class="page-item">
                            {offset < page ? (
                              <>
                                <a
                                  class="page-link"
                                  href="#"
                                  aria-label="Next"
                                  onClick={() => setOffset((prev) => prev + 1)}
                                >
                                  <span aria-hidden="true">
                                    <i class="fas fa-angle-right"></i>
                                  </span>
                                </a>
                              </>
                            ) : (
                              <></>
                            )}
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Delete
        setShow1={setShow1}
        show1={show1}
        id={deleteId}
        updateList={getCustomers}
        url={url}
      />
    </>
  );
};

export default Customer;
