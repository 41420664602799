import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import ConnectWallet from "../modals/connectWallet";
import useAuth from "../../hooks/useAuth";
import messageSound from "../../assets/sounds/message.mp3"
import { io } from "socket.io-client";
import axios from "axios";
import Environment from "../../utils/environment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Container,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
} from "reactstrap";

import routes from "routes.js";
import moment from "moment";

function Header(props) {
  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const images = importAll(
    require.context("assets/img/dashboardimg", false, /\.(png|jpe?g|svg)$/)
  );
  const val = localStorage.getItem("accessToken");

  const api_url = Environment.base_url;
  const api_url1 = Environment.base_url4;

  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [brandName, setbrandName] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [color, setColor] = useState("transparent");
  const [loader, setLoader] = useState(false);
  const [notificationsData, setNotificationsData] = useState("");


  const sidebarToggle = useRef();
  const location = useLocation();
  const { account } = useWeb3React();
  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("");
    }
    setIsOpen(!isOpen);
  };
  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };
  const getBrand = () => {
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        // brandname = prop.name;
        setbrandName(prop.name);
        // console.log(brandName)
      }
      return null;
    });
  };

  useEffect(() => {
    getBrand();
  });
  useEffect(() => {
    if (!val) {
      history.push("/");
    } else {
      getNotiications();
    }
  }, []);



  const getSocket = async () => {
    const socket = io('https://prod-api.quick.shop/', {
    transports: ["websocket", "polling"],
    auth: {
      Authorization: `Bearer ${val}` 
    },
   
  });
  
  socket.on('connect', () => {
    console.log('Connected to the server');
  });
  socket.on('ORDER_NOTIFICATION_SENT', (event) => {
      console.log('socket connected in', socket.connected, event);
      toast.success(event.description,{ autoClose: false });
      const sound =  new Audio(messageSound);
      sound.play();
      getNotiications();
    });
    socket.on('ping', () => {
      console.log('ping from the server');
    });
     socket.on('pong', () => {
      console.log('pong from the server');
    });
    socket.on('error', (error) => {
      console.error('Connection error: ass', error.message); // Handle connection errors
    });
    
  }
  useEffect(() => {
    getSocket();
  }, [])

  const getNotiications = async () => {
    // validateUserName();
    // if (!errors) {
    setNotificationsData([]);

    const config = {
      method: "get",
      url: api_url1 + "/notifications/?limit=200",
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setNotificationsData(resData?.notifications);
        // socket.emit("ORDER_NOTIFICATION_SENT", resData?.notifications);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
          console.log("refresh token: ", err?.response);
        }
        console.log("error meessage: ", err?.response?.data?.message);
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
    // }
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color /transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("");
    } else {
      setColor("transparent");
    }
  };
  useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });
  useEffect(() => {
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [location]);

  const handleLogout = (e) => {
    e.preventDefault();

    setLoader(true);

    var config = {
      method: "delete",
      url: `${api_url1}/auth/staffs/logout`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        localStorage.removeItem("accessToken");
        console.log("tokeen: ", response?.data);
        history.push("/adminlogin");
        setLoader(false);
      })
      .catch(function (error) {
        localStorage.removeItem("accessToken");
        history.push("/adminlogin");
        setLoader(false);
      });
  };

  const { login, logout } = useAuth();

  const connectMetamask = () => {
    localStorage.setItem("connectorId", "injected");
    if (account) {
      logout();
    } else {
      login("injected");
    }
  };

  const trustWallet = async () => {
    localStorage.setItem("connectorId", "walletconnect");
    if (account) {
      logout();
    } else {
      login("walletconnect");
    }
  };

  const connectwallet = () => {
    if (account) {
      connectMetamask();
    } else {
      window.$("#exampleModalLong").modal("show");
    }
  };

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <div className="main-div-nav-head">
      <div class="dropdown noti dgvsvuvtyxts d-none">
        <button
          class="notifications"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <img
            src="\nav-assets\notification.svg"
            alt="img"
            className="notiimg"
          />
          <div className="numimg">
            <span>2</span>
          </div>
        </button>
        <div
          class="dropdown-menu noti-inner"
          aria-labelledby="dropdownMenuButton"
        >
          <div className="upper">
            <h4 className="upper-head">Notifications</h4>
            <p className="upper-para">Mark all as read</p>
          </div>
          <div className="scrollss">
            {notificationsData.length > 0
              ? notificationsData.map((item) => {
                return (
                  <>
                    <div className="lower">
                      <div className="lower-outer">
                        <img
                          src="\nav-assets\bag.svg"
                          alt="img"
                          className="lower-img"
                        />
                      </div>
                      <div className="lower-inner">
                        <h5 className="lower-head">{item?.title}</h5>
                        <div className="btnf">
                          <button className="lower-btn">
                            {item?.description}
                          </button>
                          <p className="lower-para">
                            {moment(item?.createdeAt).format(
                              "MMM DD, YYYY - hh:mm A"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
              : null}
          </div>
        </div>
      </div>
      <Navbar
        color={
          props.location.pathname.indexOf("full-screen-maps") !== -1
            ? ""
            : color
        }
        expand="lg"
        className={
          props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
            (color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid className="main-header-top-change p-0">
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={sidebarToggle}
                className="navbar-toggler"
                onClick={() => openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            {/* <NavbarBrand href="/">{brandName}</NavbarBrand> */}
          </div>
          <NavbarToggler onClick={toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar className="">
            {/* <button
              type="button"
              onClick={connectwallet}
              className="btn-common-pur btn mr-4"
            >
              { account ? "Disconnect Wallet" : "Connect Wallet" }
            </button> */}
            <div className="navbarsetting">
              <div className="main-head">
                {/* <img
                  src="\nav-assets\search-normal.svg"
                  alt="img"
                  className="main-search-image"
                />
                <input
                  type="search"
                  placeholder="Serach Item by Serial no., name, category etc."
                  className="main-search-input"
                /> */}
              </div>
              <div className="inner-head">
                <button className="nav-btn-log-out" onClick={handleLogout}>
                  <img
                    src="\nav-assets\login.svg"
                    alt="img"
                    className="logout"
                  />
                  Log Out
                </button>
                <div class="dropdown noti">
                  <button
                    class="notifications"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src="\nav-assets\notification.svg"
                      alt="img"
                      className="notiimg"
                    />
                    {/* <div  className="numimg">
                      <span >{notificationNumber}</span>
                    </div> */}
                  </button>
                  <div
                    class="dropdown-menu noti-inner"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div className="upper">
                      <h4 className="upper-head">Notifications</h4>
                      <p className="upper-para">Mark all as read</p>
                    </div>
                    <div className="scrollss">
                      {notificationsData.length > 0
                        ? notificationsData.map((item) => {
                          return (
                            <>
                              <div className="lower">
                                <div className="lower-outer">
                                  <img
                                    src={item?.imageUrl}
                                    alt="img"
                                    className="lower-img"
                                  />
                                </div>
                                <div className="lower-inner">
                                  <h5 className="lower-head">
                                    {item?.title}
                                  </h5>
                                  <div className="btnf text-white">
                                    {item?.description}

                                    <p className="lower-para">
                                      {moment(item?.createdeAt).format(
                                        "MMM DD, YYYY - hh:mm A"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                        : null}
                    </div>
                  </div>
                </div>
                <div className="dropdown drop-seller">
                  <button
                    className="button-seller"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src="\nav-assets\customer-supporter 1.svg"
                      className=""
                      alt=""
                    />
                  </button>
                  {/* <div
                className="dropdown-menu drop-mainn"
                aria-labelledby="dropdownMenuButton"
              >
                <h3 className="title-drop">John Doe</h3>
                <p className="inner-label">Admin</p>
                <Link to={`/admin/editprofile`} className="dropdown-item">
                  Edit Profile
                </Link>
                <Link to="user" className="dropdown-item">
                  Users
                </Link>
              </div> */}
                  {/* <img
                src="\dashboard-assets\arrow-down.png"
                alt="img"
                className="img-fluid arrow-down-p"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              /> */}
                </div>
              </div>
            </div>
          </Collapse>
        </Container>
      </Navbar>
      {/* Add Wallet Modal */}
      {/* <div className="row">
        <div className="col-sm-12">
          <div
            className="modal fade"
            id="exampleModalLong"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
          >
            <ConnectWallet
              trustWallet={trustWallet}
              connectMetamask={connectMetamask}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Header;